import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './core/commons/reduxStore';
import './styles/index.less'

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div>loading...</div>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
