import { GET_TRANSACTIONSDEPOSITBYTRANSFER_ERROR, GET_TRANSACTIONSDEPOSITBYTRANSFER_SUCCES, TransactionsDepositByTransferInStoreAction } from "../../../actions/dashboard/transactions/updateTransactionsDepositByTransferInStore";
import { DepositModel } from "../../../models/dashboard/transactions/transation";

export const updateTransactionsDepositByTransferInStoreReducer 
=(state:DepositModel| null= null, action: TransactionsDepositByTransferInStoreAction) => {
    switch (action.type) {
        case GET_TRANSACTIONSDEPOSITBYTRANSFER_SUCCES:
            return { ...state, ...action }
        case GET_TRANSACTIONSDEPOSITBYTRANSFER_ERROR:
            return {...state};
        default:
            return {...state};
    }
}