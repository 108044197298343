import { ArrowRightOutlined, DownloadOutlined, MoneyCollectOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";

export enum IconType {
    Subscription,
    Download,
    Add,
    Withdraw
}

export interface IconProps {
    type: IconType
}

class IconsComponent extends React.Component<IconProps> {
    render() {
        return (
            <>
                {(() => {
                    switch (this.props.type) {
                        case IconType.Subscription:
                            return <MoneyCollectOutlined />
                        case IconType.Download:
                            return <DownloadOutlined />
                        case IconType.Add:
                            return <PlusOutlined />
                        case IconType.Withdraw:
                            return <ArrowRightOutlined />
                    }
                })()}

            </>
        )
    }
}

export default IconsComponent;