import React from 'react';
import '../../styles/_variables.less'
import 'antd/dist/antd.less';
import {Avatar, Dropdown, Layout, Menu, Skeleton} from 'antd';
import services from '../../core/services';
import store from '../../core/commons/localstorage';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  PieChartOutlined,
  ShopOutlined, DownOutlined, BellOutlined, AppstoreOutlined, MobileOutlined, AmazonOutlined
} from '@ant-design/icons';

import '../../styles/layout/dashboard/dashboard.less';

import Home from "./home/Home";
import UserList from "./users/User";

import {
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom';

import Logo from './logo/Logo';
import { RoutePaths } from '../routes';
import {AppState} from "../../core/commons/reduxStore";
import {connect} from "react-redux";
import {updateUserInStore} from "../../core/actions/user/updateUserInStore";
import {User} from "../../core/services/accounts/getUserProfile";
import {queryRequest} from "../common/requestUtils/requestUtils";
import UserDetails from './users/details/UserDetails';
import {isNil} from '../common/utils/Utils';
import Affiliates from './marketplace/Affiliates';
import AdminNotification from "./notifications/Notification";
import AffiliateDetailsComponent from './marketplace/affiliateDetails/AffiliateDetailsComponent';
import TransactionsComponent from "./transactions/TransactionComponent";
import Subscriptions from './subscriptions/Subscriptions';
import IconsComponent, { IconType } from '../common/components/dataDisplay/Icons';
import WithdrawComponent from './withdraw/WithdrawComponent';
import SubMenu from 'antd/lib/menu/SubMenu';
import DeviceUsersComponent from './users/device/DeviceUsersComponent';
import AmazonCashbackRequestComponent from './marketplace/amazon/AmazonCashbackRequest';

const { Header, Sider } = Layout;
const {getUserProfile} = services;



const menuItems = [
  {
    link: RoutePaths.HOME_PATH,
    name: "Dashboard",
    key: '1',
    icon: () => <PieChartOutlined/>
  },
  {
    link: RoutePaths.USER_PATH,
    name: "Users",
    key: '2',
    icon: () => <UserOutlined/>,
    submenu:{
      link: RoutePaths.DEVICE_PATH,
      name: "Devices",
      key: 'deviceIds',
    }
  },
  {
    link: RoutePaths.AFFILIATE_PATH,
    name: "Marketplace",
    key: '4',
    icon: () => <ShopOutlined/>,
    submenu:{
      link: RoutePaths.AMAZON_CASHBACK_REQUEST,
      name: "Amazon",
      key: 'amazon-cashback',
    }
  },
  {
    link: RoutePaths.NOTIFICATION_PATH,
    name: "Notifications",
    key: '5',
    icon: () => <BellOutlined/>
  },
  {
    link: RoutePaths.SUBSCRIPTION_PATH,
    name: "Subscriptions",
    key: '6',
    icon: () => <IconsComponent  {...{type:IconType.Subscription}}/>
  },
  {
    link: RoutePaths.WITHDRAW_PATH,
    name: "Withdrawal",
    key: '7',
    icon: () => <IconsComponent  {...{type:IconType.Withdraw}}/>
  }
];


class Dashboard extends React.Component<any> {
  state = {
    collapsed: false,
    loading: true,
  };

  getSelectedMenu = ():string => {
    let url = window.location.href;

    let returnValue =
        Object.values(menuItems)
            .filter(x => url.includes(x.link));
    // check submenu

    let returnValueSubmenu = Object.values(menuItems)
                  .filter(x=> (x.submenu && url.includes(x.submenu?.link)));

    if(!isNil(returnValueSubmenu) && returnValueSubmenu.length > 0)
    return returnValueSubmenu[0].submenu? returnValueSubmenu[0].submenu?.key : '1'

    if(!isNil(returnValue) && returnValue.length > 0)
      return returnValue[0].key

    return '1';
  }


  toggleMenu = () => {
    this.setState(prev => ({
      ...prev,
      collapsed: !this.state.collapsed,
    }));
  };

  constructor(props: any) {
    super(props);
    this.getProfileInfo().then(r => {
          if (r !== null) {
            this.setState(prev => ({
              ...prev,
              loading: false
            }))
          }
          this.props.updateUserInStore(r)
        }
    );
  }

  toggleMenuOnResize = () => {
    if(window.innerWidth <= 1024)
      this.setState(prev => ({
        ...prev,
        collapsed: true
      }));
    else
      this.setState(prev => ({
        ...prev,
        collapsed: false
      }));
  }

  componentDidMount() {
    window.addEventListener('resize', this.toggleMenuOnResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.toggleMenuOnResize);
  }

  routeChange = (path: string, key: string) => {
    store.setPage(key);
    this.props.history.push(path);
  }

  getProfileInfo = async () => {
    try {
      const user: User = await queryRequest(() => getUserProfile(), true);
      return user;
    } catch (err) {
      return null;
    }
  };

  getAcronym = ():string => {
    const userName = this.props?.user?.fullName;
    if(this.props.user === undefined || this.props.user === null) return '';
    if(userName === null || userName === undefined) return '';
    return userName.trim()
        .split(' ')
        .map((x:string) => x.trim().charAt(0))
        .join('')
        .toUpperCase();
  }

  logout = (): void => {
    store.removeToken();
    window.location.reload();
  }

  render() {
    const signOut =
        <Menu>
          <Menu.Item onClick={this.logout}>
            Sign Out
          </Menu.Item>
        </Menu>
    ;

    const layout = (
        <Layout className={'layout-height'}>
          <Sider trigger={null} collapsible collapsed={this.state.collapsed} className={'side-menu'}>
            <Menu theme="light" mode="inline" defaultSelectedKeys={[this.getSelectedMenu()]} selectedKeys={[this.getSelectedMenu()]}>
              <Logo isCollapsed={this.state.collapsed}/>
              {menuItems.map(x =>
              (
                <>
                {(x.link == RoutePaths.USER_PATH) &&
                  (
                    <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Users">
                    <Menu.Item key={x.key} icon={x.icon()} onClick={() => this.routeChange(x.link, x.key)}>Users</Menu.Item>

                    <Menu.Item key={"deviceIds"} icon={<MobileOutlined />}
                             onClick={() => this.routeChange(RoutePaths.DEVICE_PATH, "deviceIds")}>Devices</Menu.Item>
                  </SubMenu>
                  )
                }
                   {(x.link == RoutePaths.AFFILIATE_PATH) &&
                  (
                    <SubMenu key="sub3" icon={<AppstoreOutlined />} title={x.name}>
                      <Menu.Item key={x.key} icon={x.icon()} onClick={() => this.routeChange(x.link, x.key)}>{x.name}</Menu.Item>
                      <Menu.Item key={"amazon-cashback"} icon={<AmazonOutlined />} onClick={() => this.routeChange(RoutePaths.AMAZON_CASHBACK_REQUEST, "amazon-cashback")}>Amazon</Menu.Item>
                  </SubMenu>
                  )
                }
                {
                  (x.link != RoutePaths.AFFILIATE_PATH && x.link != RoutePaths.USER_PATH) &&
                    <Menu.Item key={x.key} icon={x.icon()}
                      onClick={() => this.routeChange(x.link, x.key)}>
                      {x.name}
                    </Menu.Item>
                }

            </>
              ))}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background header">
              <div className="header-content">
                <div className="header-content-left">
                  <div className="menu-toggle-wrapper">
                    {
                      this.state.collapsed
                          ? <MenuUnfoldOutlined className={'toggle-menu'} onClick={this.toggleMenu}/>
                          : <MenuFoldOutlined className={'toggle-menu'} onClick={this.toggleMenu}/>
                    }
                  </div>
                </div>
                <div className="header-content-right">
                  <Dropdown overlay={signOut} trigger={["click"]}>
                    <a onClick={e => e.preventDefault()} href="#" className="link-style">
                      <Avatar className="avatar" size={32}>
                        {this.getAcronym()}
                      </Avatar>
                      <div className="avatar-content-wrapper">
                        <div className="avatar-wrapper">
                          {this.props.user?.fullName}
                          <DownOutlined className="down-arrow"/>
                        </div>
                      </div>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Switch>
              <Route component={Home} exact   path={RoutePaths.HOME_PATH}/>
              <Route component={UserList} exact   path={RoutePaths.USER_PATH}/>
              <Route component={DeviceUsersComponent} exact   path={RoutePaths.DEVICE_PATH}/>
              <Route component={TransactionsComponent} exact   path={RoutePaths.TRANSACTION_PATH}/>
              <Route component={Affiliates} exact   path={RoutePaths.AFFILIATE_PATH}/>
              <Route component={UserDetails} exact   path={RoutePaths.USER_DETAILS_PATH}/>
              <Route component={AffiliateDetailsComponent} exact   path={RoutePaths.AFFILIATE_DETAILS_PATH}/>
              <Route component={AdminNotification} exact path={RoutePaths.NOTIFICATION_PATH}/>
              <Route component={Subscriptions} exact path={RoutePaths.SUBSCRIPTION_PATH}/>
              <Route component={WithdrawComponent} exact path={RoutePaths.WITHDRAW_PATH}/>
              <Route component={AmazonCashbackRequestComponent} exact path={RoutePaths.AMAZON_CASHBACK_REQUEST}/>
              <Route exact path={RoutePaths.DASHBOARD_PATH} render={() => (<Redirect to={RoutePaths.HOME_PATH}/>)}/>
            </Switch>
          </Layout>
        </Layout>
    );

    return (
        <Skeleton active loading={this.state.loading}>
          {this.props.user ? layout : <div/> }
        </Skeleton>
    );
  }
}

const mapStateToProps = ({user}: AppState) =>
    ({
      user: user
    });

export default
withRouter(
    connect(mapStateToProps, {
  updateUserInStore
})(Dashboard)
);
