import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { GetMoveMoney, MoveMoneySearch } from "../../../models/dashboard/moveMoney/moveMoney";
import getMoveMoneyByUser from "../../../services/dashboard/moveMoney/getMoveMoneyByUser";


export const GET_MOVEMONEYBYUSER_SUCCESS ="GET_MOVEMONEYBYUSER_SUCCESS";
export const GET_MOVEMONEYBYUSER_ERROR ="GET_MOVEMONEYBYUSER_ERROR";

export interface MoveMoneyByUserInStoreAction {
    type: typeof GET_MOVEMONEYBYUSER_SUCCESS | typeof GET_MOVEMONEYBYUSER_ERROR,
    userMoveMoney: GetMoveMoney | null,
    searchParams: MoveMoneySearch
}

export const updateMoveMoneyByUserInStoreAction =
(moveMoney: GetMoveMoney | null,
 searchParams: MoveMoneySearch,
 onSuccess: Function,
 onFailure: Function)
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    queryRequest(()=> getMoveMoneyByUser(searchParams))
    .then( (res)=>{
        dispatch({
            type: GET_MOVEMONEYBYUSER_SUCCESS,
            userMoveMoney: res
        } as MoveMoneyByUserInStoreAction);
        onSuccess();
    })
    .catch((err) => {
        dispatch({
            type: GET_MOVEMONEYBYUSER_ERROR,
            userMoveMoney: moveMoney
        } as MoveMoneyByUserInStoreAction);
        onFailure();
    });
}