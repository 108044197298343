import { CheckOutlined, ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import React from "react";

export interface ContentProps {
    nrOfcharsIcon: boolean;
    lowercaseIcon: boolean;
    uppercaseIcon: boolean;
    oneNumberIcon: boolean;
  }
  
  const resolvePasswordRequirementIcon = (requirementMet: boolean) => {
    return requirementMet ? <CheckOutlined className="icon-styles"/> : <ExclamationCircleOutlined className="warning-style" />
  };
  

const PasswordPopoverContent: React.FC<ContentProps> = props => {
    let contentProps =
    props !== undefined
      ? props
      : {
          nrOfcharsIcon: false,
          lowercaseIcon: false,
          oneNumberIcon: false,
          uppercaseIcon: false
        };

    return (
        <div>
          <p>{resolvePasswordRequirementIcon(contentProps.nrOfcharsIcon)}&nbsp;At least 8 characters</p>
          <p>{resolvePasswordRequirementIcon(contentProps.lowercaseIcon)}&nbsp;At least one lowercase letter</p>
          <p>{resolvePasswordRequirementIcon(contentProps.uppercaseIcon)}&nbsp;At least one uppercase letter</p>
          <p>{resolvePasswordRequirementIcon(contentProps.oneNumberIcon)}&nbsp;At least one number</p>
        </div>
      );
    };
    
    export default PasswordPopoverContent;
    