import { Empty, Row, Spin } from "antd";
import Table from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { updateAllUserSubscriptionsInStoreAction } from "../../../../core/actions/dashboard/subscriptions/userSubscriptions/updateAllUserSubscriptionsInStore";
import { updateUserSubscriptionsInStoreAction } from "../../../../core/actions/dashboard/subscriptions/userSubscriptions/updateUserSubscriptionsInStore";
import { AppState } from "../../../../core/commons/reduxStore";
import { CSVUserSubscriptions, GetListOfUserSubscriptions, PaginatedUserSubscriptions, UserSubscriptions, UserSubscriptionType } from "../../../../core/models/dashboard/subscriptions/subscriptions";
import ButtonComponent from "../../../common/components/dataDisplay/Button";
import IconsComponent, { IconType } from "../../../common/components/dataDisplay/Icons";
import SearchComponent from "../../../common/components/dataInput/components/SearchComponent";
import "../../../../styles/layout/dashboard/subscriptions/subscriptionList/subscriptionList.less";

export interface SubscriptionListComponentProps {
    updateUserSubscriptionsInStoreAction(initSearchParams: GetListOfUserSubscriptions): void;
    userSubscriptions: PaginatedUserSubscriptions | null,
    allUserSubscriptions: Array<UserSubscriptions> | []
}

var initData: PaginatedUserSubscriptions = {
    items: [],
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    totalItemCount: 0
}

var initSearchParams: GetListOfUserSubscriptions = {
    orderDescending: true,
    pageNumber: 1,
    pageSize: 10,
    searchValue: '',
    sortColumn: 'CreatedAt'
}

const SubscriptionListComponent: React.FC<SubscriptionListComponentProps> = (props: SubscriptionListComponentProps) => {
    let data: PaginatedUserSubscriptions = props.userSubscriptions ? props.userSubscriptions : initData;
    let csvData: Array<UserSubscriptions> = props.allUserSubscriptions && props.allUserSubscriptions.length > 0 ? props.allUserSubscriptions : [];

    const defaultSortColumnt = "CreatedAt";
    const maxSearchLength = 50;
    const [isLoading, setLoading] = useState(true);
    const [currentUserSubscriptions, setCurrentState] = useState<PaginatedUserSubscriptions>();
    const [csvUserSubscriptions, setUserSubscriptions] = useState<Array<CSVUserSubscriptions>>([])

    useEffect(() => {
        if (props.userSubscriptions) {
            if (!currentUserSubscriptions) {
                setCurrentState(props.userSubscriptions)
            }

            if (!Object.is(currentUserSubscriptions, props.userSubscriptions)) {
                setLoading(false);
                setCurrentState(props.userSubscriptions)
            }
        }
        if (csvData.length > 0 && csvUserSubscriptions.length == 0) {
            onGetAllUserSubscriptions();
        }
    });

    const columns = [
        {
            title: <><b>Full Name</b></>, dataIndex: 'user.fullName', key: 'Id', width: 220,
            fixed: true,
            sorter: true,
            render: (fullName: string, row: UserSubscriptions) => (
                <> {row.user.fullName} </>
            )
        },
        {
            title: <><b>Email</b></>, dataIndex: 'user.email',
            width: 220,
            sorter: true,
            key: 3,
            render: (email: string, row: UserSubscriptions) => (
                <> {row.user.email} </>
            )
        },
        {
            title:  <><b>User created on</b></>, dataIndex: 'user.createdAt', width: 170, key: 5, sorter: true,
            render: (createdAt: Date, row: UserSubscriptions) => (
                <> {row.user.createdAt ? `${moment(new Date(row.user.createdAt)).format("MM-DD-YYYY")}` : ("")} </>

            )
        },
        {
            title: <><b>Subscription type</b></>, dataIndex: 'subscriptionType', width: 220, key: 6, sorter: true,
            render: (subscriptionType: string, row: UserSubscriptions) => (
                <> {row.subscriptionType == UserSubscriptionType.Premium ? "Premium" : "Trial"}  </>
            )
        },
        {
            title: <><b>Start plan date</b></>, dataIndex: 'startPlanDate', sorter: true, width: 220, key: 7,
            render: (startPlanDate: Date, row: UserSubscriptions) => (
                <> {row.startPlanDate ? `${moment(new Date(row.startPlanDate)).format("MM-DD-YYYY")}` : ("")} </>

            )
        },
        {
            title: <><b>End plan date</b></>,
            dataIndex: 'endPlanDate',
            width: 220,
            key: 8,
            render: (endPlanDate: Date, row: UserSubscriptions) => (
                <> {row.endPlanDate ? `${moment(new Date(row.endPlanDate)).format("MM-DD-YYYY")}` : ("")} </>

            )
        },
        {
            title: <><b>Is canceled</b></>,
            dataIndex: 'isCanceled',
            width: 220,
            key: 9,
            render: (isCanceled: boolean, row: UserSubscriptions) => (
                <> {row.isCanceled ? "True" : "False"} </>
            )
        },
        {
            title:  <><b>Primary card of use</b></>,
            dataIndex: 'hasPrimaryCardOfUse',
            width: 220,
            key: 10,
            render: (hasPrimaryCardOfUse: boolean, row: UserSubscriptions) => (
                <> {row.hasPrimaryCardOfUse ? "True" : "False"} </>
            )
        }

    ];

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
            } else {
                initSearchParams.sortColumn = defaultSortColumnt;
                initSearchParams.orderDescending = false;
            }
        }

        if (pagination) {
            if (props.userSubscriptions?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.updateUserSubscriptionsInStoreAction(initSearchParams);
        setLoading(true);

    }

    const onSearchSubmit = (searchValue: string) => {
        initSearchParams.searchValue = searchValue;
        initSearchParams.pageNumber = 1;
        setLoading(true)
        props.updateUserSubscriptionsInStoreAction(initSearchParams);
    }


    const onGetAllUserSubscriptions = () => {
        let exportedData: Array<CSVUserSubscriptions> = [];
        if (props.allUserSubscriptions && props.allUserSubscriptions.length > 0) {
            props.allUserSubscriptions.forEach((item: UserSubscriptions) => {
                exportedData.push({
                    User: item.user.fullName,
                    Email: item.user.email,
                    CreatedAt: `${moment(new Date(item.user.createdAt)).format("MM-DD-YYYY")}`,
                    StartPlanDate: `${moment(new Date(item.startPlanDate)).format("MM-DD-YYYY")}`,
                    EndPlanDate: `${moment(new Date(item.endPlanDate)).format("MM-DD-YYYY")}`,
                    SubscriptionType: (item.subscriptionType == UserSubscriptionType.Premium) ? 'Pro' : 'Trial',
                    IsCanceled: item.isCanceled ? 'True' : 'False',
                    HasPrimaryCardOfUse: item.hasPrimaryCardOfUse ? 'True' : 'False'

                })
            });
            setUserSubscriptions(exportedData);
        }
    }



    return (
        <>
            <Spin spinning={isLoading}>
                <Row className="subscription-list-search-row">
                    <span className="searchbox" style={{ display: 'flex' }}>
                        <CSVLink
                            filename={`user-subscription-list.csv`}
                            title={`Download CSV`}
                            data={csvUserSubscriptions} >
                            <ButtonComponent
                                loading={csvUserSubscriptions.length == 0}
                                className="export-button"
                                shape="round"
                                size="large"
                                disabled={csvUserSubscriptions.length == 0}
                                icon={<IconsComponent {...{ type: IconType.Download }} />}
                                name={`Download CSV`} />
                        </CSVLink>
                        <SearchComponent {...{ className: "search-input", maxLength: maxSearchLength, size: "large", name: "searchValue", placeholder: "Search something...", onSearch: onSearchSubmit }} />
                    </span>
                </Row>
                <Table
                    columns={columns}
                    rowKey="id"
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No result`} /> }}
                    pagination={{
                        total: data.totalItemCount, current: data.pageNumber,
                        pageSize: data.pageSize,
                        showSizeChanger: false,
                    }}
                    scroll={{ x: 1200 }}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    onChange={(onChangeEvent)}
                    dataSource={data.items}
                />
            </Spin>
        </>
    )
}

const mapStateToProps = ({ userSubscriptions }: AppState) =>
({
    userSubscriptions: userSubscriptions
});
export default connect(mapStateToProps, {
    updateUserSubscriptionsInStoreAction

})(SubscriptionListComponent);
