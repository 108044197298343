import { adminHttpClient } from "../../commons/adminHttpClinet";

export const login = (password: string, email: string, ): Promise<any> => {
  return adminHttpClient.post("/api/Admin/AdminAuthentication/login", {email, password });
};

export const confirmCode = (code: string, token: string, ): Promise<any> => {
  return adminHttpClient.post("/api/Admin/AdminAuthentication/confirm-code", {code, token });
};

export const resendCode = (token: string): Promise<any> => {
  return adminHttpClient.post("/api/Admin/AdminAuthentication/resend-code", {token});
}

export const passwordRevery = ( email: string, ): Promise<any> => {
  return adminHttpClient.post("/api/Admin/AdminAuthentication/password-recovery", {email});
};


export const resetPassword = ( newPassword: string,  token:string): Promise<any> => {
  return adminHttpClient.post("/api/Admin/AdminAuthentication/reset-password", {newPassword, token});
};