import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../commons/reduxStore";
import { AnnualRapidWithdrawModel } from "../../../models/dashboard/withdraw/withdraw";
import getAnnualRapidWithdrawals from '../../../services/dashboard/moveMoney/getAnnualRapidWithdrawStatistics';

export const GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_SUCCESS="GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_SUCCESS";
export const GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_ERROR="GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_ERROR";

export interface AnnualRapidWithdrawInStoreAction {
    type: typeof GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_SUCCESS | typeof GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_ERROR,
    annualRapidWithdraw: Array<AnnualRapidWithdrawModel> | []
}

export const udpateAnnualRapidWithdrawInStoreAction = (
    annualRapidWithdraw : Array<AnnualRapidWithdrawModel> | [],
    onSuccess: Function,
    onFailure: Function
)
: ThunkAction<void, AppState, unknown, Action> => dispatch =>{
    queryRequest(()=> getAnnualRapidWithdrawals())
    .then((res:Array<AnnualRapidWithdrawModel>)=>{
        dispatch({
            type:GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_SUCCESS,
            annualRapidWithdraw: res
        } as AnnualRapidWithdrawInStoreAction);
        onSuccess();
    })
    .catch((err) => {
        dispatch({
            type: GET_ANNUAL_RAPID_WITHDRAWAL_STATISTICS_ERROR,
            annualRapidWithdraw: []
        } as AnnualRapidWithdrawInStoreAction);
        onFailure();
    });
}