import {MarketplaceCategoriesModel} from "../../../../models/dashboard/marketplace/marketplaceCategoryModel";
import {GET_MARKETPLACECATEGORIES_SUCCESS, GET_MARKETPLACECATEGORIES_ERROR, MarketplaceCategoriesInStoreAction} from "../../../../actions/dashboard/marketplace/categories/updateMarketplaceCategoriesInStore";


export  const  updateMarketplaceCategoriesInStoreReducer = 
    (state: Array<MarketplaceCategoriesModel> = [], action: MarketplaceCategoriesInStoreAction ) => {
        switch (action.type) {
            case GET_MARKETPLACECATEGORIES_SUCCESS:     
                return [...action.affiliateCategory];
            case GET_MARKETPLACECATEGORIES_ERROR:
                return [];
            default:
                return [...state];
        }
    }