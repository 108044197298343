import {TotalMarketplacePurchases} from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../../../commons/reduxStore";
import {Action} from "redux";
import {exec} from "child_process";
import {executeAction} from "../../../commons/baseAction";
import {queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import getTotalMarketplacePurchases from "../../../services/dashboard/marketplacePurchase/getTotalMarketplacePurchases";

export const GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_SUCCESS = "GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_SUCCESS";
export const GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_FAILURE = "GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_FAILURE";

export interface GetTotalMarketplacePurchasesInStoreAction{
    type : typeof GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_SUCCESS | typeof GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_FAILURE,
    total: TotalMarketplacePurchases | null
}

export const getTotalMarketplacePurchasesInStoreAction = (amount : TotalMarketplacePurchases)
                                                          : ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getTotalMarketplacePurchases()),
        (res: TotalMarketplacePurchases) => {
            dispatch({
                type: GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_SUCCESS,
                total: res
            } as GetTotalMarketplacePurchasesInStoreAction)
        },
        (_ : any) =>{
            dispatch({
                type: GET_TOTAL_MARKETPLACE_PURCHASE_IN_STORE_FAILURE,
                total: amount
            } as GetTotalMarketplacePurchasesInStoreAction)
        }
    )
}