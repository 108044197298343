import React, {useState} from 'react';
import {Button, Card, Empty, message, Row, Spin, Table} from "antd";
import {ArrowRightOutlined, BackwardOutlined, DownOutlined, ReloadOutlined, RightOutlined} from "@ant-design/icons";
import {AppState} from "../../../../core/commons/reduxStore";
import {connect} from "react-redux";
import {JobRole, JobStatus, JobType} from "../../../../core/models/dashboard/notification/notification";
import {updateNotificationJobsInStore} from "../../../../core/actions/dashboard/notification/updateNotifcationJobsInStore";
import {GetJobRole, GetJobStatus, getJobType} from "../../../common/components/dashboard/NotificationHelpers";
import NotificationSubJobLogListComponent from "./NotificationSubJobLogListComponent";


const NotificationJobLogListComponent: React.FC<any> = (props: any) => {

    const [isLoading, setLoading] = useState(false);
    const [isNotCollapsed, setCollapsed] = useState(false);
    const [isMounting, setMounting] = useState(true);

    const onGetJobsSuccess = () => {
        setLoading(false);
    }

    const onGetJobsFailure = (err: any) => {
        message.error(err.message)
        setLoading(false);
    }

    const loadJobs = (pageNumber: number) => {
        props.updateNotificationJobsInStore({
            pageNumber: pageNumber,
            pageSize: 7,
            parentId: null
        }, null,  onGetJobsSuccess, onGetJobsFailure);
    }

    if (isMounting) {
        setLoading(true);
        setMounting(false);
        loadJobs(1);
    }

    const onChangeEvent = (pagination: any, _: any, _1: any, _2: any) => {
        if (pagination) {
            if (props.notificationJobs?.pageNumber !== pagination.current) {
                loadJobs(pagination.current);
                setLoading(true);
            }
        }
    }

    const refresh = () => {
        setLoading(true);
        loadJobs(props
            .notificationJobs.pageNumber);
    }

    const collapse = () => {
        setCollapsed(!isNotCollapsed);
    }

    const getHeaderTitle = () => {
        return (
            <>
                {
                    isNotCollapsed ?
                        <>
                            <DownOutlined onClick={collapse} />
                            {` Notification Executed Jobs`}
                        </> :
                        <>
                            <RightOutlined onClick={collapse} />
                            {` Notification Executed Jobs`}
                            </>}
            </>
        )
    }

    const columns = [
        {
            title: 'Tag',
            dataIndex: 'tag',
            width: 100,
            key: 1,
            fixed: true,
            sorter: false,
            render: (tag: string) => (
                <>
                    <span>{tag}</span>
                </>
            )
        },
        {
            title: 'Type',
            dataIndex: 'jobType',
            width: 100,
            key: 2,
            fixed: false,
            sorter: false,
            render: (type: JobType) => (
                <>
                    <span>{getJobType(type)}</span>
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'jobStatus',
            width: 100,
            key: 3,
            fixed: false,
            sorter: false,
            render: (status: JobStatus) => {
                return (
                    <>
                        <GetJobStatus status={status}/>
                    </>
                )
            }
        },
        {
            title: 'Layer',
            dataIndex: 'jobRole',
            width: 100,
            key: 4,
            fixed: false,
            sorter: false,
            render: (role: JobRole) => (
                <>
                   <GetJobRole role={role}/>
                </>
            )
        },
        {
            title: 'Total Jobs',
            dataIndex: 'totalJobs',
            width: 60,
            key: 5,
            fixed: false,
            sorter: false,
            render: (total:number) => (
                <>
                    <span>{total}</span>
                </>
            )
        },
        {
            title: 'Failed Jobs',
            dataIndex: 'failedSubJobs',
            width: 60,
            key: 6,
            fixed: false,
            sorter: false,
            render: (failed:number) => (
                <>
                    <span>{failed}</span>
                </>
            )
        },
        {
            title: 'Processing Jobs',
            dataIndex: 'processingSubJobs',
            width: 60,
            key: 7,
            fixed: false,
            sorter: false,
            render: (processing:number) => (
                <>
                    <span>{processing}</span>
                </>
            )
        },
        {
            title: 'Succeeded Jobs',
            dataIndex: 'succeededSubJobs',
            width: 60,
            key: 8,
            fixed: false,
            sorter: false,
            render: (succeeded:number) => (
                <>
                    <span>{succeeded}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 9,
            fixed: false,
            sorter: false,
            dataIndex: 'id',
            width: 80,
            render: (id: string | null, _: any) =>
                <>
                    <Button onClick={() => {
                        props.openLogs(id, false);
                    }} type="link">
                        <ArrowRightOutlined/>
                    </Button>
                </>
        },
    ];

    return (
        <Card
            className={`notification-log-view ${isNotCollapsed ? "is-flex-expanded nt-expanded" : "nt-collapsed"}`}
            title={getHeaderTitle()}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <div className="nt-tab-row-card-content">

                    <Row className="notification-item notification-item-right">
                        <Button
                            onClick={() => refresh()}
                            type="default"
                            shape="round"
                            icon={<ReloadOutlined/>}>
                            Refresh
                        </Button>
                    </Row>
                    <div className="notification-item">
                        <Table
                            locale={{
                                emptyText: <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No results for ths search. Try again..."/>
                            }}
                            pagination={{
                                total: props?.notificationJobs?.totalItemCount,
                                current: props.notificationJobs?.pageNumber,
                                pageSize: props?.notificationJobs?.pageSize,
                                showSizeChanger: false,
                            }}
                            expandable={{
                                expandedRowRender: (record: any) => (
                                    <>
                                        <NotificationSubJobLogListComponent
                                            openLogs={props.openLogs}
                                            parentId={record.id}/>
                                    </>
                                )
                            }}
                            className=""
                            sortDirections={['ascend', 'descend', 'ascend']}
                            scroll={{x: 800, y: 600}}
                            onChange={(onChangeEvent)}
                            columns={columns}
                            dataSource={props
                                .notificationJobs?.items?.map((e: any, key: number) => ({
                                    key: key,
                                    ...e
                                }))}/>
                    </div>
                </div>
            </Spin>
        </Card>
    );
}

const mapStateToProps = ({notificationJobs}: AppState) =>
    ({
        notificationJobs: notificationJobs
    });

export default connect(mapStateToProps, {
    updateNotificationJobsInStore
})(NotificationJobLogListComponent);
