import {
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Empty,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Spin,
    Steps,
    Table,
    Tabs,
    Upload
} from "antd";
import {
    CheckOutlined,
    ClearOutlined,
    CloseOutlined,
    DeleteColumnOutlined,
    DeleteRowOutlined,
    FullscreenOutlined,
    InfoCircleOutlined,
    PartitionOutlined,
    UploadOutlined
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

import React, { ReactElement, useEffect, useState } from "react";
import * as XLSX from "xlsx";

import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { RoutePaths } from "../../../routes";

import { commandRequest } from "../../../common/requestUtils/requestUtils";
import { newGuid, getMoneyType, success } from "../../../common/utils/Utils";
import marketplaceAction from "../../../../core/actions/dashboard/marketplace/index"

import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import moment from 'moment';

import {
    CashbackRequestModel, CashbackRequestsCheckedPaginated,
    FeeEarningsReportModel,
    FeeOrdersAmazonDetailsMatched,
    FeeOrdersReportModel, GetPaginatedData,
    MatchCashbackRequestModel,
    RejectCashbackRequestModel
} from "../../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel";

import RequestMoreInformationComponent from "./requestMoreInformaction/RequestMoreInformation";
import ApprovedCashbackRequestsComponent from "./approvedCashbackRequests/ApprovedCashbackRequests";
import RejectedCashbackRequestsComponent from "./rejectedCashbackRequests/RejectedCashbackRequests";

import BreadcrumbComponent, { BreadcrumbProps } from "../../../common/components/dataDisplay/BreadcrumbComponent";

import marketplace from "../../../../core/services/dashboard/marketplace/index";

import "../../../../styles/layout/dashboard/marketplace/amazon/amazonCashbackRequest.less";
import { updateApprovedCashbackRequestInStoreAction } from "../../../../core/actions/dashboard/marketplace/amazon/updateApprovedCashbackRequestsInStore";
import { updateRejectedCashbackRequestInStoreAction } from "../../../../core/actions/dashboard/marketplace/amazon/updateRejectedCashbackRequestInStore";
import { updateMarketplaceCashbackRequestInStoreAction } from "../../../../core/actions/dashboard/marketplace/amazon/updateCashbackRequestInStore";
import ImagePreviewGroupComponent, { ImagesModal } from "../../../common/components/dashboard/ImagePreviewGroupComponent";

import resource from "../../../common/resource/resource";
import { MarketplaceModel } from "../../../../core/models/dashboard/marketplace/affiliatesModel";
import { MarketplaceCategoriesModel } from "../../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import affiliateStatisticsResource from "../affiliateDetails/statistics/Resource";
import AddMatchedCashbackRequestComponent from "./addMatchedCashbackRequests/AddMatchedCashbackRequests";
import ConfirmCashbackEarningsComponent from "./confirmCashbackEarnings/ConfirmCashbackEarnings";
import { on } from "process";
import AmazonOrderReportComponent from "./orderReport/AmazonOrdersReport";

const { Panel } = Collapse;
const { updateAllMarketplacesInStoreAction } = marketplaceAction;


const { TabPane } = Tabs;
export interface AmazonCashbackRequestProps {
    allMarketplaces: Array<MarketplaceModel>,
    marketplaceCashbackRequest: Array<CashbackRequestModel> | [],
    updateMarketplaceCashbackRequestInStoreAction: (cashbackRequest: Array<CashbackRequestModel>) => void;
    updateApprovedCashbackRequestInStoreAction: (approvedCashbackRequest: CashbackRequestsCheckedPaginated | null, model: GetPaginatedData) => void;
    updateRejectedCashbackRequestInStoreAction: (rejectedCashbackRequest: CashbackRequestsCheckedPaginated | null, model: GetPaginatedData) => void;
    updateAllMarketplacesInStoreAction: (allMarketplaces: Array<MarketplaceModel> | [], deleted: boolean) => void;
}

let initSearchParams: GetPaginatedData = {
    pageNumber: 1,
    pageSize: 10
}
enum OrderType {
    Matched,
    UnMatched
}

const AmazonCashbackRequestComponent: React.FC<AmazonCashbackRequestProps> = (props: AmazonCashbackRequestProps) => {
    const [isLoading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [pageSize, setPageSize] = useState(5);

    const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);
    const [cashbackRequestModel, setCashbackRequest] = useState<Array<CashbackRequestModel>>([]);
    const [selectedCashbackRequestModel, setSelectedCashbackRequestModel] = useState<Array<CashbackRequestModel>>([]);

    const [feeEarningsReport, setFeeEarningsReport] = useState<Array<FeeEarningsReportModel>>([]);
    const [feeOrdersReport, setFeeOrdersReport] = useState<Array<FeeOrdersReportModel>>([]);

    const [selectedRowKeys, setSelectedRowKey] = useState<Array<string>>([]);
    const [selectedRowItem, setSelectedRowItem] = useState<Array<FeeOrdersReportModel>>([]);
    const [selectedUnMatchedRowKeys, setSelectedUnMatchedRowKey] = useState<Array<string>>([]);
    const [selectedUnMatchedRowItem, setSelectedUnMatchedRowItem] = useState<Array<FeeOrdersReportModel>>([]);

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalMoreInfoVisible, setModalMoreInfoVisible] = useState<boolean>(false);
    const [modalMoreInfoModel, setModalMoreInfoModel] = useState<CashbackRequestModel | null>(null);
    const [hideChild, setHideChild] = useState<boolean>(false)
    const [sendNotificationUploaded, setSendNotificationUploaded] = useState<boolean>(false);
    const [amazonAffiliates, setAmazonAffiliates] = useState<Array<MarketplaceModel>>([]);
    const [activeTab, setActiveTab] = useState<string>("cashback-requests");

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Marketplace",
                path: RoutePaths.AFFILIATE_PATH
            },
            {
                title: "Amazon Cashback",
                path: ""
            }
        ]
    };

    useEffect(() => {
        setLoading(true);
        setCashbackRequest([]);
        setFeeOrdersReport([]);
        setFeeEarningsReport([]);
        setKeyExpanded([]);
        setSelectedRowKey([]);
        setSelectedRowItem([]);
        setSelectedUnMatchedRowItem([]);
        setSelectedUnMatchedRowKey([]);

        setModalVisible(false);
        setSelectedCashbackRequestModel([]);
        props.updateMarketplaceCashbackRequestInStoreAction([]);
        props.updateAllMarketplacesInStoreAction([], false);
    }, [])

    useEffect(() => {
        setLoading(false);
        props.marketplaceCashbackRequest.forEach(item => {
            item.feeOrdersMatching = []
        });
        setCashbackRequest(props.marketplaceCashbackRequest);
    }, [props.marketplaceCashbackRequest])

    useEffect(() => {
        setAmazonAffiliates(props.allMarketplaces.filter(item => item.category.name.toLowerCase() == 'amazon'))
    }, [props.allMarketplaces])

    useEffect(() => {

        setLoading(true);

        // if (cashbackRequestModel.length > 0) {
        //     setLoading(true);

        //     cashbackRequestModel.forEach(item => {

        //         item.feeOrdersMatching = feeOrdersReport.filter(itm => {
        //             var diff = Math.abs(itm?.date.getTime() - new Date(item.purchaseDetails.amazonDetails.marketplaceClickDate).getTime());
        //             var diffDays = Math.ceil(diff / (1000 * 3600 * 24));


        //             if (
        //                 ((itm.date.setHours(0, 0, 0) == new Date(new Date(item.purchaseDetails.amazonDetails.marketplaceClickDate.toString()).toLocaleDateString()).setHours(0, 0, 0))

        //                     //||(diffDays <=2 && (new Date(itm.date) >= new Date(item.purchaseDetails.amazonDetails.marketplaceClickDate)))

        //                 ) && itm.price <= item.purchaseDetails.amazonDetails.plaidAmount)

        //                 return itm;

        //         }
        //         );
        //         item.feeOrdersUnMatched = feeOrdersReport.filter(itm => {
        //             //var diff = Math.abs( itm?.date.getTime() - new Date(item.purchaseDetails.amazonDetails.marketplaceClickDate).getTime());
        //             //var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 


        //             if (!((itm.date.setHours(0, 0, 0) == new Date(new Date(item.purchaseDetails.amazonDetails.marketplaceClickDate.toString()).toLocaleDateString()).setHours(0, 0, 0))
        //                 && itm.price <= item.purchaseDetails.amazonDetails.plaidAmount))

        //                 return itm;

        //         }
        //         );

        //         item.feeOrdersMatching.filter((itm) => {
        //             itm.cashbackRequestId = item.id;
        //         });
        //         item.feeOrdersUnMatched.filter((itm) => {
        //             itm.cashbackRequestId = item.id;
        //         });

        //     });
        //     console.log("cashback requests model: ", cashbackRequestModel)
        //     setLoading(false);
        //     setCashbackRequest(cashbackRequestModel);

        // } else {
        //     cashbackRequestModel.forEach(item => {
        //         item.feeOrdersMatching = []
        //     })
        //     setCashbackRequest(cashbackRequestModel);
        // }

        setLoading(false);

    }, [feeOrdersReport])

    const columns = [
        {
            title: 'User', dataIndex: 'user.fullName',
            key: 'user.fullName',
            width: 220,
            render: (id: string, row: CashbackRequestModel) => (
                <>
                    {row.user.fullName}
                </>
            )
        },
        {
            title: 'Screenshot',
            dataIndex: 'invoicesUrls',
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    {getInvoiceUrls(row)}
                </>
            )
        },
        {
            title: 'Guac affiliate click date',
            dataIndex: 'marketplaceClickDate',
            key: 2,
            width: 200,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.marketplaceClickDate ? `${moment(new Date(row.purchaseDetails.amazonDetails.marketplaceClickDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Request date',
            dataIndex: 'createdAt',
            key: 2,
            width: 200,

            render: (createdAt: any, row: CashbackRequestModel) => (
                <>
                    <> {row.createdAt ? `${moment(new Date(row.createdAt)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Transaction date (Plaid)',
            dataIndex: 'transactionDate',
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.authorizedDate ? `${moment(new Date(row.purchaseDetails.amazonDetails.authorizedDate)).format("MM-DD-YYYY")}` : ("")} </>
                </>
            )
        },
        {
            title: 'Plaid Amount',
            dataIndex: 'transactionDate',
            key: 2,
            render: (partner: any, row: CashbackRequestModel) => (
                <>
                    <> {row.purchaseDetails.amazonDetails.plaidAmount ? <>{getMoneyType(row.purchaseDetails.amazonDetails.plaidAmount)}</> : ("")} </>
                </>
            )
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'id',
            width: 400,
            render: (id: string, row: CashbackRequestModel) =>
                <Row className="action-column">
                    {!keyExpanded.includes(id) &&

                        <>
                            <Popconfirm
                                title="Are you sure to reject this cashback?"
                                onConfirm={() => onHandleReject(row)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={() => {
                                }} className="reject-button" icon={<CloseOutlined />}>Reject</Button>
                            </Popconfirm>
                            <Button onClick={() => {
                                onRequestMoreInfo(row)
                            }} className="more-info-button" icon={<InfoCircleOutlined />}>Request more informations</Button>

                            <Button
                                onClick={() => {
                                    onMatchPressed(row)
                                }} className={"match-button"}
                                icon={<CheckOutlined />}>Match</Button>
                        </>
                    }
                </ Row>
        },
    ]

    // const feeOrdersColumns = [
    //     {
    //         title: 'Category',
    //         dataIndex: 'category',
    //         width: 270,
    //         key: 'orderReport-1',
    //         render: (category: any, row: FeeOrdersReportModel) => (
    //             <>
    //                 {row.category}
    //             </>
    //         )
    //     },
    //     {
    //         title: 'Product name',
    //         dataIndex: 'name',
    //         key: 'orderReport-2',
    //         render: (name: any, row: FeeOrdersReportModel) => (
    //             <>
    //                 {row.name}
    //             </>
    //         )
    //     },
    //     {
    //         title: 'ASIN',
    //         dataIndex: 'asin',
    //         key: 'orderReport-3',
    //         render: (asin: any, row: FeeOrdersReportModel) => (
    //             <>
    //                 {row.asin}
    //             </>
    //         )
    //     },

    //     {
    //         title: 'Quantity',
    //         dataIndex: 'quantity',
    //         key: 'orderReport-4',
    //         render: (quantity: any, row: FeeOrdersReportModel) => (
    //             <>
    //                 {row.quantity}
    //             </>
    //         )
    //     },
    //     {
    //         title: 'Price',
    //         dataIndex: 'price',
    //         key: 'orderReport-5',
    //         render: (price: any, row: FeeOrdersReportModel) => (
    //             <>
    //                 {row.price}
    //             </>
    //         )
    //     },
    //     {
    //         title: 'Date',
    //         dataIndex: 'date',
    //         key: 'orderReport-6',
    //         width: 170,

    //         render: (date: any, row: FeeOrdersReportModel) => (
    //             <>
    //                 <> {row.date ? `${moment(new Date(row.date)).format("MM-DD-YYYY")}` : ("")} </>
    //             </>
    //         )
    //     }
    // ]

    const onMatchPressed = (record: CashbackRequestModel) => {
        setModalVisible(true);
        //let cashbackRequestIds = selectedRowItem.map(item => item.cashbackRequestId);
        let cashbackRequestSelected = cashbackRequestModel.filter(item => item.id === record.id);
        setSelectedCashbackRequestModel(cashbackRequestSelected);
        //let totalAmount = cashbackRequestSelected[0].feeOrdersMatching.reduce((sum, current) => sum + (current.price * current.quantity), 0);
        //setTotalAmount(totalAmount);
    }

    const onResetUploadedData = () => {
        setFeeOrdersReport([]);
        setFeeOrdersReport([]);
        setFeeEarningsReport([]);
        setKeyExpanded([]);
        setSelectedRowKey([]);
        setSelectedRowItem([]);

        cashbackRequestModel.forEach(item => {
            item.feeOrdersMatching = []
        })

        setCashbackRequest(cashbackRequestModel);
        setSendNotificationUploaded(false);

    }

    const onUploadChange = (info: UploadChangeParam) => {
        if (info) {
            if (info.file && info.file.status === "uploading") {
                const reader = new FileReader();
                reader.onload = (evt) => {

                    const result = evt.target?.result;
                    const workBook = XLSX.read(result, { type: "binary" });
                    if (workBook) {
                        readFeeOrdersReport(workBook);
                        readFeeEarningsReport(workBook);
                    }
                };
                reader.readAsBinaryString(info.file.originFileObj as any);
            }
        }
    }

    const readFeeOrdersReport = (workBook: XLSX.WorkBook) => {
        let feeOrdersReport: Array<FeeOrdersReportModel> = [];
        const workSheet = workBook.Sheets['Fee-Orders'];
        const data = XLSX.utils.sheet_to_json(workSheet);

        data.forEach((item: any) => {
            let obj: FeeOrdersReportModel = {
                index: newGuid(),
                cashbackRequestId: '',
                asin: item['ASIN'],
                category: item['Category'],
                price: item['Price($)'],
                name: item['Name'],
                quantity: item['Qty'],
                date: new Date(item['Date'])
            }
            feeOrdersReport.push(obj);
        });

        if (feeOrdersReport.length > 0) {
            setFeeOrdersReport(feeOrdersReport);
            success('Fee orders report uploaded successfully');
        }
    }

    const readFeeEarningsReport = (wb: XLSX.WorkBook) => {
        debugger;
        let feeEarningsReport: Array<FeeEarningsReportModel> = [];
        const workSheet = wb.Sheets['Fee-Earnings'];
        if (workSheet) {
            const data = XLSX.utils.sheet_to_json(workSheet);
            if (data) {
                data.forEach((item: any) => {
                    let obj: FeeEarningsReportModel = {
                        asin: item['ASIN'],
                        adFees: item['Ad Fees($)'],
                        category: item['Category'],
                        dateShipped: item['Date Shipped'],
                        itemsShipped: item['Items Shipped'],
                        price: item['Price($)'],
                        name: item['Name'],
                        revenue: item['Revenue($)'],
                        seller: item['Seller'],
                        trackingId: item['Tracking ID'],
                        returns: item['Returns']

                    }
                    feeEarningsReport.push(obj);
                });
                if (feeEarningsReport.length > 0) {
                    setFeeEarningsReport(feeEarningsReport);
                    success('Fee earnings report uploaded successfully');
                }
            }
        }
    }

    const onPreview = async (info: UploadFile<any>) => {
        if (info) {
            let src = info?.url;
            if (!src) {
                src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(info.originFileObj as any);
                    reader.onload = () => resolve(reader.result as any);
                });
            }
        }
    }

    const onImageOpen = (value: boolean) => {
        setHideChild(value)
    }

    const getInvoiceUrls = (item: CashbackRequestModel) => {   
        return (
            <ImagePreviewGroupComponent key={item.id} imageUrls={item.invoicesUrls} isOpen={visible} isParent={true} changeVisibility={onImageOpen} />
        )
    }

    const handleOk = () => {
        setLoading(true); let filterCashbackRequests = cashbackRequestModel.filter(item => item.id != selectedCashbackRequestModel[0].id);
        setCashbackRequest(filterCashbackRequests);
        setVisible(false);
        setModalVisible(false);
        setModalVisible(false);
        setCashbackRequest(filterCashbackRequests);
        handleCancel();
        setLoading(false);
    };

    const handleCancel = () => {
        setSelectedRowItem([]);
        setSelectedRowKey([]);
        setModalVisible(false)
        setKeyExpanded([]);
    };

    const onTableRowExpand = (expanded: boolean, record: any) => {
        if (feeOrdersReport.length == 0) {
            setSelectedRowKey([]);
            setSelectedRowItem([]);
            setKeyExpanded([]);
            setSelectedUnMatchedRowItem([]);
            setSelectedUnMatchedRowKey([]);
        } else {
            let keys: Array<string> = [];
            if (expanded) {
                keys.push(record.id);
            }
            setKeyExpanded(keys);
            setSelectedRowKey([]);
            setSelectedRowItem([]);
            setSelectedUnMatchedRowItem([]);
            setSelectedUnMatchedRowKey([]);

        }
    }

    // const rowSelection = {

    //     onChange: (selectedKeys: any, selectedRows: Array<any>) => {

    //         let _selectedKey: Array<string> = [];
    //         _selectedKey = selectedRows.map(itm => itm.index);

    //         setSelectedRowKey(_selectedKey);
    //         setSelectedRowItem(selectedRows);

    //     },
    //     getCheckboxProps: (record: any) => ({}),
    // };

    // const rowUnMachedSelection = {

    //     onChange: (selectedKeys: any, selectedRows: Array<any>) => {

    //         let _selectedKey: Array<string> = [];
    //         _selectedKey = selectedRows.map(itm => itm.index);

    //         setSelectedUnMatchedRowKey(_selectedKey);
    //         setSelectedUnMatchedRowItem(selectedRows);

    //     },
    //     getCheckboxProps: (record: any) => ({}),
    // };

    const onHandleReject = (modelSelected?: CashbackRequestModel) => {
        setLoading(true);
        let cashbackRequested = (modelSelected)
            ? cashbackRequestModel.filter(item => item.id == modelSelected.id)
            : cashbackRequestModel.filter(item => keyExpanded.includes(item.id));

        let model: RejectCashbackRequestModel = {
            cashbackRequestId: cashbackRequested[0].id,
            purchaseId: cashbackRequested[0].purchaseDetails.id,
            userId: cashbackRequested[0].user.id,
        }

        try {
            commandRequest(() => marketplace.rejectCashbackRequestTransaction(model))
                .then(() => {
                    props.updateMarketplaceCashbackRequestInStoreAction([]);
                    props.updateRejectedCashbackRequestInStoreAction(null, initSearchParams)
                    resetStates();
                    setLoading(false);
                }, (reason: any) => {
                    setLoading(false);
                    resetStates();
                })
                .catch((error) => {
                    setLoading(false);
                    resetStates();
                })
        } catch (error) {
            resetStates();
        }
        resetStates();
    }

    const resetStates = () => {
        setModalVisible(false);
        setSelectedRowItem([]);
        setSelectedRowKey([]);
        setKeyExpanded([]);
    }

    const onRequestMoreInfo = (item: CashbackRequestModel | null) => {
        setModalMoreInfoVisible(true);
        if (item) {
            setModalMoreInfoModel(item);
        } else {
            let model = cashbackRequestModel.find(item => keyExpanded.includes(item.id)) ?? null;
            setModalMoreInfoModel(model);
        }
    }

    const closeModalMoreInfo = (status: boolean) => {
        setModalMoreInfoVisible(status);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        setPageSize(pagination.pageSize)
    }

    const onActiveTabChanged = (selectedKey: string) => {
        resetStates();
        handleCancel();
        onResetUploadedData();
        setActiveTab(selectedKey);
    }

    // const ButtonRowNested: React.FC<any> = (record: CashbackRequestModel) => {
    //     console.log("row button: ")
    //     return (
    //         <>
    //             <Popconfirm
    //                 title="Are you sure to reject this cashback?"
    //                 onConfirm={() => onHandleReject()}
    //                 okText="Yes"
    //                 cancelText="No"
    //             >
    //                 <Button onClick={() => { }} className="reject-button"
    //                     icon={<CloseOutlined />}>Reject</Button>
    //             </Popconfirm>

    //             <Button
    //                 onClick={() => { onRequestMoreInfo(null) }}
    //                 className="more-info-button"
    //                 icon={<InfoCircleOutlined />}>Request for more informations</Button>

    //             <Button
    //                 // disabled={selectedRowItem.length == 0}
    //                 onClick={() => {
    //                     onMatchPressed(record)
    //                 }}
    //                 // className={(selectedRowItem.length == 0) ? "match-button disabled" : "match-button"}
    //                 className={"match-button"}

    //                 icon={<CheckOutlined />}>Match</Button>
    //         </>
    //     )

    // }

    // const feeOrderMatchesTable = (records: Array<FeeOrdersReportModel>) => {
    //     return (
    //         <Table
    //             pagination={{ pageSize: pageSize, showSizeChanger: true }}
    //             rowKey="index"
    //             dataSource={records}
    //             columns={feeOrdersColumns}
    //             scroll={{ x: 1200 }}
    //             rowSelection={
    //                 {
    //                     type: 'checkbox',
    //                     ...rowSelection,
    //                     selectedRowKeys: selectedRowKeys
    //                 }
    //             }
    //         />
    //     )
    // }

    const UploadButtons: React.FC<any> = () => {
        return (

            <Row className="row-buttons">
                <Upload

                    onChange={(info) => {
                        onUploadChange(info)
                    }}
                    onPreview={onPreview}
                    showUploadList={false}
                    className="upload-button"
                    disabled={feeEarningsReport.length > 0 || feeOrdersReport.length > 0 || cashbackRequestModel.length == 0}
                >
                    <Button
                        disabled={feeEarningsReport.length > 0 || feeOrdersReport.length > 0 || cashbackRequestModel.length == 0}
                        className="btn-item"
                        icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>

                {(feeEarningsReport.length > 0 || feeOrdersReport.length > 0) &&
                    <Button onClick={() => {
                        onResetUploadedData()
                    }} className="reset-button" icon={<ClearOutlined />}>Reset uploaded data</Button>
                }

            </Row>
        )
    }

    return (
        <Content>
            <BreadcrumbComponent {...currentBreadCrumbs} />
            <Spin spinning={isLoading}>
                <Row className="home-content">
                    <Row className="row-card-content">
                        <Card className="card-section">
                            {modalVisible && 
                            <AddMatchedCashbackRequestComponent
                                changeVisibility={handleCancel}
                                isVisible={modalVisible}
                                selectedCashbackRequest={selectedCashbackRequestModel}
                                feeOrdersMatched={selectedRowItem.concat(selectedUnMatchedRowItem)}
                                amazonAffiliates={amazonAffiliates}
                                onConfirmCashbackPressed={handleOk}
                            />
}

                            <RequestMoreInformationComponent {...{
                                isVisible: modalMoreInfoVisible,
                                marketplaceCashbackRequest: modalMoreInfoModel,
                                changeVisibility: closeModalMoreInfo
                            }} />

                            <Tabs defaultActiveKey={activeTab}
                                onChange={onActiveTabChanged}
                                tabBarExtraContent=
                                {
                                    <Row className="row-buttons">
                                        <Upload

                                            onChange={(info) => {
                                                onUploadChange(info)
                                            }}
                                            onPreview={onPreview}
                                            showUploadList={false}
                                            className="upload-button"
                                            disabled={feeEarningsReport.length > 0 || feeOrdersReport.length > 0 || cashbackRequestModel.length == 0}
                                        >
                                            <Button
                                                disabled={feeEarningsReport.length > 0 || feeOrdersReport.length > 0 || cashbackRequestModel.length == 0}
                                                className="btn-item"
                                                icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>

                                        {(feeEarningsReport.length > 0 || feeOrdersReport.length > 0) &&
                                            <Button onClick={() => {
                                                onResetUploadedData()
                                            }} className="reset-button" icon={<ClearOutlined />}>Reset uploaded data</Button>
                                        }

                                    </Row>
                                }>

                                <TabPane
                                    tab="User requests for cashback"
                                    key="cashback-requests">
                                    <Table locale={{
                                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="No results for ths search. Try again..." />
                                    }}
                                        pagination={{ pageSize: pageSize, showSizeChanger: true }}
                                        onRow={(record: any) => ({})}
                                        sortDirections={['ascend', 'descend', 'ascend']}
                                        scroll={{ x: 1000 }}
                                        onChange={(onChangeEvent)}
                                        columns={columns}
                                        dataSource={cashbackRequestModel}
                                        rowKey="id"
                                        onExpand={onTableRowExpand}
                                        expandedRowKeys={keyExpanded}
                                        expandable={{
                                            expandedRowRender: (record: CashbackRequestModel) =>
                                                <>
                                                </>,
                                            rowExpandable: (record: CashbackRequestModel) => (record.feeOrdersMatching.length > 0),
                                        }}
                                    />
                                    <Row>
                                        <AmazonOrderReportComponent feeOrdersRecords={feeOrdersReport}
                                            feeEarningRecords={feeEarningsReport}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Row>

                    <Row className={hideChild ? "row-card-content row-child-changed" : "row-card-content"}>
                        <Card className="card-section">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Matched cashback request" key="1">
                                    <ApprovedCashbackRequestsComponent />
                                </TabPane>
                                <TabPane tab="Rejected cashback request" key="2">
                                    <RejectedCashbackRequestsComponent />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Row>
                </Row>

            </Spin>
        </Content>
    )
}

const mapStateToProps = ({ marketplaceCashbackRequest, allMarketplaces }: AppState) =>
({
    marketplaceCashbackRequest: marketplaceCashbackRequest,
    allMarketplaces: allMarketplaces,
});

export default connect(mapStateToProps, {
    updateMarketplaceCashbackRequestInStoreAction,
    updateApprovedCashbackRequestInStoreAction,
    updateRejectedCashbackRequestInStoreAction,
    updateAllMarketplacesInStoreAction
})(AmazonCashbackRequestComponent);