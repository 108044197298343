import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { PlaidDetailsPaymentOption} from '../../../models/dashboard/users/plaidDetails';
import { GetListOfDevices, PaginatedDeciceIdsUsersModel } from "../../../models/dashboard/users/user";
import getDeviceWithMoreUsers from "../../../services/dashboard/users/getDeviceIdsWithMoreUsers";

export const GET_DEVICEIDS_USERS_SUCCESS = "GET_DEVICEIDS_SUCCESS";
export const GET_DEVICEIDS_USERS_ERROR = "GET_DEVICEIDS_ERROR";


export interface DeviceIdsWithMoreUsersInStoreAction{
    type: typeof GET_DEVICEIDS_USERS_SUCCESS | typeof GET_DEVICEIDS_USERS_ERROR,
    deviceIdsUsers: PaginatedDeciceIdsUsersModel | null
}

export const initialDetails: PaginatedDeciceIdsUsersModel = {
    items: [],
    totalItemCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber:  0
};

export const updateDeviceIdsWithMoreUsersInStoreAction = (
    searchParams: GetListOfDevices
)
: ThunkAction<void, AppState, unknown, Action> => dispatch =>{
    queryRequest(()=> getDeviceWithMoreUsers(searchParams))
    .then((res:any)=>{
        dispatch({
            type:GET_DEVICEIDS_USERS_SUCCESS,
            deviceIdsUsers: res
        } as DeviceIdsWithMoreUsersInStoreAction);
    })
    .catch((err) => {
        dispatch({
            type: GET_DEVICEIDS_USERS_ERROR,
            deviceIdsUsers: initialDetails
        } as DeviceIdsWithMoreUsersInStoreAction);
        
    });
}