import { ShoppingOutlined } from "@ant-design/icons";
import { Badge, Col, Empty, Table } from "antd";
import Row from "antd/lib/row";
import moment from "moment";
import React from "react";
import { CashbackMarketplaceCollected, CashBackTransactionDetailsModel, CollectionType, TransferType } from "../../../../core/models/dashboard/transactions/transation";
import "../../../../styles/layout/dashboard/transactions/cashbackActivity.less";

import TransactionTypeDetailsComponent from "../../../common/components/dashboard/TransactionTypeDetailsComponent";
//import { CurrencyNumberFormat } from "../../../common/utils/Utils";

export interface CashbackActivityComponentProps {
    procesingDate: Date,
    availableDate: Date,
    cashBackActivity: CashBackTransactionDetailsModel;
}

const CashbackActivityComponent: React.FC<CashbackActivityComponentProps> = props => {
    const columns = [
        {
            title: 'Affiliate', dataIndex: 'marketplaceName', key: '0',
            render: (name: any, record: CashbackMarketplaceCollected) => (
                <> { record.marketplaceName ? record.marketplaceName : ("")} </>
            )
        },
        {
            title: 'Tracked date', dataIndex: 'trackedOn', key: '1',
            render: (percentage: any, record: CashbackMarketplaceCollected) => (
                <> {record.trackedOn ? `${moment(new Date(record.trackedOn)).format("MM-DD-YYYY")}` : ("")}</>
            )
        },
        {
            title: 'Cashback amount', dataIndex: 'cashback', key: '1',
            render: (percentage: any, record: CashbackMarketplaceCollected) => (
                <> { record.cashback ? `$${record.cashback}` : ("")} </>
            )
        }
    ]

    return (<>
        <TransactionTypeDetailsComponent {...{ transferType: TransferType.Cashback }}></TransactionTypeDetailsComponent>

        <Row className="cashbackActivity-details" gutter={[16, 16]}>
            <Col span={8} >
                <span className="item-title">Processing date</span>
                <span className="item-value">{props.procesingDate ? `${moment(new Date(props.procesingDate)).format("MM-DD-YYYY")}` : ("")} </span>

            </Col>
            <Col span={8} >
                <span className="item-title">Available Date</span>
                <span className="item-value">{props.availableDate ? `${moment(new Date(props.availableDate)).format("MM-DD-YYYY")}` : ("")} </span>
            </Col>
            <Col span={8} >
                <span className="item-title">To</span>
                <span className="item-value" >
                    {props?.cashBackActivity.goals && props.cashBackActivity.goals.length > 0 ?
                        <>
                            {props.cashBackActivity.goals.map(item => {
                                return (
                                    <> {
                                        item.goalName
                                    }<br></br>
                                    </>
                                )
                            })}
                        </>
                        : ("--")}
                </span>
            </Col>
        </Row>
        <Row className="cashbackActivity-details" gutter={[16, 16]}>
            <Col span={16} offset={2}>
                <Table
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                    pagination={false}
                    columns={columns} dataSource={props.cashBackActivity.marketplace} />
            </Col>
        </Row>

    </>)
}

export default CashbackActivityComponent;
