import { Badge, Card, Empty, Popover, Row, Spin, Table, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { CashBackStatus, CSVMarketplacePurchase, MarketplacePurchase, MarketplacePurchaseSearch, PurchaseStatus } from "../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import CashbackStatusComponent from "../../../common/components/dashboard/CashbackStatusComponent";
import PurchaseStatusComponent from "../../../common/components/dashboard/PurchaseStatusComponent";
import { updateMarketplacePurchaseByMarketplaceInStoreAction } from "../../../../core/actions/dashboard/marketplacePurchase/updateMarketplacePurchaseByMarketplaceInStore";
import marketplacePurchaseActions from "../../../../core/actions/dashboard/marketplacePurchase/index";
import transactionMarketplaceResource from "./Resource";
import { CSVLink } from "react-csv";
import { DownloadOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";
import CashbackDetailsMarketplacePurchaseComponent from "../../marketplacePurchase/CashbackDetailsMarketplacePurchaseComponent";
import ButtonComponent from "../../../common/components/dataDisplay/Button";
import { error, getCashbackStatusName, getPlatformName, getPurchaseStatusName, maxStringLength, s3Url, success } from "../../../common/utils/Utils";
import resource from "../../../common/resource/resource";

const { updateAllMarketplacePurchaseByMarketplaceInStoreAction } = marketplacePurchaseActions;
const { TabPane } = Tabs;



const initSearchParams: MarketplacePurchaseSearch = {
    affiliateId: null,
    orderDescending: true,
    pageNumber: 1,
    pageSize: 5,
    searchValue: "",
    sortColumn: "CreatedAt",
    userId: null
}

const TransactionMarketplaceComponent: React.FC<any> = (props: any) => {
    const [isLoading, setLoading] = useState(false);
    const [CSVMarketplacePurchases, setCSVMarketplacePurchases] = useState<Array<CSVMarketplacePurchase>>([]);
    const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);
    const [isCollapsed, setCollapsed] = useState(false);

    const defaultSortColumnt = "CreatedAt";
    
    useEffect(() => {
        initSearchParams.affiliateId = props.affiliateId;
        props.updateMarketplacePurchaseByMarketplaceInStoreAction(null, initSearchParams, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);
        props.updateAllMarketplacePurchaseByMarketplaceInStoreAction([]);
    }, []);

    const columns = [
        {
            title: 'Affiliate', dataIndex: 'marketplace.name',
            key: 'Id',
            width: 220,
            fixed: true,
            sorter: true,
            render: (id: string, row: MarketplacePurchase) => (
                <>
                        <span className="affiliate-list">
                            {row.marketplace.iconUrl ?
                                <img className="icon" src={`${s3Url}${row.marketplace.iconUrl}`}></img>
                                : ('')}
                            <span className="name">
                                {row.marketplace?.name.length > maxStringLength ?
                                    <>
                                        <Popover content={row.marketplace.name} title="Name" trigger="hover">
                                            {`${row.marketplace?.name.substring(0, maxStringLength)}...`}
                                        </Popover>
                                    </> :
                                    <>  {row.marketplace.name} </>
                                }
                            </span>
                        </span>
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.purchaseDate}`, dataIndex: 'purchaseDate',
            width: 220,
            sorter: true,
            key: 3, render: (purchaseDate: Date, row: MarketplacePurchase) => (
                <> { purchaseDate ? `${moment(new Date(purchaseDate)).format("MM-DD-YYYY")}` : ("")} </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.user}`, dataIndex: 'user.fullName', width: 170, key: 5, sorter: true,
            render: (lastModifiedAt: Date, row: MarketplacePurchase) => (
                <> { row.user ? `${row.user.fullName}` : ("")} </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.status}`, dataIndex: 'status', width: 220, key: 6, sorter: true,
            render: (status: PurchaseStatus, row: MarketplacePurchase) => (
                <>
                  <PurchaseStatusComponent {...{purchaseStatus: status}}></PurchaseStatusComponent>
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.cashBackStatus}`, dataIndex: 'cashBackStatus', sorter: true, width: 220, key: 7, render: (cashBackStatus: number, row: any) => (
                <>
                    <CashbackStatusComponent {...{ status: cashBackStatus }}></CashbackStatusComponent>
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.totalCashBackPercentage}`,
            dataIndex: 'marketplace.totalCashBackPercentage',
            width: 220,
            key: 8,
            render: (cashBackPercentage: number, row: MarketplacePurchase) => (
                <>
                    { row.marketplace ? `${(row.marketplace.totalCashBackPercentage * 100).toFixed(2)}%` : ("")}
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.guacCashBackPercentage}`,
            dataIndex: 'marketplace.guacCashBackPercentage',
            width: 220,
            key: 8,
            render: (guacCashBackPercentage: number, row: MarketplacePurchase) => (
                <>
                    { row.marketplace ? `${row.marketplace.guacCashBackPercentage * 100}%` : ("")}
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.userCashBackPercentage}`,
            dataIndex: 'marketplace.cashBackPercentage',
            width: 220,
            key: 8,
            render: (cashBackPercentage: number, row: MarketplacePurchase) => (
                <>
                    { row.marketplace ? `${row.marketplace.cashBackPercentage * 100}%` : ("")}
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.totalCommission}`, dataIndex: 'totalCommission', key: 9,
            width: 220,
            render: (totalCommission: number, row: MarketplacePurchase) => (
                <>
                    { row.totalCommission ? `$${row.totalCommission.toFixed(2)}` : ("")}
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.guacCashBack}`, dataIndex: 'guacCashBack', key: 9,
            width: 220,
            render: (guacCashBack: number, row: MarketplacePurchase) => (
                <>
                    { row.guacCashBack ? `$${row.guacCashBack.toFixed(2)}` : ("")}
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.userCashBack}`, dataIndex: 'cashBack', key: 9,
            width: 220,
            render: (cashBack: number, row: MarketplacePurchase) => (
                <>
                    { row.cashBack ?  `$${row.cashBack.toFixed(2)}` : ("")}
                </>
            )
        },
        {
            title: `${transactionMarketplaceResource.tableColumns.amount}`, dataIndex: 'amount', key: 10,
            sorter: true,
            width: 220,
            render: (amount: number, row: MarketplacePurchase) => (
                <>
                    { row.amount ? `$${row.amount.toFixed(2)}` : ("")}

                </>
            )
        }
    ];

    const onTableRowExpand = (expanded: boolean, record: any) => {
        let keys: Array<string> = [];
        if (expanded) {
            keys.push(record.id);
        }
        setKeyExpanded(keys);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        setLoading(true);
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
            } else {
                initSearchParams.sortColumn = defaultSortColumnt;
                initSearchParams.orderDescending = true;
            }
        }

        if (pagination) {
            if (props.marketplaces?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.updateMarketplacePurchaseByMarketplaceInStoreAction(null, initSearchParams, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);
    }
    
    const onGetAffiliateDetailsSuccess = () => {
        success(transactionMarketplaceResource.success);
        setLoading(false);
    }

    const onGetAffiliateDetailsFailure = () => {
        error(transactionMarketplaceResource.error);
        setLoading(false);
    }
    
    if (props.allMarketplacePurchases.length && CSVMarketplacePurchases.length == 0) {
        let exportedData: Array<CSVMarketplacePurchase> = [];

        props.allMarketplacePurchases.forEach((item: MarketplacePurchase) => {

            exportedData.push({
                PurchaseDate: item.purchaseDate,
                OrderNumber: item.associatedPurchase,
                Platform: getPlatformName(item.type),
                Marketplace: item.marketplace.name,
                User: item.user.fullName,
                PurchaseStatus: getPurchaseStatusName(item.status),
                CashBackStatus: getCashbackStatusName(item.cashBackStatus),
                Amount: item.amount,
                TotalCommission: item.totalCommission,
                CashBack: item.cashBack,
                GuacCashBack: item.guacCashBack,
                IsCollected: item.isCollected,
                CollectedDate: item.collectedDate,
                TrackedOn: item.cashBackCalculated,
                ClaimedDate: item.claimedDate,
                ExpiredOn: item.expiredOn,
                AvailableOn: item.availableOn,
                CanceledOn: item.canceledOn
            })
        });
        setCSVMarketplacePurchases(exportedData);
    }

    const collapse = () => {
        setCollapsed(!isCollapsed);
    }

    const cardHeaderTitle = () => {
        return (
            <>{isCollapsed ?
                <><DownOutlined onClick={collapse} /> {`Marketplace Purchases`}
                </>
                :
                <>
                    <RightOutlined onClick={collapse} /> {`Marketplace Purchases`}
                </>
            }
            </>
        )
    }

    return <> 
          <Spin spinning={isLoading}>
            <Card onClick={collapse} className={isCollapsed ? "collapsed-off" : "collapsed-in"} title={cardHeaderTitle()}>

                <Tabs defaultActiveKey="1">
                    <TabPane tab={`${transactionMarketplaceResource.transactions}`} key="1">
                       <Row className="row-header-actions">
                            <Row className="header-actions">
                                <CSVLink
                                    filename={`${transactionMarketplaceResource.export.fileName}`}
                                    title={`${transactionMarketplaceResource.export.title}`}
                                    data={CSVMarketplacePurchases} >
                                    <ButtonComponent shape="round"
                                        disabled={props.marketplacePurchases?.items?.length == 0}
                                        icon={<DownloadOutlined />}
                                        name={`${transactionMarketplaceResource.export.name}`} />
                                </CSVLink>
                            </Row>                        
                       </Row>
                        <Table
                            columns={columns}
                            rowKey="id"
                            onExpand={onTableRowExpand}
                            expandedRowKeys={keyExpanded}
                            expandable={{
                                expandedRowRender: (record: MarketplacePurchase) => <>
                                    <CashbackDetailsMarketplacePurchaseComponent {...{ marketplacePurchase: record }}>
                                    </CashbackDetailsMarketplacePurchaseComponent>
                                </>,
                                rowExpandable: (record: MarketplacePurchase) => (record.cashBackStatus != CashBackStatus.Processing),
                            }}

                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`${resource.generic.tableEmpty}`} /> }}

                            pagination={{
                                total: props.marketplacePurchases?.totalItemCount, current: props.marketplacePurchases?.pageNumber,
                                pageSize: props.marketplacePurchases?.pageSize,
                                showSizeChanger: false,
                            }}

                            scroll={{ x: 1200 }}
                            onChange={onChangeEvent}
                            dataSource={props.marketplacePurchases?.items}
                        />
                    </TabPane>
                </Tabs>
            </Card>
        </Spin>
    </>
}
const mapStateToProps = ({ marketplacePurchases, allMarketplacePurchases }: AppState) =>
({
    marketplacePurchases: marketplacePurchases,
    allMarketplacePurchases: allMarketplacePurchases
});

export default connect(mapStateToProps, {
    updateMarketplacePurchaseByMarketplaceInStoreAction,
    updateAllMarketplacePurchaseByMarketplaceInStoreAction
})(TransactionMarketplaceComponent);