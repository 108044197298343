import React, {FC} from 'react';
import {Image} from 'antd';
import logoUrl from '../../../assets/icons/logo-only.png';
import fullLogoUrl from '../../../assets/icons/login-logo.png';
import './logo.less';

type LogoProps = {
    isCollapsed: boolean
}

const Logo : FC<LogoProps> = (props) => {
    const {isCollapsed} = props;
    return (
        <div
            className={`logo-menu-layout logo-menu-layout-${isCollapsed ? 'collapsed' : 'expanded'}`}
        >
            { isCollapsed
                ? <img
                    width={27}
                    height={30.8}
                    src={logoUrl}
                    alt={'Logo'}
                />
                : <img
                    width={73}
                    height={30.8}
                    src={fullLogoUrl}
                    alt={'Logo'}
                />
            }
        </div>
    );
};

export default Logo;