import { AffiliateDetailsInStoreAction, GET_AFFILIATE_DETAILS_ERROR, GET_AFFILIATE_DETAILS_SUCCESS } from "../../../../actions/dashboard/marketplace/affiliates/updateAffiliateDetailsInStore";
import { AffiliateDetailsModel } from "../../../../models/dashboard/marketplace/affiliateDetails";

export const updateAffiliateDetailsInStoreReducer = (state: AffiliateDetailsModel | null = null, action: AffiliateDetailsInStoreAction) => {
    switch (action.type) {
        case GET_AFFILIATE_DETAILS_SUCCESS:
            return { ...state, ...action.affiliateDetails }
        case GET_AFFILIATE_DETAILS_ERROR:
            return {...state};
        default:
            return {...state};
    }
}