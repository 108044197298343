import { DeviceIdsWithMoreUsersInStoreAction, GET_DEVICEIDS_USERS_ERROR, GET_DEVICEIDS_USERS_SUCCESS } from '../../../actions/dashboard/users/updateDeviceIdsWithMoreUsersInStore';
import { PaginatedDeciceIdsUsersModel, UserModel } from '../../../models/dashboard/users/user';


export const updateDeviceIdsWithMoreUsersInStoreReducer = (state: PaginatedDeciceIdsUsersModel | null = null, action: DeviceIdsWithMoreUsersInStoreAction) => {
    switch (action.type) {
        case GET_DEVICEIDS_USERS_SUCCESS:
            return { ...state, ...action.deviceIdsUsers };
        case GET_DEVICEIDS_USERS_ERROR:
            return null;
        default:
            return state;
    }
}