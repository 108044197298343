import React from "react";
import {Row} from "antd";
import MarketplaceProgramReserve from "./MarketplaceProgramReserve";
import TotalMarketPlacePurchase from "./TotalMarketPlacePurchase";

export const MarketplaceCoreDataWrapper: React.FC = () => {
    return (
        <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
            <MarketplaceProgramReserve/>
            <TotalMarketPlacePurchase/>
        </Row>
    );
};

export default MarketplaceCoreDataWrapper;