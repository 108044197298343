import {AdminNotificationExecutionLogModel} from "../../../models/dashboard/notification/notification";
import {
    GET_NOTIFICATION_SIMPLE_LOGS_FAILURE,
    GET_NOTIFICATION_SIMPLE_LOGS_SUCCESS, NotificationSimpleLogsInStoreAction
} from "../../../actions/dashboard/notification/updateNotificationLogsInStoreSimple";


const logsInitialState = {
    totalItemCount : 0,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    items: []
} as AdminNotificationExecutionLogModel;

export const updateNotificationLogsInStoreSimpleReducer = (
    state: AdminNotificationExecutionLogModel | null = logsInitialState,
    action: NotificationSimpleLogsInStoreAction) => {
    switch (action.type) {
        case GET_NOTIFICATION_SIMPLE_LOGS_SUCCESS:
            return {
                ...state,
                ...action.model
            };
        case GET_NOTIFICATION_SIMPLE_LOGS_FAILURE:
            return {...state};
        default:
            return {...state};
    }
}