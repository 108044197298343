import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import {queryRequest} from "../../../../app/common/requestUtils/requestUtils";
import {AppState} from "../../../commons/reduxStore";
import {GetGoals, Goals} from "../../../models/dashboard/goals/goal";
import getGoalList from "../../../services/dashboard/goals/getGoals";
import {executeAction} from "../../../commons/baseAction";


export const GET_GOALS_LIST_SUCCESS = "GET_GOALS_LIST_SUCCESS";
export const GET_GOALS_LIST_ERROR = "GET_GOALS_LIST_ERROR";

export interface UserGoalListInStoreAction {
    type: typeof GET_GOALS_LIST_SUCCESS | typeof GET_GOALS_LIST_ERROR;
    userGoals: Goals | null,
    searchParameters: GetGoals
}

export const updateUserGoalsListInStoreAction =
    (
        goals: Goals | null,
        searchParams: GetGoals,
        onSuccess: Function,
        onFailure: Function
    )
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        executeAction(
            queryRequest(() =>
                getGoalList(
                    searchParams.searchValue,
                    searchParams.pageNumber,
                    searchParams.pageSize,
                    searchParams.orderDescending,
                    searchParams.userId,
                    searchParams.deletedGoals,
                    searchParams.sortColumn)
            ),
            (res: any) => {
                dispatch({
                    type: GET_GOALS_LIST_SUCCESS,
                    userGoals: res
                } as UserGoalListInStoreAction);
                onSuccess();
            },
            (_: any) => {
                dispatch({
                    type: GET_GOALS_LIST_ERROR,
                    userGoals: goals
                } as UserGoalListInStoreAction);
                onFailure(_);
            }
        );
    }