import { UserSubscriptions } from './../../../../models/dashboard/subscriptions/subscriptions';
import { AllUserSubscriptionsInStoreAction, GET_ALL_USERSUBSCRIPTIONS_ERROR, GET_ALL_USERSUBSCRIPTIONS_SUCCESS } from '../../../../actions/dashboard/subscriptions/userSubscriptions/updateAllUserSubscriptionsInStore';

export const updateAllUserSubscriptionsInStoreReducer = (state: Array<UserSubscriptions> | [] = [], 
    action: AllUserSubscriptionsInStoreAction) => {
    switch (action.type) {
        case GET_ALL_USERSUBSCRIPTIONS_SUCCESS:
            return [...state, ...action.allUserSubscriptions ]
        case GET_ALL_USERSUBSCRIPTIONS_ERROR:
            return [...state];
        default:
            return [];
    }
}