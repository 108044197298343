import { BankOutlined, CheckCircleFilled, CheckOutlined, ClockCircleOutlined, CloseOutlined, DeleteFilled, DollarCircleOutlined, EditFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { CashBackStatus, MarketplacePurchaseType } from "../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import { UserStatus } from "../../../../core/models/dashboard/users/user";
import { s3Url } from "../../utils/Utils";

export interface AffiliatePartnerProps {
    iconUrl?:string | null,
    status: MarketplacePurchaseType
}

const AffiliatePartnerComponent: React.FC<AffiliatePartnerProps> = props => {

    return (
        <>
            {props.status == MarketplacePurchaseType.Flex &&
                (
                    //todo: add img
                    
                    <> {props.iconUrl  ?   <img className="platform-icon" src={`${s3Url}${props.iconUrl}`}></img> : ("")}
                        <span className="platform-title">Flex</span>
                    </>
                )
            }
            {props.status == MarketplacePurchaseType.Impact &&
                (
                    //todo: add img
                    <> {props.iconUrl  ?   <img className="platform-icon" src={`${s3Url}${props.iconUrl}`}></img> : ("")}
                    <span  className="platform-title">Impact</span>
                </>
                )
            }

        </>
    )
}

export default AffiliatePartnerComponent;