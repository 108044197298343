import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";

import  marketplaceAction from "../../../../core/actions/dashboard/marketplace/index";
import {  } from "../../../../core/actions/dashboard/marketplacePurchase/index";
import { useParams } from "react-router-dom";
import { error, success } from "../../../common/utils/Utils";
import { Content } from "antd/lib/layout/layout";
import BreadcrumbComponent, { BreadcrumbProps } from "../../../common/components/dataDisplay/BreadcrumbComponent";
import { RoutePaths } from "../../../routes";
import resource from "../../../common/resource/resource";
import {  Card,   Divider,  Row, Spin } from "antd";
import { MarketplacePurchaseModel, MarketplacePurchaseSearch } from "../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import AffiliatePurchaseComponent from "./purchases/AffiliatePurchaseComponent";
import AffiliateStatisticsComponent from "./statistics/AffiliateStatisticsComponent";
import { AffiliateDetailsModel } from "../../../../core/models/dashboard/marketplace/affiliateDetails";
import affiliateDetailsResource from "./Resource";
import AffiliatePromotionsComponent from "./promotions/PromotionComponent";
import DetailsComponent from "./details/DetailsComponent";
import "../../../../styles/layout/dashboard/marketplace/affiliateDetails/affiliateDetails.less";



const {updateAffiliateDetailsInStoreAction} = marketplaceAction;


export interface AffiliateDetailsProps {
    affiliateDetails: AffiliateDetailsModel;
    marketplacePurchases: MarketplacePurchaseModel;
    updateAffiliateDetailsInStoreAction: (Id: string, affiliateDetails: AffiliateDetailsModel | null, onSuccess: Function, onFailure: Function) => void;
}

interface AffiliateSelected {
    id: string
}

const initSearchMarketplacePurchases: MarketplacePurchaseSearch = {
    affiliateId: null,
    orderDescending: true,
    pageNumber: 1,
    pageSize: 5,
    searchValue: "",
    sortColumn: "CreatedAt",
    userId: null
}

const AffiliateDetailsComponent: React.FC<AffiliateDetailsProps> = props => {

    let affiliate = useParams<AffiliateSelected>();
    const [isLoading, setLoading] = useState(true);

    const currentBreadCrumbs: BreadcrumbProps = {
        title: resource.routes.dashboard.dashboard,
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: resource.routes.dashboard.marketplace,
                path: RoutePaths.AFFILIATE_PATH,
            },
            {
                title: resource.routes.dashboard.details,
                path: "",
            }
        ]
    };


    useEffect(() => {
        initSearchMarketplacePurchases.affiliateId = affiliate.id;
        props.updateAffiliateDetailsInStoreAction(affiliate.id, null, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);       
    }, []);

    const onGetAffiliateDetailsSuccess = () => {
        success(affiliateDetailsResource.success);
        setLoading(false);
    }

    const onGetAffiliateDetailsFailure = () => {
        error(affiliateDetailsResource.error);
        setLoading(false);
    }

    return (<>
        <Content>
            <BreadcrumbComponent {...currentBreadCrumbs} />
            <Spin spinning={isLoading}>
                <Row className="affiliate-details">
                    
                    <Row className="details-item">
                        <Row className="row-card">
                            <Card className="affiliate-card-item">
                                <DetailsComponent {...{ affiliateDetails: props.affiliateDetails }}> </DetailsComponent>
                                <Divider />
                                <Row className="details-row-card-item">
                                    <AffiliatePromotionsComponent {...{ percentageCommissions: props.affiliateDetails.percentageCommissions }}></AffiliatePromotionsComponent>
                                </Row>
                            </Card>
                        </Row>
                    </Row>

                    <Row className="statistic-item">
                        <AffiliateStatisticsComponent {...{ affiliateId: affiliate.id }} />
                    </Row>

                    <Row className="transaction-item">
                        <AffiliatePurchaseComponent {...{ affiliateId: affiliate.id }}></AffiliatePurchaseComponent>
                    </Row>

                </Row>

            </Spin>
        </Content>
    </>)
}

const mapStateToProps = ({ affiliateDetails, affiliateCategory, marketplacePlatform}: AppState) =>
({
    affiliateDetails: affiliateDetails,
    affiliateCategory:affiliateCategory,
    marketplacePlatform:marketplacePlatform
});

export default connect(mapStateToProps, {
    updateAffiliateDetailsInStoreAction
})(AffiliateDetailsComponent);