export const numberRegx = "[0-9]";
export const lowercaseRegx = "[a-z]";
export const uppercaseRegx = "[A-Z]";
export const specialCharRegx = "[!@#$%^&*]";
export const generalPasswordRegx: RegExp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
);
export const numberOnlyRegx = new RegExp("^[0-9]+$");
export const numbersAndSpacesOnlyRegx = new RegExp("[0-9]+$");
export const numbersAndSlashOnlyRegx = new RegExp("[0-9/]$");
export const underscoreRegx = new RegExp("^[0-9s+_]*$");
