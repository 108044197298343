import {CashbackRequestsCheckedPaginated} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";
import {
    ApprovedCashbackRequestInStoreAction,
    GET_APPROVED_CASHBACK_REQUEST_ERROR,
    GET_APPROVED_CASHBACK_REQUEST_SUCCESS
} from "../../../../actions/dashboard/marketplace/amazon/updateApprovedCashbackRequestsInStore";

export const updateApprovedRequestInStoreReducer = (state:  CashbackRequestsCheckedPaginated| null, action: ApprovedCashbackRequestInStoreAction) => {
    switch (action.type) {
        case GET_APPROVED_CASHBACK_REQUEST_SUCCESS:
            return action?.approvedCashbackRequest
        case GET_APPROVED_CASHBACK_REQUEST_ERROR:
            return {};
        default:
            return {...state};
    }
}
