import React, {useEffect, useState} from "react";
import PlaidItemDetailsView from "./PlaidItemDetailsView";
import {connect} from "react-redux";
import {AppState} from "../../../../../core/commons/reduxStore";
import {updatePaymentOptionInStoreAction} from "../../../../../core/actions/dashboard/users/updatePaymentOptionDetailsInStore";
import {message} from "antd";

const PaymentOptionPlaid : React.FC<any> = (props: any) => {

    const [isSpinning, setSpinning] = useState(false);

    const onSuccess = () =>{
        message.success('Primary card of use details loaded successfully!');
        setSpinning(false);
    };

    const onFailure = () => {
        message.info('Primary card of use details failed to load!');
        setSpinning(false);
    };


    const reload = () => {
        setSpinning(true);
        props.updatePaymentOptionInStoreAction(props.userId, onSuccess, onFailure);
    };

    useEffect(() => {
        props.updatePaymentOptionInStoreAction(props.userId, onSuccess, () => {});
    }, []);



    return (<>
        {
            props.paymentOptionDetails?.item != null
                ? <PlaidItemDetailsView
                    details={props.paymentOptionDetails}
                    spinning={isSpinning}
                    reload={reload}
                    title={"Plaid item: Primary Card of Use"}/>
                : null
        }
    </>);
}
const mapStateToProps = ({ paymentOptionDetails }: AppState) =>
    ({
        paymentOptionDetails: paymentOptionDetails
    });

export default connect(mapStateToProps, {
    updatePaymentOptionInStoreAction
})(PaymentOptionPlaid);