import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { MarketplacePurchase } from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import {executeAction} from "../../../commons/baseAction";
import marketplacePurchase from "../../../services/dashboard/marketplacePurchase/index";

const {getAllMarketplacePurchaseByMarketplace} = marketplacePurchase;

export const GET_ALL_PURCHASE_MARKETPLACE_SUCCESS ="GET_ALL_PURCHASE_MARKETPLACE_SUCCESS";
export const GET_ALL_PURCHASE_MARKETPLACE_ERROR ="GET_ALL_PURCHASE_MARKETPLACE_ERROR";

export interface AllMarketplacePurchaseByMarketplaceInStoreAction {
    type: typeof GET_ALL_PURCHASE_MARKETPLACE_SUCCESS | typeof GET_ALL_PURCHASE_MARKETPLACE_ERROR,
    allMarketplacePurchases: Array<MarketplacePurchase>,
    affiliateId: string
}

export const updateAllMarketplacePurchaseByMarketplaceInStoreAction =
(allMarketplacePurchases: Array<MarketplacePurchase>,
 affiliateId: string)
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(()=> getAllMarketplacePurchaseByMarketplace(affiliateId)),
        (res: Array<MarketplacePurchase>) => {
            dispatch({
                type: GET_ALL_PURCHASE_MARKETPLACE_SUCCESS,
                allMarketplacePurchases: res
            } as AllMarketplacePurchaseByMarketplaceInStoreAction);
        },
        (_: any) => {
            dispatch({
                type: GET_ALL_PURCHASE_MARKETPLACE_ERROR,
                allMarketplacePurchases: allMarketplacePurchases
            } as AllMarketplacePurchaseByMarketplaceInStoreAction);
        }
    );
}
