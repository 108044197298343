import React from "react"
import { Col, Collapse, Row } from "antd";
import { PercentageCommission } from "../../../../../core/models/dashboard/marketplace/affiliateDetails";
import "../../../../../styles/layout/dashboard/marketplace/affiliateDetails/promotions.less"
import promotionResource from "./Resource";

const { Panel } = Collapse;

export interface AffiliatePromotionsProps {
    percentageCommissions: PercentageCommission[];
}

const AffiliatePromotionsComponent: React.FC<AffiliatePromotionsProps> = props => {
    const { percentageCommissions } = props;
    return (
        <>
            <Collapse className="promotion-item" bordered={false} defaultActiveKey={['1']} >
                <Panel className="promotion-panel" header={<span className="promotion-title-section">Promotions</span>} key="1">
                    {percentageCommissions ? percentageCommissions.map((item: PercentageCommission) => {
                        return (<>
                            <Row className="promotion-row" gutter={[48, 32]}>
                                <Col span={8}>
                                    <h5 className="promotion-field-item">{promotionResource.name}</h5>
                                    <h6 className="promotion-field-value">{item.promotionName? item.promotionName : 'Default promotion'} </h6>
                                </Col>
                                <Col span={8}>
                                    <h5 className="promotion-field-item">{promotionResource.guacPercentage}</h5>
                                    <h6 className="promotion-field-value">{`${item.guacCashBackPercentage*100}%`}  </h6>
                                </Col>
                                <Col span={8}>
                                    <h5 className="promotion-field-item">{promotionResource.userPercentage}</h5>
                                    <h6 className="promotion-field-value">{`${item.cashBackPercentage*100}%`} </h6>
                                </Col>
                            </Row>
                        </>)
                    })
                        : ("")
                    }

                </Panel>
            </Collapse>
        </>
    )
}
export default AffiliatePromotionsComponent