import { AffiliateModel } from "./affiliatesModel";

export  interface MarketplaceCategoriesModel {
    id: string;
    name:string;
    iconUrl:string;
    status:CategoryStatus;
    order:number;
    affiliates: Array<AffiliateModel>
}

export interface UpdateMarketplaceCategoryModel {
    id: string;
    name:string;
    iconUrl:string;
    status:CategoryStatus;
}

export interface AddMarketplaceCategoryModel {
    name:string;
    iconUrl:string;
}

export interface UpdateMarketplaceOrderModel {
    id: string;
    order:number;
}

export enum CategoryStatus
{
    Active,
    Paused
}
export enum OrderType
{
    Platform, 
    Categories,
    Affiliates
}