import React from "react";
import { Row, Col } from "antd";


const GuacFooter: React.FC = () => {


  return (
    <Row className="footer-login">
       <p className="footer-content">©2020 New World Saving Inc. All Rights Reserved.</p>

    </Row>
  );
};

export default GuacFooter;