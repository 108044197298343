import React, {useState} from 'react';
import '../../../../../styles/layout/index.less';
import {Button, Card, Col, Row, Spin} from "antd";
import {DownOutlined, ReloadOutlined, RightOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import moment from "moment";
import {isNil} from "../../../../common/utils/Utils";


const PlaidItemDetailsView: React.FC<any> = (props: any) => {

    const [isNotCollapsed, setCollapsed] = useState(false);

    const collapse = () => {
        setCollapsed(!isNotCollapsed);
    }

    const getHeaderTitle = () => {
        return (
            <>
                {
                    isNotCollapsed ?
                        <>
                            <DownOutlined onClick={collapse}/>
                            {props.title}
                        </> :
                        <>
                            <RightOutlined onClick={collapse}/>
                            {props.title}
                        </>}
            </>
        )
    }

    return (
        <Content>
            <Spin spinning={props.spinning}>
                <Row className="row-card">
                    <Card className={`user-card-item ${isNotCollapsed ? "ud-expanded" : "ud-collapsed"}`}
                          title={getHeaderTitle()}>
                        <Row className="refresh-row-card">
                            <h5>
                                Request Id: {props.details?.requestId || ''}
                            </h5>
                            <Button
                                onClick={() => props.reload()}
                                type="default"
                                shape="round"
                                icon={<ReloadOutlined/>}>
                                Refresh
                            </Button>
                        </Row>

                        <Row className="row-card card-flex-grow balance-row">
                            <Col span={12} style={{paddingRight: '25px'}}>
                                <Card className="user-card-item card-flex-grow" title={"Item details"}>

                                    <Row>
                                        <Col span={12}>
                                            <h5 className="user-field-item">Plaid Item Id:</h5>
                                            <h6 className="user-field-value">
                                                <p style={{overflow: 'auto'}}>{props.details?.item?.itemId || '-'}</p>
                                            </h6>
                                        </Col>
                                        <Col span={12}>
                                            <h5 className="user-field-item">Plaid Account Id:</h5>
                                            <h6 className="user-field-value">{props.details?.item?.accountId || '-'}</h6>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <h5 className="user-field-item">Institution Id:</h5>
                                            <h6 className="user-field-value">{props.details?.item?.institutionId || '-'}</h6>
                                        </Col>
                                        <Col span={12}>
                                            <h5 className="user-field-item">Webhook:</h5>
                                            <h6 className="user-field-value">{props.details?.item?.webhook || '-'}</h6>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <h5 className="user-field-item">Available Products:</h5>
                                            <h6 className="user-field-value">{props.details?.item?.availableProducts?.join(', ') || '-'}</h6>
                                        </Col>
                                        <Col span={12}>
                                            <h5 className="user-field-item">Billed Products:</h5>
                                            <h6 className="user-field-value">{props.details?.item?.billedProducts?.join(', ') || '-'}</h6>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="user-card-item " title={"Update statuses"}>
                                    <Row className="row-card">
                                        <Card className="user-card-item" title={"Transaction details"}>
                                            <Row>
                                                <Col span={12}>
                                                    <h5 className="user-field-item">Last successful update:</h5>
                                                    <h6 className="user-field-value">{
                                                        !isNil(props.details?.status?.transactions?.lastSuccessfulUpdate)
                                                            ? moment(props.details?.status?.transactions?.lastSuccessfulUpdate).format("MM-DD-YYYY")
                                                            : '-'
                                                    }</h6>
                                                </Col>
                                                <Col span={12}>
                                                    <h5 className="user-field-item">Last failed update:</h5>
                                                    <h6 className="user-field-value">{
                                                        !isNil(props.details?.status?.transactions?.lastFailedUpdate)
                                                            ? moment(props.details?.status?.transactions?.lastFailedUpdate).format("MM-DD-YYYY")
                                                            : '-'
                                                    }</h6>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                    <Row className="row-card">
                                        <Card className="user-card-item" title={"Webhook details"}>
                                            <Row>
                                                <Col span={12}>
                                                    <h5 className="user-field-item">Sent at:</h5>
                                                    <h6 className="user-field-value">{
                                                        !isNil(props.details?.status?.lastWebhook?.sentAt)
                                                            ? moment(props.details?.status?.lastWebhook?.sentAt).format("MM-dd-YYYY")
                                                            : '-'
                                                    }</h6>
                                                </Col>
                                                <Col span={12}>
                                                    <h5 className="user-field-item">Sent code:</h5>
                                                    <h6 className="user-field-value">{props.details?.status?.lastWebhook?.codeSent || '-'}</h6>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        {!isNil(props?.details?.item?.error) ?
                                <Row className="row-card">
                                    <Card className="user-card-item" title={"Error details"}>
                                    <Row>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Error Type:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.errorType || '-'}</h6>
                                        </Col>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Error Code:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.errorCode || '-'}</h6>
                                        </Col>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Message:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.errorMessage || '-'}</h6>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Display message:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.displayMessage || '-'}</h6>
                                        </Col>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Request Id:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.requestId || '-'}</h6>
                                        </Col>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Causes: </h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.causes?.join(', ') || '-'}</h6>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Status</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.status || '-'}</h6>
                                        </Col>
                                        <Col span={8}>
                                            <h5 className="user-field-item">Documentation Url:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.documentationUrl || '-'}</h6>
                                        </Col>

                                        <Col span={8}>
                                            <h5 className="user-field-item">Suggested action:</h5>
                                            <h6 className="user-field-value">{props?.details?.item?.error?.suggestedAction || '-'}</h6>
                                        </Col>
                                    </Row>
                                </Card>
                                </Row> : null}
                    </Card>
                </Row>
            </Spin>
        </Content>
    );
}

export default PlaidItemDetailsView;