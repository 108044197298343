import {Empty, Table, Input, Badge, Row, Spin} from 'antd';
import React, { useEffect, useState } from 'react';
import { AppState } from "../../../core/commons/reduxStore";
import { connect } from "react-redux";
import moment from 'moment';
import { updateMarketplacePurchaseByUserInStoreAction } from "../../../core/actions/dashboard/marketplacePurchase/updateMarketplacePurchaseByUserInStore";
import { updateAffiliatesInStoreAction } from "../../../core/actions/dashboard/marketplace/affiliates/updateAffiliatesInStore";
import { CashBackStatus, MarketplacePurchase, MarketplacePurchaseModel, MarketplacePurchaseSearch, PurchaseStatus } from '../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel';
import '../../../styles/layout/dashboard/marketplacePurchase/userMarketplacePurchaseTable.less';
import CashbackStatusComponent from '../../common/components/dashboard/CashbackStatusComponent';
import AffiliatePartnerComponent from '../../common/components/dashboard/AffiliatePartnerComponent';
import { SearchOutlined } from '@ant-design/icons';
import CashbackDetailsMarketplacePurchaseComponent from './CashbackDetailsMarketplacePurchaseComponent';
import { AffiliateModel } from '../../../core/models/dashboard/marketplace/affiliatesModel';
import { s3Url } from '../../common/utils/Utils';

const { Search } = Input;

export interface MarketplacePurchaseUserComponentProps {
  userId: string,
  userMarketplacePurchase: MarketplacePurchaseModel,
  affiliates: Array<AffiliateModel>
  updateMarketplacePurchaseByUserInStoreAction: (
      userMarketplacePurchase: MarketplacePurchaseModel | null,
      searchParams: MarketplacePurchaseSearch, onSuccess: Function, onFailure: Function) => void;
  updateAffiliatesInStoreAction: (affiliates: Array<AffiliateModel> | [], onSuccess: Function, onFailure: Function) => void;
}

const initSearchParams: MarketplacePurchaseSearch = {
  pageNumber: 1,
  pageSize: 10,
  searchValue: "",
  orderDescending: true,
  userId: "",
  sortColumn: "createdAt",
  affiliateId: null
};


const UserMarketplacePurchaseTableComponent: React.FC<MarketplacePurchaseUserComponentProps> = props => {
  const maxSearchLength = 50;
  const [keyExpanded, setKeyExpanded] = useState<Array<string>>([]);

  const [isLoading, setLoading] = useState(false);
  const [isMounting, setMounting] = useState(true);

  const onMarketplacePurchaseLoadSuccess = () =>{
      setLoading(false);
  }

  const onMarketplacePurchaseLoadFailure = () =>{
    setLoading(false);
  }

  const getAffiliates = () => {
    props.updateAffiliatesInStoreAction([], onMarketplacePurchaseLoadSuccess, onMarketplacePurchaseLoadFailure);
  }



  const getMarketplacePurchase = () => {
    initSearchParams.userId = props.userId;
    props.updateMarketplacePurchaseByUserInStoreAction(null, initSearchParams, onMarketplacePurchaseLoadSuccess, onMarketplacePurchaseLoadFailure);
  }

  const init = () => {
    getAffiliates();
    getMarketplacePurchase();
    setMounting(false);
    setLoading(true);
  }

  const onSearchSubmit = (searchValue: string) => {
    initSearchParams.searchValue = searchValue;
    initSearchParams.pageNumber = 1;
    props.updateMarketplacePurchaseByUserInStoreAction(null, initSearchParams, onMarketplacePurchaseLoadSuccess, onMarketplacePurchaseLoadFailure);
    setLoading(true);
  }



  const getAffiliateFilters = () =>
      props?.affiliates?.map(element => ({
            text: <>            
              <img className="icon" src={`${element.iconUrl}`} alt="icon"/>
              {element.name.length > 17 ? <>{element.name.substring(0, 17)}...</> : element.name}              
            </>,
            value: element.id
          })
        );

  const columns = [
    {
      title: 'Affiliate', dataIndex: 'marketplace.Name',
      key: 'affiliateId',
      width: 220,
      fixed: true,
      filterMultiple: false,
      sorter: true,
      filters: getAffiliateFilters(),
      render: (id: string, row: any) => (
        <>
          {row?.marketplace && row?.marketplace != null ?
            (
              <span className="affiliate">
                <img className="icon" src={`${s3Url}${row?.marketplace?.iconUrl}`}></img>
                <span className="name">
                  {row?.marketplace?.name}
                </span>
              </span>
            ) : ("")
          }
        </>
      )
    },
    {
      title: 'Partner', dataIndex: 'type', sorter: true, width: 170, key: 2, render: (type: number) => (
        <>
          <AffiliatePartnerComponent {...{ status: type,iconUrl:null }}></AffiliatePartnerComponent>
        </>
      )
    },
    {
      title: 'Purchase date', dataIndex: 'purchaseDate', width: 220,
      sorter: true,
      key: 3, render: (purchaseDate: Date) => (
        <> { purchaseDate ? `${moment(new Date(purchaseDate)).format("MM-DD-YYYY")}` : ("")} </>
      )
    },
    {
      title: 'Date created', dataIndex: 'createdAt', width: 170, key: 4, sorter: true, render: (createdAt: Date) => (
        <> { createdAt ? `${moment(new Date(createdAt)).format("MM-DD-YYYY")}` : ("")} </>
      )
    },
    {
      title: 'Last updated', dataIndex: 'lastModifiedAt', width: 170, key: 5, sorter: true, render: (lastModifiedAt: Date) => (
        <> { lastModifiedAt ? `${moment(new Date(lastModifiedAt)).format("MM-DD-YYYY")}` : ("")} </>
      )
    },
    {
      title: 'Purchase status', dataIndex: 'status', width: 220, key: 6, sorter: true, render: (status: PurchaseStatus) => (
        <>
          {status == PurchaseStatus.Pending &&
            <Badge color={"#FA8C16"} text={"Pending"} />
          }
          {status == PurchaseStatus.Completed &&
            <Badge color={"#52C41A"} text={"Completed"} />
          }
          {status == PurchaseStatus.Reversed &&
            <Badge color={"#F5222D"} text={"Reversed"} />
          }
        </>
      )
    },
    {
      title: 'Cashback status', dataIndex: 'cashBackStatus', width: 220, key: 7, render: (cashBackStatus: number, row: any) => (
        <>
          <CashbackStatusComponent {...{ status: cashBackStatus }}></CashbackStatusComponent>
        </>
      )
    },
    {
      title: 'User cashback percentage',
      dataIndex: 'cashBackPercentage',
      width: 220,
      key: 8,
      render: (cashBackPercentage: number, row: any) => (
        <>
          {row?.marketplace && row?.marketplace != null ?
            (`${row?.marketplace?.cashBackPercentage * 100}%`) : ("--")
          }
        </>
      )
    },
    {
      title: 'Cashback amount', dataIndex: 'cashBack', key: 9,
      width: 220,
      render: (cashBack: number) => (
        <> { cashBack > 0 ? `$${cashBack}` : ("--")} </>
      )
    },
    {
      title: 'Purchase amount', dataIndex: 'amount', key: 10, sorter: true,
      width: 220,
      render: (amount: number) => (
        <> { amount > 0 ? `$${amount}` : ("")} </>
      )
    }
  ];

  const onTableRowExpand = (expanded: boolean, record: any) => {
    let keys: Array<string> = [];
    if (expanded) {
      keys.push(record.id);
    }
    setKeyExpanded(keys);
  }

  const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
    if(filters) {
      if(filters.affiliateId !=undefined || filters.affiliateId!=null) {
        initSearchParams.affiliateId = filters.affiliateId[0];

      } else {
        initSearchParams.affiliateId = null;
      }
    }
    if (sorter) {
      if (sorter.order || sorter.undefined) {
        initSearchParams.sortColumn = sorter.field;
        initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;

      } else {
        initSearchParams.sortColumn = 'createdAt';
        initSearchParams.orderDescending = true;
      }
    }

    if (pagination) {
      if (props.userMarketplacePurchase?.pageNumber != pagination.current) {
        initSearchParams.pageNumber = pagination.current;
      }
    };

    props.updateMarketplacePurchaseByUserInStoreAction(null, initSearchParams, onMarketplacePurchaseLoadSuccess, onMarketplacePurchaseLoadFailure);
    setLoading(true);
  }

  if (isMounting) {
    init();
  }

  return <>
    <Spin spinning={isLoading}>
      <Row className="searchbox">
        <Search size="large"
                name="searchValue"
                className="search-input-goals"
                maxLength={maxSearchLength}
                placeholder="Search something..."
                onSearch={onSearchSubmit} />
      </Row>

      <Table
          columns={columns}
          rowKey="id"
          onExpand={onTableRowExpand}
          expandedRowKeys={keyExpanded}
          expandable={{
            expandedRowRender: (record: MarketplacePurchase) => <>

              <CashbackDetailsMarketplacePurchaseComponent {...{ marketplacePurchase: record}}>
              </CashbackDetailsMarketplacePurchaseComponent>

            </>,
            rowExpandable: (record: MarketplacePurchase) => (record.cashBackStatus != CashBackStatus.Processing),
          }}

          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}

          pagination={{
            total: props.userMarketplacePurchase?.totalItemCount, current: props.userMarketplacePurchase?.pageNumber,
            pageSize: props.userMarketplacePurchase?.pageSize,
            showSizeChanger: false,
          }}

          scroll={{ x: 1200 }}
          onChange={onChangeEvent}
          dataSource={props.userMarketplacePurchase?.items}
      />
    </Spin>
  </>
}

const mapStateToProps = ({ userMarketplacePurchase, affiliates }: AppState) =>
({
  userMarketplacePurchase: userMarketplacePurchase,
  affiliates: affiliates
});

export default connect(mapStateToProps, {
  updateMarketplacePurchaseByUserInStoreAction,
  updateAffiliatesInStoreAction
})
  (UserMarketplacePurchaseTableComponent);