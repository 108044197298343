
import React, { useEffect, useState } from "react";
import { Column } from '@ant-design/charts';
import { connect, useSelector } from "react-redux";
import { AnnualRapidWithdrawModel, WithdrawStatisticsModel } from "../../../../core/models/dashboard/withdraw/withdraw";
import { getMoneyType } from "../../../common/utils/Utils";
import { AppState } from "../../../../core/commons/reduxStore";
import { udpateAnnualRapidWithdrawInStoreAction } from "../../../../core/actions/dashboard/moveMoney/updateAnnualRapidWithdrawInStoreAction";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export interface WithdrawAnnualStatisticsComponentProps {
    udpateAnnualRapidWithdrawInStoreAction: (annualRapidWithdraw: Array<AnnualRapidWithdrawModel> | [], onSuccess: Function, onFailure: Function) => void,

    annualRapidWithdraw: Array<AnnualRapidWithdrawModel> | [];
}

const WithdrawAnnualStatisticsChartComponent: React.FC<WithdrawAnnualStatisticsComponentProps> = (props: WithdrawAnnualStatisticsComponentProps) => {

    const defaultActiveKey = "1";
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(false);
   
    const onGetAnnualStatisticsSuccess = () => {
    }
    const onGetAnnualStatisticsError = () => {
    }


    const config = {
        data: props.annualRapidWithdraw,
        xField: 'monthName',
        yField: 'withdrawFees',
        seriesField: '',
        label: {
            content: function content(originData: any) {
                var val = parseFloat(originData.withdrawFees);
                return getMoneyType(val);

            },
            offset: 10,
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    if (isMounting) {
        props.udpateAnnualRapidWithdrawInStoreAction([], onGetAnnualStatisticsSuccess, onGetAnnualStatisticsError);
        setMounting(false);
    }
    return (
        <>
            <hr />
            <h6 style={{ justifyContent: 'start', alignItems: 'center', display: 'flex', paddingTop: '30px', paddingBottom: '30px', paddingLeft: '5px' }}>Annual Rapid fees</h6>

            <Column
                style={{ width: '100%' }}
                {...config}
                onReady={(plot: any) => {
                    plot.on('plot:click', (evt: any) => {
                        const { x, y } = evt;
                        const { xField } = plot.options;
                        const tooltipData = plot.chart.getTooltipItems({ x, y });
                    });
                }}
            />
        </>
    )

}

const mapStateToProps = ({ annualRapidWithdraw }: AppState) =>
({
    annualRapidWithdraw: annualRapidWithdraw,
});

export default connect(mapStateToProps, {
    udpateAnnualRapidWithdrawInStoreAction

})(WithdrawAnnualStatisticsChartComponent);