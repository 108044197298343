import { adminHttpClient } from "../../../commons/adminHttpClinet";
import {
    AdminNotificationExecutionLogModel,
    AdminNotificationSendJobModel,
    BaseResponse, GetNotificationJobModel, GetNotificationLogsModel, NotificationPushModel,
    NotificationTemplateData,
    NotificationTemplateModel, UserSuggestionModel
} from "../../../models/dashboard/notification/notification";


export const getAdminNotificationTemplates = (
    pageNumber: number | undefined,
    pageSize: number | undefined): Promise<NotificationTemplateModel> => {
    return adminHttpClient.get(`/api/Admin/AdminNotifications/templates?PageNumber=${pageNumber}&PageSize=${pageSize}`);
}

export const addAdminNotificationTemplate = (model: NotificationTemplateData | null): Promise<BaseResponse> => {
    return adminHttpClient.post(`/api/Admin/AdminNotifications/templates`, model);
}

export const updateAdminNotificationTemplate = (model: NotificationTemplateData | null): Promise<BaseResponse> => {
    return adminHttpClient.put(`/api/Admin/AdminNotifications/templates`, model);
}

export const deleteAdminNotificationTemplate = (id: string | null): Promise<BaseResponse> => {
    return adminHttpClient.delete(`/api/Admin/AdminNotifications/templates?Id=${id}`, null);
}

export const getUserSuggestions = (keyword: string | null) : Promise<Array<UserSuggestionModel>> => {

    let requestKeyword = keyword
    if(!requestKeyword)
        requestKeyword = 'a';
    const encodedURI =  `/api/Admin/AdminUsers/search-users?name=${encodeURIComponent(requestKeyword)}`;
    
    return adminHttpClient.get(encodedURI);
}

export const sendNotificationToAll = (model: NotificationPushModel | null) : Promise<BaseResponse> => {
    return adminHttpClient.post(`/api/Admin/AdminNotifications/send-all`, model);
}

export const sendNotificationToSpecific = (model: NotificationPushModel | null) : Promise<BaseResponse> => {
    return adminHttpClient.post(`/api/Admin/AdminNotifications/send-specific`, model);
}

export const getAdminNotificationJobs = (model: GetNotificationJobModel): Promise<AdminNotificationSendJobModel> => {
    return adminHttpClient.get(`/api/Admin/AdminNotifications/jobs?PageNumber=${model.pageNumber}&PageSize=${model.pageSize}`);
}

export const getAdminNotificationSubJobs = (model: GetNotificationJobModel): Promise<AdminNotificationSendJobModel> => {
    return adminHttpClient.get(`/api/Admin/AdminNotifications/sub-jobs?PageNumber=${model.pageNumber}&PageSize=${model.pageSize}&ParentId=${model.parentId}`);
}

export const getAdminNotificationExecutionLogs = (model: GetNotificationLogsModel): Promise<AdminNotificationExecutionLogModel> => {
    return adminHttpClient.get(`/api/Admin/AdminNotifications/logs?PageNumber=${model.pageNumber}&PageSize=${model.pageSize}&JobId=${model.jobId}&IsSub=${model.isSub}`);
}

export const getAdminNotificationExecutionLogsSimple = (model: GetNotificationLogsModel): Promise<AdminNotificationExecutionLogModel> => {
    return adminHttpClient.get(`/api/Admin/AdminNotifications/logs-simple?PageNumber=${model.pageNumber}&PageSize=${model.pageSize}`);
}
