import { ArrowRightOutlined, BankOutlined, GiftOutlined, NotificationOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Badge, Divider, Row, Tag } from "antd";
import React from "react";
import { TransferType } from "../../../../core/models/dashboard/transactions/transation";
import { s3DestinationMoveMoneyImgUrl, s3FromMoveMoneyImgUrl } from "../../utils/Utils";
import { ReactComponent as Withdrawal } from "../../../../assets/svg/money-withdrawal.svg";
import { ReactComponent as Deposit } from "../../../../assets/svg/deposit.svg";
import {ReactComponent as DirectDeposit} from "../../../../assets/svg/direct-deposit.svg";
import { ReactComponent as MarketplacePurchase } from "../../../../assets/svg/marketplace-purchase.svg";
import "../../../../styles/components/transactionTypeDetails.less";

export interface TransactionTypeDetailsComponentProps {
    transferType: TransferType
}

const TransactionTypeDetailsComponent: React.FC<TransactionTypeDetailsComponentProps> = props => {
    return <>
        {props.transferType == TransferType.Cashback &&
            (
                <>
                    <span className="transfer-type-header">
                        <span className="transfer-type">
                            <Tag className="cashback round" key={3}>
                                <GiftOutlined />
                            </Tag>
                            <span className="title-head">Cashback details</span>
                        </span>
                    </span>
                    <Divider className="transfer-type-devider"/>
                </>
            )
        }
        {props.transferType == TransferType.Withdraw &&
            (
                <>
                    <span className="transfer-type-header">
                        <span className="transfer-type">
                            <Tag className="withdrawal round" key={3}>
                                <Withdrawal />
                            </Tag>
                            <span className="title-head">Withdrawal details</span>
                        </span>
                    </span>
                    <Divider />
                </>
            )
        }
        {props.transferType == TransferType.Deposit &&
            (
                <>
                    <span className="transfer-type-header">
                        <span className="transfer-type">
                            <Tag className="deposit round" key={3}>
                                <Deposit />
                            </Tag>
                            <span className="title-head">Deposit details</span>
                        </span>
                    </span>
                    <Divider />
                </>
            )
        }
        {props.transferType == TransferType.ReferralReward &&
            (
                <>
                    <span className="transfer-type-header">
                        <span className="transfer-type">
                            <Tag className="referrarl round" key={3}>
                                <NotificationOutlined />
                            </Tag>
                            <span className="title-head">Referral details</span>
                        </span>
                    </span>
                    <Divider />
                </>
            )
        }
        {props.transferType == TransferType.MarketplacePurchase &&
            (
                <>
                    <span className="transfer-type-header">
                        <span className="transfer-type">
                            <Tag className="marketplace-purchase round" key={3}>
                                <NotificationOutlined />
                            </Tag>
                            <span className="title-head">Marketplace purchase details</span>
                        </span>
                    </span>
                    <Divider />
                </>
            )
        }

        {props.transferType == TransferType.DirectDeposit &&
        (
            <>
                    <span className="transfer-type-header">
                        <span className="transfer-type">
                            <Tag className="direct-deposit" key={3}>
                                <ThunderboltOutlined />
                            </Tag>
                            <span className="title-head">Direct deposit details</span>
                        </span>
                    </span>
                <Divider />
            </>
        )
        }
    </>
}
export default TransactionTypeDetailsComponent;