import React, {FC, useEffect, useState} from 'react';

import './App.less';
import {Skeleton} from 'antd';

import {
    Route,
    Switch,
    BrowserRouter
} from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import Login from './account/login/Login';
import ForgotPassword from './account/forgotPassword/ForgotPassword'
import getUserProfile, {User} from '../core/services/accounts/getUserProfile';
import {AppState} from '../core/commons/reduxStore';
import {updateUserInStore} from '../core/actions/user/updateUserInStore';
import {connect} from 'react-redux';
import NotFound from './errorPages/NotFound';
import redirectRouter from './redirectRouter';
import {RoutePaths} from './routes';
import ResetPassword from './account/resetPassword/ResetPassword';
import {commandRequest, queryRequest} from "./common/requestUtils/requestUtils";


interface AppProps {
    updateUserInStore: (user: User | null) => void;
    user: User;
}

const App: FC =  () => {

    return (
        <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route exact path={RoutePaths.REDIRECT_PATH} component={redirectRouter}/>
                        <Route path={RoutePaths.LOGIN_PATH} component={Login}/>;
                        <Route path={RoutePaths.DASHBOARD_PATH} component={Dashboard}/>;
                        <Route path={RoutePaths.FORGOT_PASSWORD_PATH} component={ForgotPassword}/>
                        <Route path={RoutePaths.RESET_PASSWORD_LOGIN_PATH} component={ResetPassword}/>
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </BrowserRouter>
        </div>
    );
};

export default App;