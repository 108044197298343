import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { MarketplacePurchaseModel, MarketplacePurchaseSearch } from "../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import getMarketplacePurchase from "../../../services/dashboard/marketplacePurchase/getMarketplacePurchasesByUser";
import {executeAction} from "../../../commons/baseAction";


export const GET_PURCHASE_MARKETPLACE_SUCCESS ="GET_PURCHASE_MARKETPLACE_SUCCESS";
export const GET_PURCHASE_MARKETPLACE_ERROR ="GET_PURCHASE_MARKETPLACE_ERROR";

export interface MarketplacePurchaseByMarketplaceInStoreAction {
    type: typeof GET_PURCHASE_MARKETPLACE_SUCCESS | typeof GET_PURCHASE_MARKETPLACE_ERROR,
    marketplacePurchases: MarketplacePurchaseModel | null,
    searchParams: MarketplacePurchaseSearch
}

export const updateMarketplacePurchaseByMarketplaceInStoreAction =
(marketplacePurchases: MarketplacePurchaseModel | null,
 searchParams: MarketplacePurchaseSearch,
 onSuccess: Function,
 onFailure: Function)
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(()=> getMarketplacePurchase(searchParams)),
        (res: any) => {
            dispatch({
                type: GET_PURCHASE_MARKETPLACE_SUCCESS,
                marketplacePurchases: res
            } as MarketplacePurchaseByMarketplaceInStoreAction);
            onSuccess();
        },
        (_: any) => {
            dispatch({
                type: GET_PURCHASE_MARKETPLACE_ERROR,
                marketplacePurchases: marketplacePurchases
            } as MarketplacePurchaseByMarketplaceInStoreAction);
            onFailure();
        }
    );
}