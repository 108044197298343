import React, {useState} from 'react';
import {Button, Card, Empty, message, Row, Spin, Table} from "antd";
import {
    BackwardOutlined,
    DownOutlined,
    ReloadOutlined,
    RightOutlined, SearchOutlined
} from "@ant-design/icons";
import {AppState} from "../../../../core/commons/reduxStore";
import {connect} from "react-redux";
import {updateNotificationLogsInStore} from "../../../../core/actions/dashboard/notification/updateNotificationLogsInStore";
import moment from "moment";
import {updateNotificationLogsInStoreSimple} from "../../../../core/actions/dashboard/notification/updateNotificationLogsInStoreSimple";
import Search from "antd/es/input/Search";
import { NotificationTemplateView } from '../notificationTemplate/NotificationTemplateSendComponent';
const maxSearchLength = 50;


const NotificationExecutionLogListComponent: React.FC<any> = (props: any) => {
    const [isLoading, setLoading] = useState(false);
    const [isNotCollapsed, setCollapsed] = useState(true);
    const [isMounting, setMounting] = useState(true);

    const onGetLogsSuccess = () => {
        setLoading(false);
    }

    const onGetLogsFailure = (err: any) => {
        message.error(err.message)
        setLoading(false);
    }

    const loadLogs = (pageNumber: number) => {
        if(pageNumber === 0) pageNumber ++;
        if(props.isLog){
            props.updateNotificationLogsInStore({
                pageNumber: pageNumber,
                pageSize: 7,
                isSub: props.isSubJob,
                jobId: props.jobId
            }, null, onGetLogsSuccess, onGetLogsFailure);
        }
        else{
            props.updateNotificationLogsInStoreSimple(
                pageNumber,
                7
          , onGetLogsSuccess, onGetLogsFailure);
        }
    }

    const refresh = () => {
        setLoading(true);
        loadLogs(props.notificationLogs?.pageNumber);
    }

    if (isMounting) {
        setLoading(true);
        setMounting(false);
        loadLogs(1);
    }

    const onChangeEvent = (pagination: any, _: any, _1: any, _2: any) => {
        if (pagination) {
            if (props.notificationLogs?.pageNumber !== pagination.current) {
                loadLogs(pagination.current);
                setLoading(true);
            }
        }
    }

    const collapse = () => {
        setCollapsed(!isNotCollapsed);
    }

    const data = props.isLog ? props.notificationLogs : props.simpleNotificationLogs;

    const getHeaderTitle = () => {
        return (
            <>
                {
                    isNotCollapsed ?
                        <>
                            <DownOutlined onClick={collapse}/>
                            {` ${props.title}`}
                        </> :
                        <>
                            <RightOutlined onClick={collapse}/>
                            {` ${props.title}`}
                        </>}
            </>
        )
    }

    let columns = [
        {
            title: 'Template Title',
            dataIndex: 'templateTitle',
            width: 100,
            key: 2,
            fixed: true,
            sorter: false,
            render: (title: string) => (
                <>
                    <span>{title.substr(0, 100)}
                        {
                            title.length > 100 ? '...' : ''
                        }
                    </span>
                </>
            )
        },
        {
            title: 'Recipient Full Name',
            dataIndex: 'recipientFullName',
            width: 100,
            key: 3,
            fixed: true,
            sorter: false,
            render: (name: string) => (
                <>
                    <span>{name.substr(0, 100)}
                        {
                            name.length > 100 ? '...' : ''
                        }
                    </span>
                </>
            )
        },
        {
            title: 'Recipient Email',
            dataIndex: 'recipientEmail',
            width: 100,
            key: 4,
            fixed: true,
            sorter: false,
            render: (email: string) => (
                <>
                    <span>{email.substr(0, 100)}
                        {
                            email.length > 100 ? '...' : ''
                        }
                    </span>
                </>
            )
        },
        {
            title: 'Sent on',
            dataIndex: 'createdAt',
            width: 100,
            key: 5,
            fixed: false,
            sorter: false,
            render: (createdOn: Date | null) => (
                <>
                    <span>{(moment(createdOn)).format('DD-MMM-YYYY HH:mm:ss')}</span>
                </>
            )
        },
        {
            title: 'Push Sent',
            dataIndex: 'pushSent',
            width: 100,
            key: 6,
            fixed: false,
            sorter: false,
            render: (push: boolean) => {
                const text = push ? 'Yes' : 'No';
                const color = push ? '#3AB100' : '#F5222D';
                return (
                    <span
                        style={{
                            color: color
                        }}
                    >
                        {text}
                    </span>
                );
            }
        }
    ];

    if(props.isLog){
        columns =[
            {
                title: 'Template',
                dataIndex: 'templateTag',
                width: 100,
                key: 1,
                fixed: true,
                sorter: false,
                render: (tag: string) => (
                    <>
                        <span>{tag}</span>
                    </>
                )
            },
            ...columns
        ];
    }

    return (
        <Card
            className={`notification-log-view ${isNotCollapsed ? "is-flex-expanded nt-expanded" : "nt-collapsed"}`}
            title={getHeaderTitle()}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <div className="nt-tab-row-card-content">
                    <Row className={`notification-item`}>

                        {
                            props.isLog ?
                                <Button
                                    onClick={() => {
                                        props.close();
                                    }}
                                    type="default"
                                    shape="round"
                                    icon={<BackwardOutlined/>}>
                                    Back
                                </Button>:
                                null
                        }

                        <div className="search-wrapper">
                            <Search size="large"
                                    name="searchValue"
                                    className="search-input"
                                    enterButton="Search"
                                    maxLength={maxSearchLength}
                                    placeholder="Enter name or email address"
                                    onSearch={()=>{}}
                                    prefix={<SearchOutlined />}
                            />
                        </div>

                        <Button
                            onClick={() => refresh()}
                            type="default"
                            shape="round"
                            icon={<ReloadOutlined/>}>
                            Refresh
                        </Button>
                    </Row>

                    <div className="notification-item">
                        <Table
                            locale={{
                                emptyText: <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No results for ths search. Try again..."/>
                            }}
                            pagination={{
                                total: data?.totalItemCount,
                                current: data?.pageNumber,
                                pageSize: data?.pageSize,
                                showSizeChanger: false,
                            }}
                            expandable={{
                                expandedRowRender: (record: any) => (
                                    <>
                                        <Row className="notification-item notification-item-center">

                                            <Row className="notification-item" style={{
                                                maxWidth: '60%'
                                            }}>
                                                <NotificationTemplateView
                                                    email={record.recipientEmail}
                                                    name={record.recipientFullName}
                                                    title={record.templateTitle}
                                                    description={record.templateDescription}
                                                />
                                            </Row>
                                        </Row>
                                    </>
                                )
                            }}
                            className=""
                            sortDirections={['ascend', 'descend', 'ascend']}
                            scroll={{x: 800, y: 600}}
                            onChange={(onChangeEvent)}
                            columns={columns}
                            dataSource={data?.items?.map((e: any, key: number) => ({
                                    key: key,
                                    ...e
                                }))}/>
                    </div>
                </div>
            </Spin>
        </Card>);
}

const mapStateToProps = ({notificationLogs, simpleNotificationLogs}: AppState) =>
    ({
        notificationLogs: notificationLogs,
        simpleNotificationLogs: simpleNotificationLogs
    });

export default connect(mapStateToProps, {
    updateNotificationLogsInStore,
    updateNotificationLogsInStoreSimple
})(NotificationExecutionLogListComponent);