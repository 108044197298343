import React from "react";
import CashbackStatisticsCard from "./CashbackStatisticsCard";
import MarketplaceCoreDataWrapper from "./MarketplaceCoreDataWrapper";

const MarketplaceStatisticsWrapper : React.FC<any> = (props: any) => {
    return(
        <>
            <CashbackStatisticsCard/>
            {
                !props?.isHidden ? <MarketplaceCoreDataWrapper/> :null
            }
    </>);
}

export default MarketplaceStatisticsWrapper;