import { Card, Col, Row, Spin, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { AnnualRapidWithdrawModel, GetListOfWithdrawals, PaginatedWithdrawals, WithdrawStatisticsModel } from "../../../core/models/dashboard/withdraw/withdraw";
import BreadcrumbComponent, { BreadcrumbProps } from "../../common/components/dataDisplay/BreadcrumbComponent";
import WithdrawStatisticsComponent from "./statistics/WithdrawStatisticsComponent";
import WithdrawListComponent from "./withdrawlist/WithdrawListComponent"
import { Column } from '@ant-design/charts';
import { getMoneyType } from "../../common/utils/Utils";
import { AppState } from "../../../core/commons/reduxStore";
import { connect, useSelector } from "react-redux";
import { udpateAnnualRapidWithdrawInStoreAction } from "../../../core/actions/dashboard/moveMoney/updateAnnualRapidWithdrawInStoreAction";
import { updateWithdrawalStatisticsInStoreAction } from "../../../core/actions/dashboard/moveMoney/updateWithdrawalStatisticsInStore";
import WithdrawAnnualStatisticsChartComponent from "./charter/WithdrawAnnualStatisticsChartComponent";
import { udpateWithdrawListInStoreAction } from "../../../core/actions/dashboard/moveMoney/updateWithdrawListInStoreAction";

const { TabPane } = Tabs;

export interface WithdrawComponentProps {
    updateWithdrawalStatisticsInStoreAction: (onSuccess: Function, onFailure: Function) => void,
    udpateWithdrawListInStoreAction(searchWithdraw: GetListOfWithdrawals): void;
    withdrawalStatisticsData: WithdrawStatisticsModel | null,
    withdrawList: PaginatedWithdrawals | null,
}
var initialValue: GetListOfWithdrawals = {
    orderDescending: true,
    pageNumber: 1,
    pageSize: 10,
    searchValue: '',
    sortColumn: 'CreatedAt'
}
const WithdrawComponent: React.FC<WithdrawComponentProps> = (props: WithdrawComponentProps) => {
    const defaultActiveKey = "1";
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const { withdrawalStatisticsData } = props;
    const withdrawStatisticsLastMonth = (withdrawalStatisticsData) ? withdrawalStatisticsData?.withdrawStatisticsLastMonth : null;
    const withdrawStatisticsTotal = (withdrawalStatisticsData) ? withdrawalStatisticsData?.withdrawStatisticsTotal : null;


    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Withdraw",
                path: ""
            }
        ]
    };

    const onGetStatisticsSuccess = () => {
    }

    const onGetStatisticsError = () => {
    }

    const getWithdrawList = () => {
        props.udpateWithdrawListInStoreAction(initialValue);
    }

    if (isMounting) {
        props.updateWithdrawalStatisticsInStoreAction(onGetStatisticsSuccess, onGetStatisticsError);
        getWithdrawList();
        setMounting(false)
    }
    return (
        <Content>
            <BreadcrumbComponent key="withdraw_component" {...currentBreadCrumbs} />
            <Spin spinning={isLoading}>
                <Row className="home-content">

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                <Tabs defaultActiveKey={defaultActiveKey}>
                                    <TabPane tab={'All time'} key="0" id="alltime-id">
                                        {withdrawStatisticsTotal ?
                                            <WithdrawStatisticsComponent {...{
                                                rapidWithdrawStatisticsModel: withdrawStatisticsTotal?.rapidWithdrawStatisticsModel,
                                                standardWithdrawStatisticsModel: withdrawStatisticsTotal?.standardWithdrawStatisticsModel
                                            }} ></WithdrawStatisticsComponent> : ("")}
                                        <WithdrawAnnualStatisticsChartComponent></WithdrawAnnualStatisticsChartComponent>
                                    </TabPane>
                                    <TabPane tab={'Last Month'} key="1" id="alltime-id">
                                        {withdrawStatisticsLastMonth ? <WithdrawStatisticsComponent {...
                                            {
                                                rapidWithdrawStatisticsModel: withdrawStatisticsLastMonth?.rapidWithdrawStatisticsModel,
                                                standardWithdrawStatisticsModel: withdrawStatisticsLastMonth?.standardWithdrawStatisticsModel
                                            }} ></WithdrawStatisticsComponent> : ("")}
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                <WithdrawListComponent {...{ withdrawals: null }}></WithdrawListComponent>

                            </Card>
                        </Col>
                    </Row>

                </Row>
            </Spin>
        </Content>
    )
}
const mapStateToProps = ({ withdrawalStatisticsData, withdrawList }: AppState) =>
({
    withdrawalStatisticsData: withdrawalStatisticsData,
    withdrawList: withdrawList
});

export default connect(mapStateToProps, {
    updateWithdrawalStatisticsInStoreAction,
    udpateWithdrawListInStoreAction
})(WithdrawComponent);