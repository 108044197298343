import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, Popover, Row, Switch, Table, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { AffiliateModel, AffiliateStatus, OrderIndex } from "../../../../core/models/dashboard/marketplace/affiliatesModel";
import { CategoryStatus, MarketplaceCategoriesModel, OrderType, UpdateMarketplaceCategoryModel, UpdateMarketplaceOrderModel } from "../../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import "../../../../styles/layout/dashboard/marketplace/editCategories.less";
import { commandRequest } from "../../../common/requestUtils/requestUtils";
import services from "../../../../core/services";
import { UploadFile } from "antd/lib/upload/interface";
import { FileResponse } from "../../../../core/models/files/fileModel";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Affiliates from "../Affiliates";
import AlertComponent from "../../../common/components/dataDisplay/Alert";
import { acceptFileUpload } from "../../../common/utils/Utils";

const { marketplace, uploadIcon } = services;

export interface EditCategoriesProps {
    isVisible: boolean;
    category: MarketplaceCategoriesModel | null,
    changeVisibility: (isVisible: boolean) => void;
}

interface EditAffiliateValues {
    name: string;
    iconUrl: string;
    status: number,
}

let initialValues: EditAffiliateValues = {
    name: "",
    iconUrl: "",
    status: 0,
}

const formValidator = () =>
    Yup.object({
        name: Yup.string().max(50, 'You have reached your maximum limit of characters allowed').required("Affilite name is required"),
    });

const ASortableItem = SortableElement((props: any) => <tr className="row-dragging" {...props} />);
const ASortableContainer = SortableContainer((props: any) => <tbody {...props} />);

const EditCategoriesComponent: React.FC<EditCategoriesProps> = props => {

    const [imageUrl, setImageUrl] = useState<any>(null);
    const [uploadedFile, setUploadedFile] = useState<FormData>();
    const [status, setStatus] = useState<boolean>();
    const [editFileUploaded, setEditFileUploaded] = useState<Array<UploadFile>>([]);
    const [affiliateCategory, setAffiliateCategory] = useState<Array<AffiliateModel>>([]);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [acceptedFiles, setAcceptedFiles] = useState<Array<string>>([]);

    const maxInputLength = 30;
    const maxStringLength = 30;

    const onEditStatusChanged = (checked: boolean) => {
        setStatus(checked)
    }

    const onClose = () => {
        setErrorMessage(null);
        props.changeVisibility(props.isVisible);
    };

    const closeNotification = () => {
        setErrorMessage(null);
    }

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img as any);
    }

    const getFileNameFromUrl = (url: string) => {
        if (url.trim().length > 0) {
            var urlSplited = url.split('/');
            return (urlSplited[urlSplited.length - 1])
        }
        return "";
    }

    useEffect(() => {
        if (props.category) {

            formik.setValues({
                iconUrl: props.category?.iconUrl,
                name: props.category?.name,
                status: props.category.status
            })

            setImageUrl(props.category.iconUrl);
            if (props.category.status == CategoryStatus.Active)
                setStatus(true);
            else
                setStatus(false);

            const modelFileEdit: Array<UploadFile> = [
                {
                    uid: props.category.id,
                    name: getFileNameFromUrl(props.category.iconUrl),
                    status: 'done',
                    type: 'image',
                    size: 1,
                    url: props.category.iconUrl,
                    thumbUrl: props.category.iconUrl,
                }
            ];

            setEditFileUploaded(modelFileEdit);
            setAffiliateCategory(props.category.affiliates)
            setAcceptedFiles(acceptFileUpload.split(','))

        }
    }, [initialValues])

    const onUploadChange = (info: UploadChangeParam) => {
        if (info) {
            if (info.file && acceptedFiles.length > 0 && acceptedFiles.includes(info.file.type)) {

                let formData = new FormData();
                formData.append("file", info.file as any);
                setUploadedFile(formData)
                getBase64(info.file as any, (imageUrl: any) => {
                    setImageUrl(imageUrl);
                });
            }
        }
    }


    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const DraggableContainer = (props: any) => {
        return (
            <ASortableContainer
                useDragHandle
                disableAutoscroll={false}
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        )
    }

    const DraggableBodyRow = (dragable: any) => {
        const index = affiliateCategory.findIndex(x => x.order === dragable['data-row-key']);
        return <ASortableItem index={index} {...dragable} />;
    };

    const onSortEnd = (index: OrderIndex) => {
        if (index.oldIndex !== index.newIndex) {
            const newData = arrayMove([].concat([...affiliateCategory] as never[]), index.oldIndex, index.newIndex).filter(el => !!el);
            (newData as Array<AffiliateModel>).forEach((item, index) => {
                item.order = index + 1;
            });

            let orderdAffiliate: Array<UpdateMarketplaceOrderModel> = [];
            orderdAffiliate = newData.map((f: MarketplaceCategoriesModel) =>
            ({
                id: f.id,
                order: f.order
            } as UpdateMarketplaceOrderModel));

            if (orderdAffiliate.length > 0) {        
                updateOrderNumberOfAffiliates(orderdAffiliate);
            }

            setAffiliateCategory([...[], ...newData]);
        }
    };

    const updateOrderNumberOfAffiliates = (affiliates: Array<UpdateMarketplaceOrderModel>) => {
        commandRequest(() => marketplace.updateOrder(OrderType.Affiliates, affiliates))
            .then((res: any) => {
              //  props.updateMarketplaceCategoriesInStoreAction([]);
            }).catch((error: any) => {
                // todo :: show alert;
            })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onEditAffiliateSubmit = () => {
        if (props.category) {
            let updateCategoryModel: UpdateMarketplaceCategoryModel = {
                id: props.category.id,
                iconUrl: ``,
                name: formik.values.name,
                status: (status) ? CategoryStatus.Active : CategoryStatus.Paused
            }

            if (!uploadedFile) {
                updateCategoryModel.iconUrl = getFileNameFromUrl(formik.values.iconUrl);
                editAffiliate(updateCategoryModel);
            } else {
                if (uploadedFile) {
                    commandRequest(() => uploadIcon(uploadedFile))
                        .then((res: FileResponse) => {
                            updateCategoryModel.iconUrl = `${res.fileName}`;
                            editAffiliate(updateCategoryModel)
                        })
                        .catch((error: any) => {
                            // todo :: show alert;
                        })
                }
            }
        }
    }

    const editAffiliate = (model: UpdateMarketplaceCategoryModel) => {
        try {
            commandRequest(() => marketplace.updateCategory(model))
                .then(() => {
                    props.changeVisibility(props.isVisible);
                }, (reason: any) => {
                    setErrorMessage(reason)
                }).catch((error: any) => {
                    // todo :: show alert;
                })
        }
        catch (error) {
            console.log("error: ", error)
        }

    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: onEditAffiliateSubmit
    })

    const deleteMarketplaceCategories = () => {
        return (
            Modal.confirm({
                title: `Are you sure you want to delete "${props.category?.name}"?`,
                icon: <DeleteFilled className={"delete-icon-modal"} />,
                content: `All affiliates that are currently assigned to this category will be temporary paused, until assigned another category. 
                Assigning a new category can be done by going into the affiliate details page and select one of the existing categories.`,
                okText: 'Delete',
                cancelText: 'Cancel',
                className: "delete-modal",
                onOk() {
                    if (props.category?.id) {
                        let id = props.category.id;
                        commandRequest(() => marketplace.deleteCategory(id))
                            .then(() => {
                                props.changeVisibility(props.isVisible);
                            }, (reason: any) => {
                                setErrorMessage(reason)
                            }).catch((error: any) => {
                                // todo :: show alert;
                            })
                    }
                },
                onCancel() {
                },
            })
        )
    }

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '', dataIndex: 'name',
            key: 'Id',
            render: (id: string, row: AffiliateModel) => (
                <>
                    <span className="affiliate">
                        <img className="icon" src={`${row.iconUrl}`}></img>
                        <span className="name">
                            {row?.name.length > maxStringLength ?
                                <>
                                    <Popover content={row.name} title="Category" trigger="hover">
                                        {`${row?.name.substring(0, maxStringLength)}...`}
                                    </Popover>
                                </> :
                                <>  {row.name} </>
                            }
                        </span>
                    </span>
                </>
            )
        },
    ]

    const getAffiliates = (
        <Row className="row-item">
            <span className="main-title">{`Ordered affiliates (${props.category?.affiliates.length})`}</span>
            <Row className="row-item table-row">
                <Table
                    className="categories-table"
                    pagination={false}
                    dataSource={affiliateCategory}
                    columns={columns}
                    rowClassName={(record, index) => record.status == AffiliateStatus.Paused ? 'disabled-row ' : ''}
                    rowKey="order"
                    components={(affiliateCategory.length > 1) ? {
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    } : {}}
                />

            </Row>
        </Row>
    )

    return (
        <>
            <Drawer
                title={
                    <>
                        <span className="modal-header-title">Edit category</span>
                        {errorMessage ? <AlertComponent {...{ type: 'error', closable: true, message: 'Error', description: errorMessage, showIcon: true, onClose: closeNotification }}></AlertComponent> : ("")}

                    </>
                }
                placement={"right"}
                closable={false}
                onClose={onClose}
                visible={props.isVisible}
                key={"right"}
                width={464}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div style={{
                        textAlign: 'right',
                    }}>
                        <Button className="cancel-button" onClick={onClose} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button className="save-button"
                            onClick={() => formik.handleSubmit()} type="primary">
                            Save
                        </Button>
                    </div>
                }>

                <Row className="edit-category-row-item">
                    <Row className="row-item">
                        <span className="affiliate-name">
                            <span className="edit-head-item">
                                <span className="affiliate-icon">
                                    <Upload
                                        beforeUpload={() => false}
                                        listType="picture-card"
                                        className="affiliate-icon"
                                        onChange={onUploadChange}
                                        name="avatar"
                                        accept={acceptFileUpload}
                                        showUploadList={false}>
                                        {imageUrl ? <img className="edit-render-uploaded-image" src={imageUrl} alt="avatar" /> : uploadButton}
                                    </Upload>
                                </span>
                            </span>
                            <span className="edit-head-item name">
                                <span className="item-title">Category name</span>
                                <Input maxLength={maxInputLength} onChange={formik.handleChange} value={formik.values.name} name="name" className="item-input" placeholder="Please enter name" />
                                {formik.errors.name && formik.touched.name && (
                                    <div
                                        className="ant-form-item-explain ant-form-item-explain-error">
                                        <div role="alert">{formik.errors.name}</div>
                                    </div>
                                )}
                                <Button onClick={deleteMarketplaceCategories} className="button-delete" icon={<DeleteOutlined className="error-style" />} size={'large'} />
                            </span>
                        </span>
                    </Row>
                    <Row className="row-item status-row">
                        <span className="status-field">
                            <span className="title-field">Status </span>
                            <span className="description-field">Decide if this category is active or not.</span>
                        </span>
                        <Switch
                            onChange={onEditStatusChanged}
                            checked={status}
                            className="checkbox-status"
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked />
                    </Row>
                    {props.category && props.category.affiliates.length > 0 &&
                        <>
                            {getAffiliates}
                        </>
                    }
                </Row>
            </Drawer>
        </>
    )
}
export default EditCategoriesComponent;