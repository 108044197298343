import { GET_SUBSCRIPTIONPLANS_ERROR, GET_SUBSCRIPTIONPLANS_SUCCESS } from '../../../../actions/dashboard/subscriptions/subscriptionPlans/updateSubscriptionPlansInStore';
import { SubscriptionPlans } from './../../../../models/dashboard/subscriptions/subscriptions';
import { SubscriptionPlansInStoreAction } from '../../../../actions/dashboard/subscriptions/subscriptionPlans/updateSubscriptionPlansInStore';

export const updateSubscriptionPlansInStoreReducer = (state: Array<SubscriptionPlans> | [] = [], 
    action: SubscriptionPlansInStoreAction) => {
    switch (action.type) {
        case GET_SUBSCRIPTIONPLANS_SUCCESS:
            return [...state, ...action.subscriptionPlans ]
        case GET_SUBSCRIPTIONPLANS_ERROR:
            return [...state];
        default:
            return [];
    }
}