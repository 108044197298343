import { FullscreenOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import ImageViewer from 'react-simple-image-viewer';
import { newGuid } from "../../utils/Utils";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
export interface ImagesModal {
    url: string
}
export interface ImagePreviewGroupProps {
    imageUrls: Array<string>
    isOpen: boolean,
    isParent: boolean,
    changeVisibility: (isVisible: boolean) => void;

}

const ImagePreviewGroupComponent: React.FC<ImagePreviewGroupProps> = (props: ImagePreviewGroupProps) => {


    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState<Array<string>>([]);

    useEffect(() => {
        setImages(props.imageUrls);
    }, [props.imageUrls])


    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);        
        props.changeVisibility(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        props.changeVisibility(false);

    };

    return (

        <div>
            <Button key={newGuid()} type="primary" className="general-button " onClick={() => {
                openImageViewer(0);
            }} icon={<FullscreenOutlined />}>  screenshot.png </Button>

            {isViewerOpen && (
                <>
                <Lightbox
                 mainSrc={images[currentImage]}
                 nextSrc={images[(currentImage + 1) % images.length]}
                 prevSrc={images[(currentImage + images.length - 1) % images.length]}
                 onCloseRequest={() =>{closeImageViewer()}}
                 onMovePrevRequest={() =>{
                     setCurrentImage((currentImage + images.length -1) % images.length)
                 }
                 }
                 onMoveNextRequest={() => {
                    setCurrentImage((currentImage + 1) % images.length)

                 }
                 }
                ></Lightbox>

                {/* <ImageViewer
                    key={newGuid()}
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                /> */}
                </>
            )}
        </div>
    );
   
}
export default ImagePreviewGroupComponent;

