
import  getUsersList from "./getUsers";
import  {deleteUser} from "./userDetails";
import  {cancelUserDeletion} from "./userDetails";
import  {getUsersDetails} from "./userDetails";
import {getPlaidDetails} from "./getPlaidItems";
import  searchUsers from "./searchUsers";


const users = {
    getUsersDetails,
    getUsersList,
    deleteUser,
    cancelUserDeletion,
    searchUsers,
    getPlaidDetails
}
export default users;