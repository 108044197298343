import { CalendarOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Divider, Row, Spin, Statistic, Tabs } from "antd";
import { connect, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { updateWithdrawalStatisticsInStoreAction } from "../../../../core/actions/dashboard/moveMoney/updateWithdrawalStatisticsInStore";
import { AppState } from "../../../../core/commons/reduxStore";
import { RapidWithdrawStatisticsModel, StandardWithdrawStatisticsModel, WithdrawStatisticsModel } from "../../../../core/models/dashboard/withdraw/withdraw";
import StatisticComponent from "../../../common/components/dashboard/StatisticsComponent";
import { WithdrawStatisticsChartColor } from "../../../common/utils/ChartColors";
import { getMoneyType } from "../../../common/utils/Utils";
import "../.../../../../../styles/layout/dashboard/withdraw/withdraw.less";
const { TabPane } = Tabs;

export interface WithdrawStatisticsComponentProps {
    standardWithdrawStatisticsModel: StandardWithdrawStatisticsModel | null;
    rapidWithdrawStatisticsModel: RapidWithdrawStatisticsModel | null;
}

const WithdrawStatisticsComponent: React.FC<WithdrawStatisticsComponentProps> = (props: WithdrawStatisticsComponentProps) => {
    const defaultActiveKey = "1";
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [rapidWithdrawStats, setRapidWithdrawStats] = useState<RapidWithdrawStatisticsModel | null>(null);

    const standardWithdraw = (props.standardWithdrawStatisticsModel) ? props.standardWithdrawStatisticsModel : null;

    useEffect(() => {
        setRapidWithdrawStats(props.rapidWithdrawStatisticsModel);
    }, [props.rapidWithdrawStatisticsModel])


    const onGetStatisticsSuccess = () => {
    }

    const onGetStatisticsError = () => {
    }


    const getRulesStatistics = (title:string, totalCount:number, totalFee: number) => {

        return (
        <Col span={8} >
            <StatisticComponent {...{ title: `${title}`, badgeColor: WithdrawStatisticsChartColor.Pro, value: `${totalCount}`}} />
            <StatisticComponent {...{ title: "Total fee", badgeColor: WithdrawStatisticsChartColor.Pro, value: getMoneyType(totalFee ?? 0) }} />
        </Col>
        
        )
    
        
    }
    return (

        <Row className="withdraw-statistics" justify="start" style={{ width: '100%' }}>
            <Col flex={2}>
                <Row className="avatar-row">
                    <Avatar size={54} className="avatar-item" icon={<CalendarOutlined style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }} />} />
                    <h6 style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingLeft: '5px', margin: 0 }}>Standard Withdraw</h6>
                </Row>
                <Statistic title={'Total standard withdraw'} value={standardWithdraw?.totalStandardWithdraw} />
                <Row justify="start" className="row-statistics" gutter={[8, 8]}>
                    <Col span={24} >
                        <StatisticComponent {...{ title: "Total Amount", badgeColor: WithdrawStatisticsChartColor.Pro, value: getMoneyType(standardWithdraw?.totalAmountStandardWithdraw ?? 0) }} />
                    </Col>

                </Row>
            </Col>
            <Divider type="vertical" className="divider" />
            <Col flex={3}>
                <Row style={{ display: 'flex', justifyContent: 'start', textAlign: 'center', paddingBottom: '10px' }}>
                    <Avatar size={54} style={{ color: '#3AB100', maxWidth: '54px', maxHeight: '54px', lineHeight: '54px', justifyContent: 'center', alignItems: 'center ' }} icon={<ThunderboltOutlined />} />
                    <h6 style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingLeft: '5px' }}> Rapid Withdraw</h6>
                </Row>

                <Statistic title={'Total rapid withdraw'} value={rapidWithdrawStats?.totalRapidWithdraw} />

                <Row justify="start" className="row-statistics" gutter={[8, 8]}>
                    <Col span={12} >
                        <StatisticComponent {...{ title: "Total Amount", badgeColor: WithdrawStatisticsChartColor.Pro, value: getMoneyType(rapidWithdrawStats?.totalAmountRapidWithdraw ?? 0) }} />
                    </Col>
                    <Col span={12} >
                        <StatisticComponent {...{ title: "Total Fees", badgeColor: WithdrawStatisticsChartColor.Pro, value: getMoneyType(rapidWithdrawStats?.totalFeeRapidWithdraw ?? 0) }} />
                    </Col>
                    <Divider type="horizontal" />
                    {
                      rapidWithdrawStats?.rapidWithdrawStatisticsRulesModels.map(item=>{
                        return getRulesStatistics(item.title, item.totalCount, item.totalAmount)
                      })  
                    }                   
                </Row>

            </Col>
        </Row>
    )

}

const mapStateToProps = ({ }: AppState) =>
({
});

export default connect(mapStateToProps, {

})(WithdrawStatisticsComponent);