import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SuggestedAdvertisers } from "../../../../models/dashboard/marketplace/suggestedAdvertisers";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../../commons/reduxStore';
import getSuggestedAffiliates from '../../../../services/dashboard/marketplace/suggestedAffiliates/getSuggestedAffiliates';

export const GET_SUGGESTED_SUCCESS = "GET_SUGGESTED_SUCCESS";
export const GET_SUGGESTED_ERROR = "GET_SUGGESTED_ERROR";

export interface SuggestedAffiliatesInStoreAction {
    type: typeof GET_SUGGESTED_SUCCESS | typeof GET_SUGGESTED_ERROR,
    suggestAdvertiser: SuggestedAdvertisers
}

export const updateSuggestedAffiliatesInStoreAction = (suggestAdvertiser: SuggestedAdvertisers | null) : ThunkAction<void, AppState, unknown, Action> => dispatch => {
            queryRequest(() => getSuggestedAffiliates())
                .then((res: SuggestedAdvertisers) => {
                    dispatch({
                        type: GET_SUGGESTED_SUCCESS,
                        suggestAdvertiser: res
                    } as SuggestedAffiliatesInStoreAction)
                })
                .catch((err) => {
                    dispatch({
                        type: GET_SUGGESTED_ERROR,
                        suggestAdvertiser: suggestAdvertiser
                    } as SuggestedAffiliatesInStoreAction)
                });
        }