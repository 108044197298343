import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../../commons/reduxStore";
import { DailySubscriptionStatistics, MonthlySubscriptionStatistics, SubscriptionStatistics, TotalSubscriptionStatistics } from "../../../../models/dashboard/subscriptions/subscriptions";
import getSubscriptionStatistics from '../../../../services/dashboard/subscriptions/getSubscriptionStatistics';

export const GET_SUBSCRIPTIONS_STATISTICS_SUCCESS = "GET_SUBSCRIPTIONS_STATISTICS_SUCCESS";
export const GET_SUBSCRIPTIONS_STATISTICS_ERROR ="GET_SUBSCRIPTIONS_STATISTICS_ERROR";
const totalSubscriptionStatistics : TotalSubscriptionStatistics = {
    canceledSubscriptions:0,
    totalPremium:0,
    totalSubscriptions:0,
    totalTrial:0,
    usersWithPremiumCard:0
}

const monthlySubscriptionStatistics : MonthlySubscriptionStatistics = {
    canceledSubscriptions:0,
    totalPremium:0,
    totalSubscriptions:0,
    totalTrial:0,
    usersWithPremiumCard:0,
    totalExistingUser:0,
    totalNewUsers:0
}
const dailySubscriptionStatistics : DailySubscriptionStatistics = {
    canceledSubscriptions:0,
    totalPremium:0,
    totalSubscriptions:0,
    totalTrial:0,
    usersWithPremiumCard:0,
    totalExistingUser:0,
    totalNewUsers:0
}
var initStatistics: SubscriptionStatistics = {
    dailySubscriptionStatistics: dailySubscriptionStatistics,
    monthlySubscriptionStatistics: monthlySubscriptionStatistics,
    subscriptionStatistics: totalSubscriptionStatistics
}
export interface SubscriptionsInStoreAction  {
    type: typeof GET_SUBSCRIPTIONS_STATISTICS_SUCCESS | typeof GET_SUBSCRIPTIONS_STATISTICS_ERROR,
    subscriptionStatistics: SubscriptionStatistics 
}

export const updateSubscriptionStatisticsInStoreAction = () : ThunkAction<void, AppState, unknown, Action> => dispatch => {
    queryRequest(() => getSubscriptionStatistics())
    .then((res: SubscriptionStatistics) => {
        dispatch({
            type: GET_SUBSCRIPTIONS_STATISTICS_SUCCESS,
            subscriptionStatistics: res
        } as SubscriptionsInStoreAction);
    })
    .catch((err) => {
        dispatch({
            type: GET_SUBSCRIPTIONS_STATISTICS_ERROR,
            subscriptionStatistics: initStatistics
        } as SubscriptionsInStoreAction);
    })
}