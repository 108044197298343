import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../commons/reduxStore";
import { UserModel, UserSearch } from "../../../models/dashboard/users/user";
import users from '../../../services/dashboard/users/index';

export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";

const {getUsersList} = users;

export interface UsersListInStoreAction {
    type: typeof GET_USERS_LIST_SUCCESS | typeof GET_USERS_LIST_ERROR;
    users: UserModel | null, 
    searchParams: UserSearch
}

export const updateUsersListInStoreAction =
 (users: UserModel | null, searchParams: UserSearch )
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getUsersList(searchParams)).then((userRes) => {
            dispatch({
                type: GET_USERS_LIST_SUCCESS,
                users: userRes
            } as UsersListInStoreAction)
        })
        .catch((err) => {
            dispatch({
                type: GET_USERS_LIST_ERROR,
                users: users
            } as UsersListInStoreAction)
        });
    }