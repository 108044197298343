import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateSubscriptionPlansInStoreAction } from "../../../../core/actions/dashboard/subscriptions/subscriptionPlans/updateSubscriptionPlansInStore";
import { AppState } from "../../../../core/commons/reduxStore";
import { SubscriptionPlans, UpdateSubscription } from "../../../../core/models/dashboard/subscriptions/subscriptions";
import "../../../../styles/layout/dashboard/subscriptions/subscriptionPlans/subscriptionPlans.less"
import { error, maxInputLength } from "../../../common/utils/Utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import { commandRequest } from "../../../common/requestUtils/requestUtils";

import services from "../../../../core/services";
const { subscriptions } = services;

export interface SubscriptionPlansProps {
    updateSubscriptionPlansInStoreAction(): void;
    subscriptionPlans: Array<SubscriptionPlans> | []
}

interface SubscriptionPlanFormValue {
    title: string;
    price: number;
}

let initialValue: SubscriptionPlanFormValue = {
    title: "",
    price: 0
}

const formValidator = () =>
    Yup.object({
        title: Yup.string().max(50, 'You have reached your maximum limit of characters allowed').required("Title is required"),
        priice: Yup.string().min(0).required('Subscription price is required')
    });

const SubscriptionPlansComponent: React.FC<SubscriptionPlansProps> = (props: SubscriptionPlansProps) => {

    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlans>>([]);
    const [isMounting, setMounting] = useState(true);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlans | null>(null);

    if (isMounting) {
        props.updateSubscriptionPlansInStoreAction();
        setMounting(false);
    }

    useEffect(() => {
        if (subscriptionPlans.length == 0) {
            console.log("props: ", props.subscriptionPlans);
            setSubscriptionPlans(props.subscriptionPlans)
        }
    }, [props.subscriptionPlans])

    const editMode = (item: SubscriptionPlans) => {
        initialValue.title = item?.title ?? "";
        initialValue.price = item?.amount ?? 0;
        formik.values.title = item?.title ?? "";
        formik.values.price = item?.amount??0;

        setSelectedPlan(item);
        setModalVisible(true);
       
    }

    const handleUpdate = () => {

        debugger;
        if (selectedPlan) {
            let model: UpdateSubscription = {
                amount: formik.values.price,
                title: formik.values.title,
                id: selectedPlan?.id
            }
            try {
                commandRequest(() => subscriptions.updateSubscriptionPlan(model))
                    .then(() => {
                        props.updateSubscriptionPlansInStoreAction();
                        setModalVisible(false);
                    }, (reason: any) => {
                        setModalVisible(false);
                        error(reason);
                    })
                    .catch((error) => {
                        setModalVisible(false);
                        console.log("error: ", error)
                    })
            }
            catch (error) {
                console.log("error: ", error)
            }
            setModalVisible(false);
        }
    }

    const handleCancel = () => {
        setModalVisible(false);

    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValue,
        validationSchema: formValidator,
        validateOnChange: true,
        onSubmit: handleUpdate
    })

    return (
        <>
            {subscriptionPlans.map((item: SubscriptionPlans, index: any) => {
                return (
                    <Col className="card-column" span={12}>
                        <Card className="card-item" bordered={false}>
                            <Row>
                                <Avatar size={64}  src={item.iconUrl} />
                                <Row className="subscription-plan-item">
                                    <span className="title">{item.title}</span>
                                    <span>{`$${item.amount}`}</span>
                                </Row>
                                <Button
                                    className="button-link"
                                    onClick={() => {
                                        editMode(item);
                                    }}
                                    type="link"
                                    icon={<EditOutlined />}>Edit plan</Button>
                            </Row>
                        </Card>
                    </Col>
                )
            })
            }
            <Modal
                className="modal"
                title={`Edit: ${selectedPlan?.title}`}
                visible={isModalVisible}
                onOk={handleUpdate}
                onCancel={handleCancel}
                footer={[
                    <Button className="footer-cancel-button" key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button className="footer-submit-button"
                        key="submit"
                        type="primary"
                        onClick={handleUpdate}>
                        Save
                    </Button>,
                ]}
            >
                <Row className="row-modal">
                    <span className="title-field">Subscription name </span>
                    <Input className="name"
                        maxLength={maxInputLength}
                        onChange={formik.handleChange}
                        value={formik.values.title || ''}
                        size="large"
                        name="title"
                    />

                    {formik.errors.title && formik.touched.title && (
                        <div
                            className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">{formik.errors.title}</div>
                        </div>
                    )}

                </Row>
                <Row className="row-modal">
                    <span className="title-field">Price in $</span>
                    <Input className="name"
                        maxLength={maxInputLength}
                        onChange={formik.handleChange}
                        value={formik.values.price || ''}
                        size="large"
                        name="price"
                    />

                    {formik.errors.price && formik.touched.price && (
                        <div
                            className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">{formik.errors.price}</div>
                        </div>
                    )}

                </Row>
            </Modal>
        </>
    )
}


const mapStateToProps = ({ subscriptionPlans }: AppState) =>
({
    subscriptionPlans: subscriptionPlans
});
export default connect(mapStateToProps, {
    updateSubscriptionPlansInStoreAction

})(SubscriptionPlansComponent);