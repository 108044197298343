import { Select } from "antd"
import React from "react"
import { UserStatus, UserStatusNotification } from "../../../../core/models/dashboard/users/user";
import TagStatusComponent from "./UserTagStatusComponent";
import "../../../../styles/components/userSelectStatus.less";
const { Option } = Select;

export interface UserSelectStatusOptionsProps {
    onChange?: (value: any, option: any) => void;
}

const UserSelectStatusComponent: React.FC<UserSelectStatusOptionsProps> = props => {
    return (
        <>
            <Select
                mode="multiple"
                allowClear
                className="user-status-multiple-select"
                placeholder="Filter by status"
                onChange={props.onChange}>
                <Option value={UserStatusNotification.Active} key={UserStatusNotification.Active}><TagStatusComponent {...{ status: UserStatusNotification.Active }} /></Option>
                <Option value={UserStatusNotification.BankConnected} key={UserStatusNotification.BankConnected}><TagStatusComponent {...{ status: UserStatusNotification.BankConnected }} /></Option>
                <Option value={UserStatusNotification.BankDisconnected} key={UserStatusNotification.BankDisconnected}><TagStatusComponent {...{ status: UserStatusNotification.BankDisconnected }} /></Option>
                <Option value={UserStatusNotification.DeletedRequest} key={UserStatusNotification.DeletedRequest}><TagStatusComponent {...{ status: UserStatusNotification.DeletedRequest }} /></Option>
                <Option value={UserStatusNotification.NotVerified} key={UserStatusNotification.NotVerified}><TagStatusComponent {...{ status: UserStatusNotification.NotVerified }} /></Option>
                <Option value={UserStatusNotification.OnBoarding} key={UserStatusNotification.OnBoarding}><TagStatusComponent {...{ status: UserStatusNotification.OnBoarding }} /></Option>
                <Option value={UserStatusNotification.NotIdentified} key={UserStatusNotification.NotIdentified}><TagStatusComponent {...{ status: UserStatusNotification.NotIdentified }} /></Option>
                <Option value={UserStatusNotification.MainInvestInvestors} key={UserStatusNotification.MainInvestInvestors}><TagStatusComponent {...{ status: UserStatusNotification.MainInvestInvestors }} /></Option>
            </Select>

        </>
    )
}

export default UserSelectStatusComponent;