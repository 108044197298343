import { AmazonCommisionsModel } from './../../../core/models/dashboard/marketplace/marketplaceCashbackRequestModel';
const amazonCommsionData = {
    commsions:  [
        {
            productCategory:'Amazon Games',
            commission:'20.00%',
            specialCondition:'',
        },
        {
            productCategory:'Premium Beauty',
            commission:'12.00%',
            specialCondition:'LinkCode: Twitch Panel, Twitch',
        },
        {
            productCategory:'Video Game Downloads',
            commission:'10.00%',
            specialCondition:'Component/Overlay',
        },
        {
            productCategory:'Luxury Stores Beauty | Premium Beauty',
            commission:'10.00%',
            specialCondition:'',
        },
        {
            productCategory:'Books & Textbooks',
            commission:'8.50%',
            specialCondition:'',
        },
        {
            productCategory:'Camera, Photo & Video | Cell Phones & Accessories | Clothing & Accessories | Electronic Components & Home Audio',
            commission:'8.00%',
            specialCondition:'',
        },
        {
            productCategory:'Beauty & Grooming | Furniture | Home | Sports & Fitness',
            commission:'7.00%',
            specialCondition:'',
        },       
        {
            productCategory:'Computers, Tablets & Components',
            commission:'4.00%',
            specialCondition:'LinkCode: Twitch Panel, Twitch Component/Overlay',
        },              
        {
            productCategory:'CDs & Vinyl | Digital Music | Handmade | Video On Demand: Rent or Buy',
            commission:'5.00%',
            specialCondition:'',
        },
        {
            productCategory:'Automotive | Kitchen & Dining',
            commission:'4.50%',
            specialCondition:'',
        },
        {
            productCategory:'Computers, Tablets & Components',
            commission:'4.00%',
            specialCondition:'LinkCode: Twitch Panel, Twitch Component/Overlay',
        },
        {
            productCategory:'Amazon Fashion Private Brands | Blink Devices | Echo Devices | Echo Look | Fire TV Devices | Fire TV Edition Smart TVs | Fire Tablets | Jewelry | Kindle E-readers | Luggage | Luxury Stores Fashion | Ring Accessories | Ring Devices | Shoes, Handbags, Wallets, Sunglasses | Watches',
            commission:'4.00%',
            specialCondition:'',
        },
        {
            productCategory:'Blu-Ray & DVD',
            commission:'2.50%',
            specialCondition:'',
        },
        {
            productCategory:'Video Games',
            commission:'2.00%',
            specialCondition:'LinkCode: Twitch Panel, Twitch Component/Overlay',
        },
        {
            productCategory:'Home Entertainment: TV | Video Game Downloads',
            commission:'2.00%',
            specialCondition:'',
        },
        {
            productCategory:'Amazon Fresh | Grocery & Gourmet Food | Health & Household | Video Games',
            commission:'1.00%',
            specialCondition:'',
        },
        {
            productCategory:'Amazon Gift Cards | Appstore for Android | Kindle Unlimited Memberships | Other Gift Card Brands | Prime Memberships | Wine, Spirits & Beer',
            commission:'0.00%',
            specialCondition:'',
        },
        {
            productCategory:'All Other Categories',
            commission:'4.00%',
            specialCondition:'',
        }   
    ]
}
export default amazonCommsionData;