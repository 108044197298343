import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../../commons/reduxStore";
import getCategories from '../../../../services/dashboard/marketplace/categories/getMarkteplaceCategories';

import { MarketplaceCategoriesModel } from '../../../../models/dashboard/marketplace/marketplaceCategoryModel';

export const GET_MARKETPLACECATEGORIES_SUCCESS  ="GET_MARKETPLACECATEGORIES_SUCCESS";
export  const  GET_MARKETPLACECATEGORIES_ERROR = "GET_MARKETPLACECATEGORIES_SUCCESS";

export  interface MarketplaceCategoriesInStoreAction {
    type: typeof GET_MARKETPLACECATEGORIES_SUCCESS | typeof GET_MARKETPLACECATEGORIES_ERROR
    affiliateCategory: Array<MarketplaceCategoriesModel>
}

export  const  updateMarketplaceCategoriesInStoreAction = 
    (categories: Array<MarketplaceCategoriesModel>)
     : ThunkAction<void, AppState,  unknown, Action> => dispatch => {
    queryRequest(() => getCategories())
        .then((res:Array<MarketplaceCategoriesModel>) => {
            dispatch({
                type: GET_MARKETPLACECATEGORIES_SUCCESS,
                affiliateCategory: res
            } as MarketplaceCategoriesInStoreAction)
        })
        .catch((err) => {
            dispatch({
                type: GET_MARKETPLACECATEGORIES_ERROR,
                affiliateCategory: categories
            } as MarketplaceCategoriesInStoreAction)
        });
    }