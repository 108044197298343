import React, { ComponentProps, useState } from "react"
import { PieChart } from "react-minimal-pie-chart"
import { UserStatisticsChartColor } from "../../utils/ChartColors"

export interface PieChartProps {
    className:string;
    data: Array<ChartData>
}
export interface ChartData {
    title:string;
    value:number;
    color:string;
}

const PieChartComponent: React.FC<PieChartProps> = props => {
    return (
            <>
            <PieChart   className= {props.className}
                data={props.data}
            />
        </>
    )
}
export default PieChartComponent;


