import { Button, Card, Empty, Row, Spin, Table, Popover, Tabs } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../core/commons/reduxStore";
import { AffiliateStatus, CSVMarketplaceMarketplaceModel, GetMarketplaceModel, MarketplaceModel, MarketplaceSearch } from "../../../../core/models/dashboard/marketplace/affiliatesModel";
import marketplaceAction from "../../../../core/actions/dashboard/marketplace/index"
import { ArrowRightOutlined, DownloadOutlined, DownOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import "../../../../styles/layout/dashboard/marketplace/affiliateList.less";
import StatusItemComponent, { Status } from "../../../common/components/dashboard/StatusItemComponent";
import AddNewAffiliateComponent from "./AddNewAffiliateComponent";
import { MarketplaceCategoriesModel } from "../../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import { MarketplacePlatformModel } from "../../../../core/models/dashboard/marketplace/marketplaceModels";
import PlatformComponent from "../../../common/components/dashboard/DynamicPlatformComponent";
import ButtonComponent from "../../../common/components/dataDisplay/Button";
import SearchComponent from "../../../common/components/dataInput/components/SearchComponent";
import { useHistory } from "react-router-dom";
import { getAffiliateStatusName, getPurchaseStatusName, maxSearchLength, maxStringLength, s3Url } from "../../../common/utils/Utils";
import { CSVLink } from "react-csv";
import { updateMarketplaceCategoriesInStoreAction } from "../../../../core/actions/dashboard/marketplace/categories/updateMarketplaceCategoriesInStore";


const { TabPane } = Tabs;

const { updateMarketplacesInStoreAction, updateAllMarketplacesInStoreAction } = marketplaceAction;

enum AffiliateTabType {
    ActiveAffiliates,
    DeletedAffiliates
}

export interface AffiliatesListProps {
    affiliateCategory: Array<MarketplaceCategoriesModel> | []
    marketplacePlatform: Array<MarketplacePlatformModel> | []
    marketplaces: GetMarketplaceModel | null,
    updateMarketplacesInStoreAction: (marketplaces: GetMarketplaceModel | null, searchParams: MarketplaceSearch) => void;
}

const initSearchParams: MarketplaceSearch = {
    pageNumber: 1,
    pageSize: 5,
    searchValue: "",
    orderDescending: false,
    sortColumn: "Name",
    isDeleted: false
}

const AffiliateListComponent: React.FC<AffiliatesListProps> = (props: any) => {

    const defaultSortColumnt = "Name";
    const history = useHistory();
    const [isMounting, setMounting] = useState(true);
    const [isCollapsed, setCollapsed] = useState(true);
    const [isLoading, setLoading] = useState<boolean>(true)
    const [addNewAffiliateMode, setAddNewAffiliate] = useState<boolean>(false);
    const [currentAffiliates, setCurrentState] = useState<GetMarketplaceModel>();
    const [csvMarketplaces, setCSVMarketplaces] = useState<Array<CSVMarketplaceMarketplaceModel>>([])

    useEffect(() => {
        if (props.marketplaces) {
            if (!currentAffiliates) {
                setCurrentState(props.marketplaces)
            }

            if (!Object.is(currentAffiliates, props.marketplaces)) {
                setLoading(false);
                setCurrentState(props.marketplaces)
            }
        }
    });

    const initSearchMarketplace = () => {
        initSearchParams.pageNumber = 1;
        initSearchParams.pageSize = 5;
        initSearchParams.searchValue = "";
        initSearchParams.orderDescending = false;
        initSearchParams.sortColumn = defaultSortColumnt;
    }

    const getListOfMarketplaces = () => {
        props.updateMarketplacesInStoreAction(null, initSearchParams);
        props.updateMarketplaceCategoriesInStoreAction([]);
    }

    const getAllListOfMarketplaces = () => {
        props.updateAllMarketplacesInStoreAction([], initSearchParams.isDeleted);
    }

    const onGetAllMarketplaceSucess = () => {
        let exportedData: Array<CSVMarketplaceMarketplaceModel> = [];
        if (props.allMarketplaces) {
            props.allMarketplaces.forEach((item: MarketplaceModel) => {
                if (item != null) {

                    exportedData.push({
                        Name: item.name,
                        AccessUrl: item.accessUrl,
                        SecondAccessUrl: item.secondAccessUrl,
                        CashBackPercentage: `${item.cashBackPercentage * 100}%`,
                        GuacCashBackPercentage: `${item.guacCashBackPercentage * 100}%`,
                        Platform: (item.platform) ? item.platform.name : "",
                        Category: (item.category) ? item.category.name : "",
                        Status: (item.isDeleted) ? "Deleted" : getAffiliateStatusName(item.status),
                        TotalMarketplacePurchases: `${item.marketplacePurchaseDetails.totalMarketplacePurchases}`,
                        TotalCashBackAmount: `$${item.marketplacePurchaseDetails.totalCashBackAmount}`,
                        TotalMarketplacePurchaseAmount: `$${item.marketplacePurchaseDetails.totalMarketplacePurchaseAmount}`,
                    })
                }
            });
        }
        setCSVMarketplaces(exportedData);
    }

    if (props.allMarketplaces && props.allMarketplaces.length > 0 && csvMarketplaces.length == 0) {
        onGetAllMarketplaceSucess();
    }

    const onSearchSubmit = (searchValue: string) => {
        initSearchParams.searchValue = searchValue;
        initSearchParams.pageNumber = 1;
        props.updateMarketplacesInStoreAction(null, initSearchParams);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;
            } else {
                initSearchParams.sortColumn = defaultSortColumnt;
                initSearchParams.orderDescending = false;
            }
        }

        if (pagination) {
            if (props.marketplaces?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.updateMarketplacesInStoreAction(null, initSearchParams);
        setLoading(true);

    }

    const onClickAffiliate = (id: string) => {
        history.push(`/admin/marketplace/details/${id}`);
    }

    if (isMounting) {
        setLoading(true);
        getListOfMarketplaces();
        getAllListOfMarketplaces();
        setMounting(false);
    }

    const columns = [
        {
            title: 'Affiliate', dataIndex: 'name',
            key: 'Id',
            width: 220,
            fixed: true,
            sorter: true,
            render: (id: string, row: MarketplaceModel) => (
                <>
                    <Button onClick={() => { onClickAffiliate(row.id) }} type="link">
                        <span className="affiliate-list">
                            {row.iconUrl ?
                                <img className="icon" src={`${s3Url}${row.iconUrl}`}></img>
                                : ('')}
                            <span className="name">
                                {row?.name.length > maxStringLength ?
                                    <>
                                        <Popover content={row.name} title="Name" trigger="hover">
                                            {`${row?.name.substring(0, maxStringLength)}...`}
                                        </Popover>
                                    </> :
                                    <>  {row.name} </>
                                }
                            </span>
                        </span>
                    </Button>
                </>
            )
        },
        {
            title: 'Platform', dataIndex: 'partnerId', sorter: true, width: 270, key: 2, render: (partner: any, row: MarketplaceModel) => (
                <>
                    <PlatformComponent {...{ name: row?.platform?.name, iconUrl: row?.platform?.iconUrl }}></PlatformComponent>
                </>
            )
        },
        {
            title: 'Category', dataIndex: 'categoryId', width: 270, key: 3,
            sorter: true, render: (category: string, row: MarketplaceModel) => (
                <> {row.iconUrl ?
                    <img className="category-icon" src={`${s3Url}${row.category.iconUrl}`}></img>
                    : ("")}
                    <span className="category-name">
                        {row?.category.name.length > maxStringLength ?
                            <>
                                <Popover content={row.category.name} title="Category" trigger="hover">
                                    {`${row?.category.name.substring(0, maxStringLength)}...`}
                                </Popover>
                            </> :
                            <>  {row.category.name} </>
                        }
                    </span>
                </>
            )
        },
        {
            title: 'Status', dataIndex: 'status', width: 220, key: 4, sorter: true
            , render: (status: number, row: MarketplaceModel) => (
                <>
                    <span>{row.status == AffiliateStatus.Paused ? <StatusItemComponent {...{ status: Status.Paused }}></StatusItemComponent> : <StatusItemComponent {...{ status: Status.Active }}></StatusItemComponent>}</span>
                </>
            )
        },
        {
            title: 'Access URL', dataIndex: 'accessUrl', width: 250, key: 5, sorter: true
            , render: (accessUrl: string, row: MarketplaceModel) => (
                <>
                    <span>{row.accessUrl ?
                        <>

                            <Popover content={<a href={row.accessUrl}>{row.accessUrl}</a>} title="Access URL" trigger="hover">
                                <a target="_blank" href={`${row.accessUrl}`}>{`${row.accessUrl.substring(0, 20)}...`}</a>

                            </Popover>
                        </>
                        : ("")}</span>
                </>
            )
        },
        {
            title: '2nd Access URL', dataIndex: 'secondAccessUrl', width: 170, key: 6, sorter: true, render: (secondAccessUrl: string, row: MarketplaceModel) => (
                <>
                    <span>{row.secondAccessUrl ?
                        <Popover content={<a href={row.secondAccessUrl}>{row.secondAccessUrl}</a>} title="2nd Access URL" trigger="hover">
                            <a target="_blank" href={`${row.secondAccessUrl}`}>{`${row.secondAccessUrl.substring(0, 20)}...`}</a>
                        </Popover>

                        : ""}</span>
                </>
            )
        },
        {
            title: 'Guac Cashback percentage', dataIndex: 'guacCashBackPercentage', width: 170, key: 7, sorter: true, render: (guacCashBackPercentage: any, row: MarketplaceModel) => (
                <>
                    <span>{`${(row.guacCashBackPercentage * 100).toFixed(2)}%`}</span>
                </>
            )
        },
        {
            title: 'User cashback percentage', dataIndex: 'cashBackPercentage', width: 170, key: 8, sorter: true, render: (cashBackPercentage: any, row: MarketplaceModel) => (
                <>
                    <span>{`${(row.cashBackPercentage * 100).toFixed(2)}%`}</span>
                </>
            )
        },
        {
            title: 'Total marketplace purchases', dataIndex: 'marketplacePurchaseDetails.totalMarketplacePurchases', width: 170,
            key: 9, render: (totalMarketplacePurchases: any, row: MarketplaceModel) => (
                <>
                    <span>{row.marketplacePurchaseDetails.totalMarketplacePurchases}</span>
                </>
            )
        },
        {
            title: 'Total user cashback amount', dataIndex: 'marketplacePurchaseDetails.totalCashBackAmount', width: 170, key: 10,
            render: (totalCashBackAmount: any, row: MarketplaceModel) => (
                <>
                    <span>{`$${row.marketplacePurchaseDetails.totalCashBackAmount}`}</span>
                </>
            )
        },
        {
            title: 'Total marketplace purchases amount', dataIndex: 'marketplacePurchaseDetails.totalMarketplacePurchaseAmount',
            width: 170, key: 11, render: (totalMarketplacePurchaseAmount: any, row: MarketplaceModel) => (
                <>
                    <span>{`$${row.marketplacePurchaseDetails.totalMarketplacePurchaseAmount}`}</span>
                </>
            )
        },
        {
            title: '',
            key: 'action',
            fixed: true,
            dataIndex: 'id',
            width: 220,
            render: (id: string) =>
                <>
                    <Button onClick={() => { onClickAffiliate(id) }} type="link">
                        <ArrowRightOutlined />
                    </Button>
                </>
        },
    ];

    const collapse = () => {
        setCollapsed(!isCollapsed);
    }

    const openNewAffiliateModal = () => {
        setAddNewAffiliate(true);
    }

    const closeAddNewModal = () => {
        setAddNewAffiliate(false);
        setLoading(true);
        initSearchMarketplace();
        getListOfMarketplaces();
        getAllListOfMarketplaces();
    }

    const onTabChange = (activeKey: any) => {
        setLoading(true);
        setCSVMarketplaces([]);
        initSearchParams.pageNumber = 1;
        initSearchParams.pageSize = 5;
        initSearchParams.searchValue = "";
        initSearchParams.orderDescending = false;
        initSearchParams.sortColumn = defaultSortColumnt;
        initSearchParams.isDeleted = (Number(activeKey) == AffiliateTabType.ActiveAffiliates) ? false : true;
        getListOfMarketplaces();
        getAllListOfMarketplaces();
    }

    return <>
        <Spin tip="Loading..." spinning={isLoading}>
            <Card>
                <Tabs onChange={(activeKey: any) => { onTabChange(activeKey) }}>
                    <TabPane tab={<span className="tab-title">Store</span>} key={AffiliateTabType.ActiveAffiliates}>
                        <>
                            <Row className="affiliate-item">
                                <span className="new-affiliate-button">
                                    <ButtonComponent name="New" {...{ onClick: openNewAffiliateModal, type: "primary", shape: "round", icon: <PlusOutlined /> }} />
                                </span>
                                <span className="searchbox">
                                    <CSVLink
                                        filename={`marketplace-list.csv`}
                                        title={`Download CSV`}
                                        data={csvMarketplaces} >
                                        <ButtonComponent
                                            className="export-button"
                                            shape="round"
                                            disabled={csvMarketplaces.length == 0}
                                            icon={<DownloadOutlined />}
                                            name={`Download CSV`} />
                                    </CSVLink>
                                    <SearchComponent {...{ className: "search-input", maxLength: maxSearchLength, size: "large", name: "searchValue", placeholder: "Search something...", onSearch: onSearchSubmit }} />
                                </span>
                            </Row>
                            <Table
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                                pagination={{
                                    total: props.marketplaces?.totalItemCount, current: props.marketplaces?.pageNumber,
                                    pageSize: props.marketplaces?.pageSize,
                                    showSizeChanger: false,
                                }}
                                onRow={(record: MarketplaceModel) => ({
                                    onClick: () => {
                                        onClickAffiliate(record.id)
                                    },
                                })}
                                sortDirections={['ascend', 'descend', 'ascend']}
                                scroll={{ x: 1200 }}
                                onChange={(onChangeEvent)}
                                columns={columns} dataSource={props.marketplaces?.items} />
                        </>
                    </TabPane>
                    <TabPane tab={<span className="tab-title">Deleted stores</span>} key={AffiliateTabType.DeletedAffiliates}>
                        <>
                            <Row className="affiliate-item deletion-items">
                                <span className="searchbox">
                                    <CSVLink
                                        filename={`marketplace-list.csv`}
                                        title={`Download CSV`}
                                        data={[]} >
                                        <ButtonComponent
                                            className="export-button"
                                            shape="round"
                                            icon={<DownloadOutlined />}
                                            name={`Download CSV`} />
                                    </CSVLink>
                                    <SearchComponent {...{ className: "search-input", maxLength: maxSearchLength, size: "large", name: "searchValue", placeholder: "Search something...", onSearch: onSearchSubmit }} />
                                </span>
                            </Row>

                            <Table
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                                pagination={{
                                    total: props.marketplaces?.totalItemCount, current: props.marketplaces?.pageNumber,
                                    pageSize: props.marketplaces?.pageSize,
                                    showSizeChanger: false,
                                }}
                                onRow={(record: MarketplaceModel) => ({
                                    onClick: () => {
                                        onClickAffiliate(record.id)
                                    },
                                })}
                                sortDirections={['ascend', 'descend', 'ascend']}
                                scroll={{ x: 1200 }}
                                onChange={(onChangeEvent)}
                                columns={columns} dataSource={props.marketplaces?.items} />

                        </>
                    </TabPane>
                </Tabs>


                {addNewAffiliateMode &&
                    <AddNewAffiliateComponent {...{ isVisible: addNewAffiliateMode, changeVisibility: closeAddNewModal, affiliateCategory: props.affiliateCategory, marketplacePlatform: props.marketplacePlatform }}></AddNewAffiliateComponent>
                }
            </Card>
        </Spin>
    </>
}

const mapStateToProps = ({ marketplaces, allMarketplaces }: AppState) =>
({
    marketplaces: marketplaces,
    allMarketplaces: allMarketplaces
});

export default connect(mapStateToProps, {
    updateMarketplacesInStoreAction,
    updateAllMarketplacesInStoreAction,
    updateMarketplaceCategoriesInStoreAction
})(AffiliateListComponent);