import { BankOutlined, CheckCircleFilled, CheckOutlined, ClockCircleOutlined, CloseOutlined, DeleteFilled, DollarCircleOutlined, EditFilled, ExclamationCircleFilled, HourglassOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { CashBackStatus } from "../../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";
import { UserStatus } from "../../../../core/models/dashboard/users/user";
import "../../../../styles/components/cashbackStatus.less"
export interface CashbackStatusProps {
    status: CashBackStatus
}

const CashbackStatusComponent: React.FC<CashbackStatusProps> = props => {

    return (
        <>
            {props.status == CashBackStatus.Available &&
                (
                    <Tag className="cashback-available" key={0}>
                        <DollarCircleOutlined /> Available
                    </Tag>
                )
            }
            {props.status == CashBackStatus.Collected &&
                (
                    <Tag className="cashback-collected" key={1}>
                        <CheckOutlined /> Collected
                    </Tag>
                )
            }
            {props.status == CashBackStatus.Processing &&
                (
                    <Tag className="cashback-processing" key={2}>
                        <SyncOutlined /> Processing
                    </Tag>
                )
            }
            {props.status == CashBackStatus.Canceled &&
                (
                    <Tag className="cashback-canceled" key={2}>
                        <CloseOutlined /> Canceled
                    </Tag>
                )
            }
            {props.status == CashBackStatus.Claimed &&
                (
                    <Tag className="cashback-pending" key={2}>
                        <ClockCircleOutlined /> Pending
                    </Tag>
                )
            }
            {props.status == CashBackStatus.Expired &&
                (
                    <Tag className="cashback-expired" key={2}>
                        <HourglassOutlined /> Expired
                    </Tag>
                )
            }

        </>
    )
}

export default CashbackStatusComponent;