import { AllMarketplacePurchaseByMarketplaceInStoreAction, GET_ALL_PURCHASE_MARKETPLACE_ERROR, GET_ALL_PURCHASE_MARKETPLACE_SUCCESS } from '../../../actions/dashboard/marketplacePurchase/updateAllMarketplacePurchaseByMarketplaceInStore';
import { MarketplacePurchase } from './../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel';


export const updateAllMarketplacePurchaseByMarketplaceInStoreReducer = (state: Array<MarketplacePurchase> = [], action: AllMarketplacePurchaseByMarketplaceInStoreAction) => {
    switch (action.type) {
        case GET_ALL_PURCHASE_MARKETPLACE_SUCCESS:
            return action.allMarketplacePurchases;
        case GET_ALL_PURCHASE_MARKETPLACE_ERROR:
            return [];
        default:
            return [...state];
    }
}