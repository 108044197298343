import { updateSubscriptionStatisticsInStoreReducer } from "./statistics/updateSubscriptionStatisticsInStoreReducer";
import { updateSubscriptionPlansInStoreReducer } from "./subscriptionPlans/updateSubscriptionPlansInStoreReducer";
import { updateAllUserSubscriptionsInStoreReducer } from "./userSubscriptions/updateAllUserSubscriptionsInStoreReducer";
import { updateUserSubscriptionsInStoreReducer } from "./userSubscriptions/updateUserSubscriptionsInStoreReducer";

const subscriptionReducer = {
    updateSubscriptionStatisticsInStoreReducer,
    updateUserSubscriptionsInStoreReducer,
    updateAllUserSubscriptionsInStoreReducer,
    updateSubscriptionPlansInStoreReducer
}

export default subscriptionReducer;