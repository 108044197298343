import { DeleteFilled, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Divider, Modal, Popover, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import marketplaceAction from "../../../../../core/actions/dashboard/marketplace";
import { AppState } from "../../../../../core/commons/reduxStore";
import { AffiliateDetailsModel } from "../../../../../core/models/dashboard/marketplace/affiliateDetails";
import { AffiliateStatus, CSVMarketplaceMarketplaceModel, MarketplaceSearch } from "../../../../../core/models/dashboard/marketplace/affiliatesModel";
import marketplace from "../../../../../core/services/dashboard/marketplace";
import StatusItemComponent, { Status } from "../../../../common/components/dashboard/StatusItemComponent";
import ButtonComponent from "../../../../common/components/dataDisplay/Button";
import { commandRequest } from "../../../../common/requestUtils/requestUtils";
import { error, s3Url, success } from "../../../../common/utils/Utils";
import EditAffiliateComponent from "../edit/EditAffiliateComponent";
import affiliateDetailsResource from "../Resource";
import detailsResource from "./Resource";

const { updateAffiliateDetailsInStoreAction } = marketplaceAction;

export interface DetailsComponentProps {
    affiliateDetails: AffiliateDetailsModel;
}


const DetailsComponent: React.FC<DetailsComponentProps> = (props: any) => {
    const history = useHistory();
    const { affiliateDetails } = props;
    const [editAffiliateMode, setEditAffiliate] = useState<boolean>(false);

    const initSearchParams: MarketplaceSearch = {
        pageNumber: 1,
        pageSize: 5,
        searchValue: "",
        orderDescending: false,
        sortColumn: "Name",
        isDeleted: false
    }

    const closeAddNewModal = () => {
        setEditAffiliate(false);
        props.updateAffiliateDetailsInStoreAction(affiliateDetails.id, null, onGetAffiliateDetailsSuccess, onGetAffiliateDetailsFailure);
    };

    const onGetAffiliateDetailsSuccess = () => {
        success(affiliateDetailsResource.success);
    }

    const onGetAffiliateDetailsFailure = () => {
        error(affiliateDetailsResource.error);
    }

    const openEditAffiliateModal = () => {
        setEditAffiliate(true);
    }

    const delteAffiliateCommand = () => {
        commandRequest(() => marketplace.deleteMarketplace(props.affiliateDetails.id))
            .then((item) => {
                history.push(`/admin/marketplace`);

            }, (reason: any) => {
                error(reason);
            })
            .catch(err => {
            })
    }

    const deleteAffiliate = () => {
        return (
            Modal.confirm({
                title: `Are you sure you want to delete "${props.affiliateDetails.name}"?`,
                icon: <DeleteFilled className={"delete-icon-modal"} />,
                content: <p>{detailsResource.deleteAffiliateContentRow1}<br /><br /> {detailsResource.deleteAffiliateContentRow2}</p>,
                okText: 'Delete',
                cancelText: 'Cancel',
                className: "delete-modal",
                onOk() {
                    delteAffiliateCommand();
                },
                onCancel() {
                },
            })
        )
    }

    const unableToDeleteContent = () => {
        return (
            <p style={{ maxWidth: '320px' }}>This store has processing purchases/cashback. We recommend changing its status to <b>‘Not active’</b> and come back when all purchases/cashback have been processed. <a onClick={openEditAffiliateModal}>Change status</a> </p>
        )
    }

    return (
        <>
            <Row className="row-card-item">
                <Col span={12} >
                    <span className="affiliate-header-row">
                        <span className="icon-item">{props.affiliateDetails.iconUrl ?
                            <><img className="icon" src={`${s3Url}${props.affiliateDetails.iconUrl}`}></img> </>
                            : ('')}</span>
                        <span className="name-details">
                            <span className="name-item"> {affiliateDetails.name}</span>
                            <span className="details-item">
                                <>
                                    <span>
                                        {affiliateDetails.isDeleted ?
                                            <StatusItemComponent {...{ status: Status.Deleted }}></StatusItemComponent>
                                            : (
                                                affiliateDetails.status === AffiliateStatus.Paused
                                                    ? <StatusItemComponent {...{ status: Status.Paused }}></StatusItemComponent>
                                                    : <StatusItemComponent {...{ status: Status.Active }}></StatusItemComponent>
                                            )
                                        }
                                    </span>
                                </>
                                <Divider type="vertical" ></Divider>
                                {props.affiliateDetails.category ?
                                    <><img className="icon" src={`${s3Url}${props.affiliateDetails.category.iconUrl}`}></img>
                                        {props.affiliateDetails.category.name} </>
                                    : ('')}
                            </span>
                        </span>
                    </span>
                </Col>
                <Col span={12} >
                    <span className="affiliate-status-action">
                        {!props.affiliateDetails.isDeleted &&
                            <>
                                <span className="button-actions">
                                    <ButtonComponent {...{ onClick: openEditAffiliateModal, name: `${detailsResource.edit}`, type: "primary", className: "edit-affiliate-button", icon: <EditOutlined /> }}  ></ButtonComponent>
                                </span>

                                <span className="button-actions">
                                    {props.affiliateDetails.hasPurchaseProcessing ?

                                        <Popover placement="bottomLeft" content={unableToDeleteContent} title={<b>{detailsResource.unableToDelete}</b>} trigger="hover">
                                            <ButtonComponent   {...{ type: "primary", name: `${detailsResource.delete}`, className: "delete-affiliate-button disabled", icon: <DeleteOutlined /> }} />
                                        </Popover>
                                        :
                                        <ButtonComponent   {...{ onClick: deleteAffiliate, type: "primary", name: `${detailsResource.delete}`, className: "delete-affiliate-button", icon: <DeleteOutlined /> }} />
                                    }
                                </span>
                            </>
                        }
                    </span>
                </Col>
                <Divider></Divider>
            </Row>

            <Row className="details-row-card-item">
                <Row className="gutter-row" gutter={[48, 32]}>
                    <Col span={8}>
                        <h5 className="affiliate-field-item">{detailsResource.name}</h5>
                        <h6 className="affiliate-field-value">{props.affiliateDetails.name} </h6>
                        <h5 className="affiliate-field-item">{detailsResource.accessUrl}</h5>
                        <h6 className="affiliate-field-value">
                            {props?.affiliateDetails.accessUrl ?
                                <Popover content={props?.affiliateDetails.accessUrl} title={`${detailsResource.accessUrl}`} trigger="hover">
                                    <a target="_blank" href={`${props?.affiliateDetails.accessUrl}`}>{`${props?.affiliateDetails.accessUrl.substring(0, 30)}...`}</a>

                                </Popover>
                                : ('-')}
                        </h6>
                    </Col>
                    <Col span={8}>
                        <h5 className="affiliate-field-item">{detailsResource.platform}</h5>
                        <h6 className="affiliate-field-value">{props.affiliateDetails.platform ?
                            <><img className="icon" src={`${s3Url}${props.affiliateDetails.platform.iconUrl}`}></img>
                                {props.affiliateDetails.platform.name} </>

                            : ('-')} </h6>
                        <h5 className="affiliate-field-item">{detailsResource.secondAccessUrl}</h5>
                        <h6 className="affiliate-field-value">
                            {props?.affiliateDetails.secondAccessUrl ?
                                <Popover content={props.affiliateDetails.secondAccessUrl} title={`${detailsResource.accessUrl}`} trigger="hover">
                                    <a target="_blank" href={`${props.affiliateDetails.secondAccessUrl}`}>{`${props.affiliateDetails.secondAccessUrl.substring(0, 30)}...`}</a>

                                </Popover>
                                : ('-')}
                        </h6>
                    </Col>
                    <Col span={8}>
                        <h5 className="affiliate-field-item">{detailsResource.category}</h5>
                        <h6 className="affiliate-field-value">{props.affiliateDetails.category ?
                            <><img className="icon" src={`${s3Url}${props.affiliateDetails.category.iconUrl}`}></img>
                                {props.affiliateDetails.category.name} </>
                            : ('-')} </h6>
                        <h5 className="affiliate-field-item">{detailsResource.status}</h5>
                        <h6 className="affiliate-field-value"><span>

                            {affiliateDetails.isDeleted ?
                                <StatusItemComponent {...{ status: Status.Deleted }}></StatusItemComponent>
                                : (
                                    affiliateDetails.status === AffiliateStatus.Paused
                                        ? <StatusItemComponent {...{ status: Status.Paused }}></StatusItemComponent>
                                        : <StatusItemComponent {...{ status: Status.Active }}></StatusItemComponent>
                                )
                            }</span> </h6>
                        <Row>
                            <Col span={12}>
                                <h5 className="affiliate-field-item date">{detailsResource.createdAt}</h5>
                                <h6 className="affiliate-field-value">{moment(new Date(props.affiliateDetails.createdAt)).format("MM-DD-YYYY")}</h6>
                            </Col>
                            <Col span={12}>
                                <h5 className="affiliate-field-item date">{detailsResource.lastModifiedAt}</h5>
                                <h6 className="affiliate-field-value">{props.affiliateDetails.lastModifiedAt ? moment(new Date(props.affiliateDetails?.lastModifiedAt)).format("MM-DD-YYYY") : ("-")}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Row>

            {editAffiliateMode &&
                <EditAffiliateComponent {...{ isVisible: editAffiliateMode, changeVisibility: closeAddNewModal }}></EditAffiliateComponent>
            }
        </>
    )
}

const mapStateToProps = ({ affiliateDetails, affiliateCategory, marketplacePlatform }: AppState) =>
({
    affiliateDetails: affiliateDetails,
    affiliateCategory: affiliateCategory,
    marketplacePlatform: marketplacePlatform
});

export default connect(mapStateToProps, {
    updateAffiliateDetailsInStoreAction
})(DetailsComponent);