import { GET_MARKETPLACEPURCHASE_ERROR, GET_MARKETPLACEPURCHASE_SUCCESS, MarketplacePurchaseByUserInStoreAction } from './../../../actions/dashboard/marketplacePurchase/updateMarketplacePurchaseByUserInStore';
import { MarketplacePurchaseModel } from './../../../models/dashboard/marketplacePurchase/marketplacePurchaseModel';


export const updateMarketplacePurchaseByUserInStoreReducer = (state: MarketplacePurchaseModel| null= null, action: MarketplacePurchaseByUserInStoreAction) => {
    switch (action.type) {
        case GET_MARKETPLACEPURCHASE_SUCCESS:
            return { ...state, ...action?.userMarketplacePurchase }
        case GET_MARKETPLACEPURCHASE_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
