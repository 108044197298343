export const MainPaths = {
    LOGIN_PATH: "/login",
    FORGOT_PASSWORD_PATH: "/forgot-password",
    RESET_PASSWORD_LOGIN_PATH: "/reset-password/:token",
    DASHBOARD_PATH: "/admin",
    REDIRECT_PATH: "/"
}

export const DashboardPaths = {
    HOME_PATH: "/admin/home",
    USER_PATH: "/admin/users",
    DEVICE_PATH: "/admin/devices",
    TRANSACTION_PATH: "/admin/transactions",
    AFFILIATE_PATH: "/admin/marketplace",
    NOTIFICATION_PATH: "/admin/notification",
    SUBSCRIPTION_PATH: "/admin/subscriptions",
    WITHDRAW_PATH: "/admin/withdraw"

}

export const UserPaths = {
    USER_DETAILS_PATH: `${DashboardPaths.USER_PATH}/user-details/:id`
}
export const AffiliatesSubPaths = {
    AFFILIATE_DETAILS_PATH : `${DashboardPaths.AFFILIATE_PATH}/details/:id`,
    AMAZON_CASHBACK_REQUEST: `${DashboardPaths.AFFILIATE_PATH}/amazon-cashback-requests`

}


export const RoutePaths =
    {
        ...MainPaths,
        ...DashboardPaths,
        ...UserPaths,
        ...AffiliatesSubPaths
    }
