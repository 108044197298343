import React, {useState} from 'react';
import {Button, Card, Divider, Drawer, Input, List, message, Row, Space, Spin} from "antd";
import {
    NotificationTemplateData,
    NotificationTemplateType
} from "../../../../core/models/dashboard/notification/notification";
import {Form, useFormik} from "formik";
import * as Yup from "yup";
import TextArea from "antd/es/input/TextArea";
import {DeleteOutlined, FileOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {
    addNotificationTemplateDataInStore,
    updateNotificationTemplateDataInStore,
    deleteNotificationTemplatesInStore
} from '../../../../core/actions/dashboard/notification/updateNotificationTemplatesInStore'

interface NewNotificationTemplateValues {
    title: string;
    description: string;
    type: NotificationTemplateType;
    id: string | null
}

export const NotificationTemplateAddOrEditComponent: React.FC<any> = props => {
    const [isLoading, setLoading] = useState(false);
    const initialValues: NewNotificationTemplateValues = props.isEdit ? {
        title: props.data.title,
        description: props.data.description,
        type: props.data.type,
        id: props.data.id
    } : {
        title: "",
        description: "",
        type: NotificationTemplateType.Information,
        id: null
    };

    const formValidator = () =>
        Yup.object({
            title: Yup.string().required("Template title is required"),
            description: Yup.string().required("Template description is required"),
        });

    const onClose = () => {
        props.setVisibility(false);
    }

    const postNewNotificationTemplate = () => {
        const newNotificationTemplate: NotificationTemplateData = {
            title: formik.values.title,
            tag: "",
            description: formik.values.description,
            type: formik.values.type,
            id: formik.values.id,
            createdAt: null,
            lastModifiedAt: null
        };
        const execute = !props.isEdit ?
            addNotificationTemplateDataInStore : updateNotificationTemplateDataInStore;
        execute(newNotificationTemplate, onSuccess, onFailure);
        setLoading(true);
    }

    const deleteNotificationTemplate = () => {
        const id = formik.values.id;
        deleteNotificationTemplatesInStore(id, onDeleteSuccess, onFailure);
        setLoading(true);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: formValidator,
        onSubmit: postNewNotificationTemplate,
    })

    const onFailure = (err: any) => {
        if(err)
            message.error(err);
        else
            message.error(`Couldn't complete action`);
        setLoading(false);
    }

    const onSuccess = () => {
        if (!props.isEdit)
            message.success('Notification template added successfully!');
        else
            message.success('Notification template updated successfully!');

        setLoading(false);
        props.refresh(1);
        onClose();
    }

    const onDeleteSuccess = () => {
        message.success('Notification template deleted successfully!');
        setLoading(false);
        props.refresh(1);
        onClose();
    }

    return (
        <Space>
            <Drawer
                title={
                    <>{
                        !props.isEdit ?
                            <span className="modal-header-title">Add new notification template</span> :
                            <span className="modal-header-title">Edit notification template</span>
                    }

                    </>
                }
                placement={"right"}
                closable={false}
                onClose={onClose}
                visible={props.isVisible}
                key={"right"}
                width={600}
                bodyStyle={{paddingBottom: 80}}
            >

                <Spin tip="Loading..." spinning={isLoading}>
                    <Row className="row-item">
                        <span className="item-title">Title</span>
                        <Input type="text" onChange={formik.handleChange} value={formik.values.title} name="title"
                               placeholder="Type something..."
                               className="item-input" maxLength={100}/>
                        {formik.errors.title && formik.touched.title && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.title}</div>
                            </div>
                        )}
                    </Row>

                    <Divider/>

                    <Row className="row-item">
                        <span className="item-title">Description</span>
                        <TextArea onChange={formik.handleChange} name="description" value={formik.values.description}
                                  placeholder="Type something..."
                                  className="item-input" maxLength={9000} rows={12}/>
                        {formik.errors.description && formik.touched.description && (
                            <div
                                className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{formik.errors.description}</div>
                            </div>
                        )}
                    </Row>

                    <Card style={{marginTop: '20px'}}>
                        <Row>
                            <h6>Variables:</h6>
                        </Row>
                        <Row>
                            <ul
                                style={{paddingLeft: '25px'}}>
                                <li style={{padding: '5px'}}>
                                    {'[fullname] => is replaced with the actual name of the recipient'}
                                </li>
                                <li style={{padding: '5px'}}>
                                    {'[email] => is replaced with the actual email of recipient'}
                                </li>
                            </ul>
                        </Row>
                    </Card>

                    <Row className="row-item" style={{justifyContent: 'flex-end'}}>
                        <Button onClick={() => formik.handleSubmit()} className="addNewPromotion-button" type="primary"
                                shape="round" icon={<FileOutlined/>}>
                            Save
                        </Button>
                        {props.isEdit ?
                            <Button style={{marginLeft: '10px'}} onClick={() => deleteNotificationTemplate()} className="addNewPromotion-button" type="primary"
                                                danger
                                                shape="round" icon={<DeleteOutlined/>}>
                            Delete
                        </Button> : null}
                    </Row>
                </Spin>
            </Drawer>
        </Space>
    )
}