import React, {useEffect} from "react";
import {Card, Col, Row} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";
import {AppState} from "../../../../core/commons/reduxStore";
import {connect} from "react-redux";
import {getMarketplaceProgramReserveInStoreAction} from "../../../../core/actions/dashboard/marketplacePurchase/getMarketplaceProgramReserveInStore";

const MarketplaceProgramReserve : React.FC<any> = (props: any) => {
    useEffect(() => {
        props.getMarketplaceProgramReserveInStoreAction();
    }, [])

    return (
        <Col className="card-column" span={24}>
            <Card className="card-item inline" bordered={false}>
                <Row className=" statistics">
                    <div className="centered-column">
                        <UnorderedListOutlined className="icon-styles circle-icon"/>
                    </div>
                    <div className="stats-details margin-left">
                        <h6 className="title-item">Marketplace Reserve</h6>
                        <span
                            className="value-item inline-block">{props.marketplaceProgramReserve?.amazon || '-'}</span>
                    </div>
                </Row>
            </Card>
        </Col>
    );
}

const mapStateToProps = ({marketplaceProgramReserve} : AppState) => ({
     marketplaceProgramReserve
});

export default connect(mapStateToProps, {
    getMarketplaceProgramReserveInStoreAction
})(MarketplaceProgramReserve);