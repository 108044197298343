import {
    GET_USER_DETAILS_ERROR,
    GET_USER_DETAILS_SUCCES,
    UPDATE_USERS_LEGAL_NAME_FAILURE,
    UPDATE_USERS_LEGAL_NAME_SUCCESS,
    USER_DELETED_FAILURE,
    USER_DELETED_SUCCESS,
    USER_DELETION_CANCELLED_FAILURE,
    USER_DELETION_CANCELLED_SUCCESS,
    USER_SCHEDULED_FOR_DELETION_SUCCESS,
    UserDetailsInStoreAction
} from '../../../actions/dashboard/users/updateUserDetailsInStore';
import {UserDetailsModel} from '../../../models/dashboard/users/userDetails';


export const updateUserDetailsInStoreReducer = (state: UserDetailsModel | null = null, action: UserDetailsInStoreAction) => {
    switch (action.type) {
        case GET_USER_DETAILS_SUCCES:
        case USER_SCHEDULED_FOR_DELETION_SUCCESS:
        case USER_DELETION_CANCELLED_SUCCESS:
        case UPDATE_USERS_LEGAL_NAME_SUCCESS:
            return { ...state, ...action.details }
        case USER_DELETION_CANCELLED_FAILURE:
        case USER_DELETED_FAILURE:
        case UPDATE_USERS_LEGAL_NAME_FAILURE:
            return { ...state, ...action.details }
        case GET_USER_DETAILS_ERROR:
        case USER_DELETED_SUCCESS:
            return {...state};
        default:
            return {...state};
    }
}