import { ArrowRightOutlined, BankOutlined } from "@ant-design/icons";
import React from "react";
import { s3DestinationMoveMoneyImgUrl, s3FromMoveMoneyImgUrl } from "../../utils/Utils";

export interface MoveMoneyTypeComponentProps {
    type: number
}

const MoveMoneyTypeComponent: React.FC<MoveMoneyTypeComponentProps> = props => {
    return <>
        {props.type == 0 &&
            <>
                <span className="move-money-type">
                    <img src={s3FromMoveMoneyImgUrl} />
                    <ArrowRightOutlined className="arrow-destination" />
                    <img src={s3DestinationMoveMoneyImgUrl} className="destination" />
                </span>
                <span>Goal to Goal</span>
            </>
        }
        {props.type == 1 &&
            <>
                <span className="move-money-type">
                    <img src={s3FromMoveMoneyImgUrl} />
                    <ArrowRightOutlined className="arrow-destination" />
                    <BankOutlined className="destination" />
                </span>
                <span>Goal to Bank</span>
            </>
        }
        {props.type == 2 &&
            <>
                <span className="move-money-type">
                    <img src={s3FromMoveMoneyImgUrl} />
                    <ArrowRightOutlined className="arrow-destination" />
                    <BankOutlined className="destination" />
                </span>
                <span>Goal to Bank</span>
            </>
        }
    </>
}
export default MoveMoneyTypeComponent;