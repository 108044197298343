export interface AffiliateModel {
  id: string;
  name: string;
  iconUrl: string;
  accessUrl: string;
  secondAccessUrl: string;
  cashBackPercentage: number;
  cashBackLabel: string;
  order:number;
  status:AffiliateStatus;
}

export interface Platform {
  id: string;
  name: string;
  platformStatus: number;
  iconUrl: string;
}

export interface Category {
  id: string;
  name: string;
  iconUrl: string;
}

export interface MarketplacePurchaseDetails {
  totalMarketplacePurchases: number;
  totalCashBackAmount: number;
  totalMarketplacePurchaseAmount: number;
}

export interface MarketplaceModel {
  id: string;
  name: string;
  iconUrl: string;
  accessUrl: string;
  secondAccessUrl: string;
  cashBackPercentage: number;
  guacCashBackPercentage: number;
  isDeleted: boolean;
  platform: Platform;
  category: Category;
  status:AffiliateStatus;
  marketplacePurchaseDetails: MarketplacePurchaseDetails;
}

export interface GetMarketplaceModel {
  items: MarketplaceModel[];
  totalItemCount: number;
  pageCount: number;
  pageSize: number;
  pageNumber: number;
}

export interface MarketplaceSearch {
 searchValue:string;
 pageNumber:number;
 pageSize:number;
 orderDescending:boolean;
 sortColumn:string;
 isDeleted:boolean;
}

export enum AffiliateStatus
{
    Active, 
    Paused
}

export interface CSVMarketplaceMarketplaceModel {
  Name: string;
  AccessUrl: string;
  SecondAccessUrl: string;
  CashBackPercentage:string;    
  GuacCashBackPercentage:string;
  Platform: string;
  Category: string;
  Status: string;  
  TotalMarketplacePurchases:string;
  TotalCashBackAmount: string;
  TotalMarketplacePurchaseAmount:string;  
}

// add new marketplace 
export interface AddNewMarketplaceModel {
  name :string;
  iconUrl : string;
  accessUrl: string;
  secondAccessUrl:string;
  categoryId:string;
  platformId:string;
  description:string;
  promotions:Array<OtherPercentageCommissionModel>

}
export interface OtherPercentageCommissionModel {
  cashBackPercentage:number;
  guacCashBackPercentage:number;
  promotionName:string;
}


export interface OrderIndex {
  oldIndex: number,
  newIndex: number
}

export interface Dragable {
  className: any;
  style: any;
  restProps: any;
}