import { GetListOfUserSubscriptions, UserSubscriptions } from './../../../../models/dashboard/subscriptions/subscriptions';
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../../commons/reduxStore";
import getAllUserSubscriptions from '../../../../services/dashboard/subscriptions/getAllUsersSubscriptions';

export const GET_ALL_USERSUBSCRIPTIONS_SUCCESS = "GET_ALL_USERSUBSCRIPTIONS_SUCCESS";
export const GET_ALL_USERSUBSCRIPTIONS_ERROR = "GET_ALL_USERSUBSCRIPTIONS_ERROR";
var initData : Array<UserSubscriptions> = [];

export interface AllUserSubscriptionsInStoreAction {
    type: typeof GET_ALL_USERSUBSCRIPTIONS_SUCCESS | typeof GET_ALL_USERSUBSCRIPTIONS_ERROR,
    allUserSubscriptions: Array<UserSubscriptions>
}

export const updateAllUserSubscriptionsInStoreAction = ()
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getAllUserSubscriptions())
            .then((res: Array<UserSubscriptions>) => {
                dispatch({
                    type: GET_ALL_USERSUBSCRIPTIONS_SUCCESS,
                    allUserSubscriptions: res
                } as AllUserSubscriptionsInStoreAction);
            })
            .catch((err) => {
                dispatch({
                    type: GET_ALL_USERSUBSCRIPTIONS_ERROR,
                    allUserSubscriptions: initData
                } as AllUserSubscriptionsInStoreAction);
            })
    }