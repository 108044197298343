import {  GET_REJECTED_CASHBACK_REQUEST_ERROR, GET_REJECTED_CASHBACK_REQUEST_SUCCESS, RejectedCashbackRequestInStoreAction
} from "../../../../actions/dashboard/marketplace/amazon/updateRejectedCashbackRequestInStore";
import {CashbackRequestsCheckedPaginated} from "../../../../models/dashboard/marketplace/marketplaceCashbackRequestModel";

export const updateRejectedRequestInStoreReducer = (state:  CashbackRequestsCheckedPaginated| null, action: RejectedCashbackRequestInStoreAction) => {
    switch (action.type) {
        case GET_REJECTED_CASHBACK_REQUEST_SUCCESS:
            return action?.rejectedCashbackRequest
        case GET_REJECTED_CASHBACK_REQUEST_ERROR:
            return {};
        default:
            return {...state};
    }
}
