import { ButtonShape, ButtonSize } from "antd/lib/button";
import { ButtonHTMLType } from "antd/lib/button/button";
import React, { ReactNode } from "react";
import { Button as AntButton } from "antd";

type IconPosition = "left" | "right";

export interface ButtonProps {
  disabled?: boolean;
  icon?: React.ReactNode;
  customIcon?: ReactNode;
  iconPosition?: IconPosition | undefined;
  shape?: ButtonShape;
  size?: ButtonSize;
  type?: "link" | "default" | "ghost" | "primary" | "dashed" | "text";
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseUp?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  htmlType?: ButtonHTMLType;
  loading?: boolean;
  children?: ReactNode;
  name: string;
}

class ButtonComponent extends React.Component<ButtonProps> {

  render() {
    const { icon, iconPosition, children, customIcon, ...props } = this.props;
     return (
      <AntButton icon={icon}  {...props}>{props.name}</AntButton>
    );
  }
}

export default ButtonComponent;