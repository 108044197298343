import React, {useEffect} from "react";
import {Card, Col, Row} from "antd";
import StatisticsItemComponent from "../../../common/components/dataDisplay/StatisticItem";
import {CashbackStatisticsChartColor} from "../../../common/utils/ChartColors";
import {connect} from "react-redux";
import {AppState} from "../../../../core/commons/reduxStore";
import {getCashbackStatisticsInStoreAction} from "../../../../core/actions/dashboard/marketplacePurchase/getCashbackStatisticsInStore";

const CashbackStatisticsCard: React.FC<any> = (props: any) => {

    useEffect(() => {
        props.getCashbackStatisticsInStoreAction()
    }, [])

    const cashbackStatisticLabels = [{
            title: 'Collected Cashbacks',
            badgeColor: (CashbackStatisticsChartColor.Collected)
        },
        {
            title: 'Claimed Cashbacks',
            badgeColor: (CashbackStatisticsChartColor.Claimed)

        },
        {
            title: 'Rejected Cashbacks',
            badgeColor: (CashbackStatisticsChartColor.Canceled)

        },
        {
            title: 'Expired Cashbacks',
            badgeColor: (CashbackStatisticsChartColor.Canceled)
        }];

    console.log(props);
    return (
        <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
            <Col className="card-column" span={24}>
                <Card className="card-item" bordered={false}>
                    <Row className="row-tab-content home-row-item vcenter">
                        <Row className="home-row-item vcenter">
                            {
                                cashbackStatisticLabels.map((c, i) =>
                                    <Col md={6} className="col-padding-left">
                                        <Row className="home-row-item vcenter statistics" style={{marginRight: '25px'}}>
                                            <StatisticsItemComponent {...{
                                                title: c.title,
                                                value: `$${props?.cashbackStatistics?.cashbacks[i]?.amount || '-'}`,
                                                secondValue: `${props?.cashbackStatistics?.cashbacks[i]?.count || '-'}`,
                                                badgeColor: c.badgeColor,
                                            }}/>
                                        </Row>
                                    </Col>
                                )
                            }
                        </Row>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
};
const mapStateToProps = ({cashbackStatistics}: AppState) =>
    ({
        cashbackStatistics: cashbackStatistics
    });
export default connect(mapStateToProps, {
    getCashbackStatisticsInStoreAction
})(CashbackStatisticsCard)