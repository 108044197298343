import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from "../../../../commons/reduxStore";
import getAffiliates from '../../../../services/dashboard/marketplace/affiliates/getAffiliates';
import { AffiliateModel } from '../../../../models/dashboard/marketplace/affiliatesModel';

export const GET_AFFILIATES_SUCCESS= "GET_AFFILIATES_SUCCESS";
export const GET_AFFILIATES_ERROR= "GET_AFFILIATES_ERROR";

export interface AffiliatesInStoreAction {
    type: typeof GET_AFFILIATES_SUCCESS | typeof GET_AFFILIATES_ERROR,
    affiliates: Array<AffiliateModel>,
}

export const updateAffiliatesInStoreAction  =
(affiliates:  Array<AffiliateModel>,
 onSuccess: Function,
 onFailure: Function)
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    queryRequest(()=> getAffiliates())
    .then( (res : Array<AffiliateModel>)=>{
        dispatch({
            type: GET_AFFILIATES_SUCCESS,
            affiliates: res
        } as AffiliatesInStoreAction);
        onSuccess();
    })
    .catch((err) => {
        dispatch({
            type: GET_AFFILIATES_ERROR,
            affiliates: affiliates
        } as AffiliatesInStoreAction);
        onFailure();
    });
}