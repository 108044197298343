import { Alert, Button, Checkbox, Col, Input, Layout, Popover, Row } from "antd";
import { CheckCircleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { ReactNode, useState } from "react";
import { connect } from "react-redux";
import { Content } from "antd/lib/layout/layout";
import GuacHeader from "../GuacHeader";
import services from "../../../core/services";
import { commandRequest } from "../../common/requestUtils/requestUtils";
import GuacFooter from "../GuacFooter";
import { RoutePaths } from "../../routes";
import { useParams } from "react-router-dom";
import { lowercaseRegx, numberRegx, specialCharRegx, uppercaseRegx } from "../../common/utils/Regex";
import PasswordPopoverContent from "../PasswordPopOverContent";
import { UserContext } from "../../common/userContext/userContext";
import queryString from 'query-string';

interface TokenProps {
    token: string;
}

interface ContentProps {
    nrOfcharsIcon: boolean;
    lowercaseIcon: boolean;
    uppercaseIcon: boolean;
    oneNumberIcon: boolean;
    specialCharIcon: boolean;
}

const { resetPassword } = services;

interface FormValues {
    newPassword: string;
    confirmNewPassword: string;
}

const initialValues: FormValues = {
    newPassword: "",
    confirmNewPassword: ""
};

const formValidator = () =>
    Yup.object({
        newPassword: Yup.string().required('Password is required').min(6, "Password should be 6 chars minimum"),
        confirmNewPassword: Yup.string().required('Password confirmation is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords must match').min(6, "Password should be 6 chars minimum")
    });

const ResetPassword: React.FC = () => {

    let token = useParams<TokenProps>();
    let history = useHistory();

    const [content, setContent] = useState<ReactNode>(PasswordPopoverContent);
    const [isSubmitting, setSubmiting] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isPassowrdReseted, setPasswordReset] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);


    const onSubmit = async (values: FormValues) => {
        setErrors([]);

        setSubmiting(true);
        const res = await commandRequest(() => resetPassword(values.newPassword, token.token));

        if (!res.errors) {
            setSubmiting(false);
            setPasswordReset(true);
        } else {
            setSubmiting(false);
            setErrors(res.errors);
        }

    };

    let passwordValidationResult: ContentProps = {
        nrOfcharsIcon: false,
        lowercaseIcon: false,
        oneNumberIcon: false,
        specialCharIcon: false,
        uppercaseIcon: false
    };

    const calculatePasswordPopoverContent = (password: string) => {
        passwordValidationResult.oneNumberIcon = password.match(numberRegx) != null;
        passwordValidationResult.lowercaseIcon = password.match(lowercaseRegx) != null;
        passwordValidationResult.nrOfcharsIcon = password.length >= 8;
        passwordValidationResult.uppercaseIcon = password.match(uppercaseRegx) != null;

        if (passwordValidationResult.uppercaseIcon && passwordValidationResult.oneNumberIcon && passwordValidationResult.lowercaseIcon && passwordValidationResult.nrOfcharsIcon) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        if (!password) {
            setIsVisible(false);
        }

        setContent(<PasswordPopoverContent {...passwordValidationResult} />);
    };


    const onPasswordInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        let password = e.currentTarget.value;
        calculatePasswordPopoverContent(password);
    };


    return (
        <Layout>
            <Content>
                <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={formValidator}
                        onSubmit={onSubmit}>

                        {formik => (
                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <Row className="reset-password-widget">
                                    <GuacHeader />
                                    {!isPassowrdReseted &&
                                        <Row className="forgot-password-form" >
                                            <Row className="content">
                                                <p className="header-title">Reset password</p>
                                                <Row>
                                                    {errors.length > 0 && (
                                                        <Alert type="error" className="error-alert" closable message={errors[0]} showIcon />
                                                    )}
                                                </Row>
                                            </Row>
                                            <Row className="form reset-password-popup">
                                                <Popover placement="right" content={content} title="Password requirements" visible={isVisible}>
                                                    <Input.Password size="large"
                                                        id="newPassword"
                                                        name="newPassword"
                                                        className="email-input"
                                                        placeholder="New password"
                                                        onBlur={formik.handleBlur}
                                                        onChange={(e) => { formik.handleChange(e); onPasswordInputChange(e) }}
                                                        prefix={<LockOutlined className="icon-styles" />} />
                                                    {formik.errors.newPassword && (
                                                        <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">{formik.errors.newPassword}</div></div>
                                                    )}
                                                </Popover>
                                            </Row>
                                            <Row className="form">
                                                <Input.Password size="large"
                                                    id="confirmNewPassword"
                                                    name="confirmNewPassword"
                                                    className="email-input"
                                                    placeholder="Confirm new password"
                                                    onChange={formik.handleChange}
                                                    prefix={<LockOutlined className="icon-styles" />} />
                                                {formik.errors.confirmNewPassword && (
                                                    <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">{formik.errors.confirmNewPassword}</div></div>
                                                )}
                                            </Row>
                                            <Row className="form">
                                                <Button
                                                    disabled={!formValidator().isValidSync(formik.values)}
                                                    htmlType="submit" type="primary"
                                                    className={(formValidator().isValidSync(formik.values)) ? "forgot-submit-button active" : "forgot-submit-button disabled"}
                                                    block title="Save" shape="round"
                                                    size="large" loading={isSubmitting}>Save</Button>
                                            </Row>
                                        </Row>
                                    }

                                    {isPassowrdReseted &&
                                        <Row className="forgot-password-form" >
                                            <Row className="instruction-sent-form">
                                                <CheckCircleOutlined className="circle-check" />
                                                <Row className="content">
                                                    <p className="header-title">New password is saved</p>
                                                    <p className="subheader-title">
                                                        Your password has been successfully updated
                                                    </p>
                                                </Row>
                                                <Row className="button-redirect">
                                                    <Button
                                                        className="forgot-submit-button active"
                                                        title="Login"
                                                        shape="round"
                                                        block
                                                        onClick={() => { history.push(`${RoutePaths.LOGIN_PATH}`); }}
                                                        type="primary">
                                                        Login
                                                    </Button>
                                                </Row>
                                            </Row>
                                        </Row>
                                    }
                                    <GuacFooter />
                                </Row>
                            </form>
                        )}

                    </Formik>
                </Row>
            </Content>
        </Layout>
    );
}
export default connect(null, null)(ResetPassword);
