import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../../commons/reduxStore';
import { GetMarketplaceModel, MarketplaceModel, MarketplaceSearch } from "../../../../models/dashboard/marketplace/affiliatesModel";
import getAllMarketplaces from '../../../../services/dashboard/marketplace/affiliates/getAllMarketplaces';

export const GET_ALL_MARKETPLACES_SUCCESS = "GET_ALL_MARKETPLACES_SUCCESS";
export const GET_ALL_MARKETPLACES_ERROR = "GET_ALL_MARKETPLACES_ERROR";

export interface AllMarketplacesInStoreAction {
    type: typeof GET_ALL_MARKETPLACES_SUCCESS | typeof GET_ALL_MARKETPLACES_ERROR,
    allMarketplaces: Array<MarketplaceModel>
}

export const updateAllMarketplacesInStoreAction = (allMarketplaces: Array<MarketplaceModel> | [],
    deleted: boolean)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
            queryRequest(() => getAllMarketplaces(deleted))
                .then((res: Array<MarketplaceModel>) => {
                    dispatch({
                        type: GET_ALL_MARKETPLACES_SUCCESS,
                        allMarketplaces: res
                    } as AllMarketplacesInStoreAction);
                })
                .catch((err) => {
                    dispatch({
                        type: GET_ALL_MARKETPLACES_ERROR,
                        allMarketplaces: allMarketplaces
                    } as AllMarketplacesInStoreAction);
                });
        }