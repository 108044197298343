import { adminHttpClient } from "../../../commons/adminHttpClinet";
import { UserSuggestionsModel } from "../../../models/dashboard/users/user";
import { UserDetails } from "../../../models/dashboard/users/userDetails";

const searchUsers = (name: string): Promise<Array<UserSuggestionsModel>> =>{
    let requestKeyword = name
    if(!requestKeyword)
        requestKeyword = 'a';
    const encodedURI =  `/api/Admin/AdminUsers/search-users?name=${encodeURIComponent(requestKeyword)}`;
    
    return adminHttpClient.get(encodedURI);
}
export default searchUsers;