import { message } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { AffiliateStatus } from "../../../core/models/dashboard/marketplace/affiliatesModel";
import { CashBackStatus, MarketplacePurchaseType, PurchaseStatus } from "../../../core/models/dashboard/marketplacePurchase/marketplacePurchaseModel";

export const isNil = (input: any): boolean => {
  return input === null || input === undefined || input === ""
}


export const s3FromMoveMoneyImgUrl = "https://gauc-images.s3-us-west-2.amazonaws.com/move-money-from.png";
export const s3DestinationMoveMoneyImgUrl = "https://gauc-images.s3-us-west-2.amazonaws.com/move-money-destination.png";
export const s3Url = `https://gauc-images.s3-us-west-2.amazonaws.com/`;
export const rememberMeKey = "rememberMeCredentials";
export const acceptFileUpload = "image/png";
export const maxSearchLength = 50;
export const maxStringLength = 30;

export const maxInputLength = 50;

export const CurrencyNumberFormat = (input: number) => {
  // Create our number formatter.
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(input);
}

export const addToLocalStorage = (name: string, input: any) => {
  if (localStorage.getItem(name)) {
    localStorage.removeItem(name)
  }
  localStorage.setItem(name, input);
}

export const getUploadChangeFormData = (info: UploadChangeParam): FormData | null => {
  if (!info) return null;

  let formData = new FormData();
  formData.append("file", info.fileList[info.fileList.length - 1].originFileObj as any);
  return formData;
}

// export const getUploadFileFormData= (info: UploadFile<any>):FormData | null=> {
// }

export const success = (content: string) => {
  message.success(content);
};

export const error = (content: string) => {
  message.error(content);
};

export const warning = (content: string) => {
  message.warning(content);
};

// check input number, allow only pozitive numbers
export const handleKeypress = (e: any) => {
  const characterCode = e.key
  if (characterCode === 'Backspace') return

  const characterNumber = Number(characterCode)
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return
    } else if (characterNumber === 0) {
      e.preventDefault()
    }
  } else {
    e.preventDefault()
  }
}


export const setAcronymValue = (fullName: string): string => {
  if (!fullName)
    return "";
  let fullnameList = fullName.toString().split(" ");
  return (fullnameList.length === 0) ? "" :
    (fullnameList.length === 1) ? `${fullnameList[0].substring(0, 1)}`.toUpperCase() : `${fullnameList[0].substring(0, 1)}${fullnameList[1].substring(0, 1)}`.toUpperCase()
}

export const getAffiliateStatusName = (status: AffiliateStatus): string => {
  if (status == AffiliateStatus.Active)
    return 'Active'
  if (status == AffiliateStatus.Paused)
    return 'Paused'
  return "";
}

export const currencyFormatter = (value: any) => {
  if (value) {
    return new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    }).format(value);
  }
  return "";
};

export const currencyParser = (value: any) => {
  try {
    // for when the input gets clears
    if (typeof value === "string" && !value.length) {
      return "";
      // value = "0.0";
    }

    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat("en-us").format(1111).replace(/1/g, "");
    var decimal = new Intl.NumberFormat("en-us").format(1.1).replace(/1/g, "");
    var reversedVal = value.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error("ERROR:", error);
  }
};

export const getPlatformName = (platform: MarketplacePurchaseType): string => {
  if (platform == MarketplacePurchaseType.Flex)
      return 'Flex'
  if (platform == MarketplacePurchaseType.Impact)
      return 'Imapct'
  return "";
}

export const getPurchaseStatusName = (status: PurchaseStatus): string => {
  if (status == PurchaseStatus.Completed)
      return 'Completed'
  if (status == PurchaseStatus.Pending)
      return 'Pending'
  if (status == PurchaseStatus.Reversed)
      return 'Canceled'
  return "";
}

export const getCashbackStatusName = (status: CashBackStatus): string => {
  if (status == CashBackStatus.Available)
      return 'Available'
  if (status == CashBackStatus.Canceled)
      return 'Canceled'
  if (status == CashBackStatus.Claimed)
      return 'Claimed'
  if (status == CashBackStatus.Collected)
      return 'Collected'
  if (status == CashBackStatus.Expired)
      return 'Expired'
  if (status == CashBackStatus.Processing)
      return 'Processing'
  return "";
}


export const getMoneyType = (value: number) : string => {
var parsedValue = (value) ? value : 0;
  return  `$${Number.parseFloat(parsedValue.toString()).toFixed(2)}` ;
}

export  const newGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}