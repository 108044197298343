import {
    ArrowRightOutlined,
    BankOutlined,
    GiftOutlined,
    NotificationOutlined,
    ThunderboltOutlined
} from "@ant-design/icons";
import {Badge, Tag} from "antd";
import React from "react";
import {TransferType} from "../../../../core/models/dashboard/transactions/transation";
import {s3DestinationMoveMoneyImgUrl, s3FromMoveMoneyImgUrl} from "../../utils/Utils";
import {ReactComponent as Withdrawal} from "../../../../assets/svg/money-withdrawal.svg";
import {ReactComponent as Deposit} from "../../../../assets/svg/deposit.svg";
import {ReactComponent as MarketplacePurchase} from "../../../../assets/svg/marketplace-purchase.svg";

export interface TransactionTypeComponentProps {
    transferType: TransferType
}

const TransactionTypeComponent: React.FC<TransactionTypeComponentProps> = props => {
    return <>
        {props.transferType == TransferType.Cashback &&
        (
            <Tag className="cashback" key={0}>
                <GiftOutlined/> Cashback
            </Tag>
        )
        }
        {props.transferType == TransferType.Withdraw &&
        (
            <Tag className="withdrawal" key={1}>
                <Withdrawal/> Withdrawal
            </Tag>
        )
        }
        {props.transferType == TransferType.Deposit &&
        (
            <Tag className="deposit" key={2}>
                <Deposit/> Deposit
            </Tag>
        )
        }
        {props.transferType == TransferType.ReferralReward &&
        (
            <Tag className="referrarl" key={3}>
                <NotificationOutlined/> Referral
            </Tag>
        )
        }
        {props.transferType == TransferType.MarketplacePurchase &&
        (
            <Tag className="marketplace-purchase" key={4}>
                <NotificationOutlined/> Marketplace purchase
            </Tag>
        )
        }

        {props.transferType == TransferType.DirectDeposit &&
        (
            <Tag className="direct-deposit" key={5}>
                <ThunderboltOutlined/> Direct deposit
            </Tag>
        )
        }
    </>
}
export default TransactionTypeComponent;