import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, Input, Menu, message, Row, Spin, Tabs, Select } from "antd";
import { CloseCircleOutlined, DownOutlined, MailOutlined, RightOutlined } from "@ant-design/icons";
import { NotificationPushModel, UserSuggestionModel } from "../../../../core/models/dashboard/notification/notification";
import { getUserSuggestionsData, sendToAll, sendToSpecific } from "../../../../core/actions/dashboard/notification/updateNotificationTemplatesInStore";
import UserSelectStatusComponent from '../../../common/components/dashboard/UserSelectStatusComponent';
import "../../../../styles/layout/dashboard/notification/notificationTemplateSend.less"

const { Option } = Select;
const { TabPane } = Tabs;

export const NotificationTemplateView = (props: any) => {
    return (
        <Card className="notification-template-view">
            <Row className="nt-template-view-row-item">
                <span className="item-value">{props?.title}</span>
            </Row>
            <Row className="nt-template-view-row-item">
                <p>
                    {props?.description
                        ?.replaceAll('[fullname]', props.name ?? 'Example Name')
                        ?.replaceAll('[email]', props.email ?? 'email@example.com')}
                </p>
            </Row>
        </Card>
    )
}

export const NotificationTemplateSendComponent: React.FC<any> = props => {
    const [isLoading, setLoading] = useState(false);
    const [currentSuggestions, setCurrentSuggestions] = useState(new Array<UserSuggestionModel>());
    const [value, setValue] = useState('');
    const [selectedSuggestions, setSelectedSuggestions] = useState(new Array<UserSuggestionModel>());
    const [selectedUserStatus, setSelectedUserStatus] = useState(new Array<number>());

    const [isNotCollapsed, setCollapsed] = useState(true);

    const collapse = () => {
        setCollapsed(!isNotCollapsed);
    }

    const onSuggestionsFailure = (_: any) => {
        message.error(`Can't load user suggestions!`);
    }
    const onSuggestionGetSuccess = (data: Array<UserSuggestionModel>) => {
        setCurrentSuggestions(data);
    }
    const removeSuggestions = (data: UserSuggestionModel) => {
        setSelectedSuggestions(prev => prev.filter(e => e.id !== data.id))
    }
    const handleSuggestionsChange = (value: string) => {
        setValue(value);
        getUserSuggestionsData(value, onSuggestionGetSuccess, onSuggestionsFailure);
    }

    const onSendSuccess = () => {
        setLoading(false)
        message.success('Notification sending was successfully initiated.');
    }
    const onSendFailure = (_: any) => {
        setLoading(false)
        message.error('Notification sending failed to be initiated!');
    }

    const initiateSendToAll = () => {
        const sendModel: NotificationPushModel = {
            templateIds: props.templates.map((e: any) => (e.id as string)),
            userIds: [],
            userStatus:selectedUserStatus
        };

        setLoading(true);
        console.log("send model:", sendModel);
        debugger;
        sendToAll(sendModel, onSendSuccess, onSendFailure);
    }

    const initiateSendToSpecific = () => {
        const sendModel: NotificationPushModel = {
            templateIds: props.templates.map((e: any) => (e.id as string)),
            userIds: selectedSuggestions.map(e => e.id),
            userStatus:[]
        };

        setLoading(true);
        sendToSpecific(sendModel, onSendSuccess, onSendFailure);
    }

    const addToSelected = (data: UserSuggestionModel) => {
        setValue('');
        setSelectedSuggestions(prev => {
            return [
                ...prev.filter(x => x.id !== data.id), data
            ];
        })
    }

    const menu =
        (<Menu className="menu-item">
            {currentSuggestions.map(e => {
                return (
                    <Menu.Item onClick={() => { addToSelected(e); }}>
                        {`${e.fullName} (${e.email})`}
                    </Menu.Item>
                )
            })
            }
        </Menu>
        );

    const getHeaderTitle = () => {
        return (
            <>
                {isNotCollapsed ? <><DownOutlined onClick={collapse} /> {`Notification Sender`}</> : <><RightOutlined onClick={collapse} /> {`Notification Sender`}</>}

            </>
        )
    }

    const onUserStatusSelect = (value: any, option: any) => {
        console.log("on user selected: ", value, option)
        setSelectedUserStatus(value);
    }

    return (
        <Card
            className={`notification-sender ${isNotCollapsed ? "is-flex-expanded nt-expanded" : "nt-collapsed"}`}
            title={getHeaderTitle()}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <div className="nt-tab-row-card-content">
                    <Col className="nt-card-col" span={24}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Send to all" key="1">
                                <Row className="row-item">
                                    <span> Send to users with status:</span>
                                    <UserSelectStatusComponent {...{ onChange: onUserStatusSelect }} />
                                    <span>Selected Templates:</span>
                                </Row>
                                <Card className="card-template-content">
                                    <Row className="row-item">
                                        {
                                            props.templates.map((e: any) => (
                                                <NotificationTemplateView
                                                    title={e.title}
                                                    description={e.description}
                                                />
                                            ))
                                        }
                                    </Row>
                                </Card>
                                <Row className="notification-button-wrapper">
                                    <Button
                                        onClick={() => initiateSendToAll()}
                                        disabled={props.templates?.length === 0}
                                        type="primary"
                                        shape="round"
                                        icon={<MailOutlined />}>
                                        Send
                                    </Button>
                                </Row>
                            </TabPane>
                            <TabPane tab="Send to specific" key="2">
                                <Dropdown overlay={menu}
                                >
                                    <Row className="row-item">
                                        <span className="item-title">Users:</span>
                                        <Input
                                            value={value}
                                            type="text"
                                            onChange={(res) => {
                                                handleSuggestionsChange(res?.target?.value)
                                            }} name="title"
                                            placeholder="Type something..."
                                            className="item-input" maxLength={100} />
                                    </Row>
                                </Dropdown>
                                <Row className="row-item template-row-item">
                                    {
                                        selectedSuggestions.map((e) => (
                                            <div className="rounded-button rounded-button-exit margin-right margin-top"
                                                onClick={() => removeSuggestions(e)}>
                                                {`${e.fullName} (${e.email})`} <CloseCircleOutlined />
                                            </div>
                                        ))}
                                </Row>

                                <Row className="row-item">
                                    <h6>Selected Templates:</h6>
                                </Row>
                                <Card className="selected-crad-template">

                                    <Row className="row-item">
                                        {
                                            props.templates.map((e: any) => (
                                                <NotificationTemplateView
                                                    title={e.title}
                                                    description={e.description}
                                                />
                                            ))
                                        }
                                    </Row>
                                </Card>

                                <Row className="notification-button-wrapper">
                                    <Button
                                        onClick={() => initiateSendToSpecific()}
                                        disabled={selectedSuggestions.length === 0 || props.templates?.length === 0}
                                        type="primary"
                                        shape="round"
                                        icon={<MailOutlined />}>
                                        Send
                                    </Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Col>
                </div>
            </Spin>
        </Card>
    );
}