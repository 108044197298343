import {MarketplacePlatformModel} from "../../../../models/dashboard/marketplace/marketplaceModels";
import {GET_MARKETPLACEPLATFORM_SUCCESS, GET_MARKETPLACEPLATFORM_ERROR,MarketplacePlatformInStoreAction} from "../../../../actions/dashboard/marketplace/platform/updateMarketplacePlatformInStore";

export  const  updateMarketplacePlatformInStoreReducer = 
    (state: Array<MarketplacePlatformModel> = [], action: MarketplacePlatformInStoreAction) => {
        switch (action.type) {
            case GET_MARKETPLACEPLATFORM_SUCCESS:
                return [...action?.marketplacePlatform]
            case GET_MARKETPLACEPLATFORM_ERROR:
                return  [];
            default:
                return [...state];
        }
    }