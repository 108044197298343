import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../../commons/reduxStore";
import { AffiliateDetailsModel } from "../../../../models/dashboard/marketplace/affiliateDetails";
import getAffiliateDetails from "../../../../services/dashboard/marketplace/affiliates/getAffiliateDetails";

export const GET_AFFILIATE_DETAILS_SUCCESS = "GET_AFFILIATE_DETAILS_SUCCESS";
export const GET_AFFILIATE_DETAILS_ERROR = "GET_AFFILIATE_DETAILS_ERROR";


export interface AffiliateDetailsInStoreAction {
    type: typeof GET_AFFILIATE_DETAILS_SUCCESS | typeof GET_AFFILIATE_DETAILS_ERROR,
    affiliateDetails: AffiliateDetailsModel
}


export const updateAffiliateDetailsInStoreAction = (id: string, affiliateDetails: AffiliateDetailsModel | null,
    onSuccess: Function,
    onFailure: Function)
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getAffiliateDetails(id))
            .then((res: AffiliateDetailsModel) => {
                dispatch({
                    type: GET_AFFILIATE_DETAILS_SUCCESS,
                    affiliateDetails: res
                } as AffiliateDetailsInStoreAction);
                onSuccess();
            })
            .catch((err) => {
                dispatch({
                    type: GET_AFFILIATE_DETAILS_ERROR,
                    affiliateDetails: affiliateDetails
                } as AffiliateDetailsInStoreAction);
                onFailure();
            });
    }