import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
    commandRequest,
    queryRequest
} from '../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../commons/reduxStore';
import {
    AdminNotificationExecutionLogModel,
    AdminNotificationSendJobModel, BaseRequestParams,
    GetNotificationJobModel, GetNotificationLogsModel,
    NotificationPushModel,
    NotificationTemplateData,
    NotificationTemplateModel,
    UserSuggestionModel
} from "../../../models/dashboard/notification/notification";

import {
    addAdminNotificationTemplate,
    deleteAdminNotificationTemplate,
    getAdminNotificationTemplates,
    getUserSuggestions,
    updateAdminNotificationTemplate,
    sendNotificationToAll,
    sendNotificationToSpecific,
    getAdminNotificationExecutionLogs
} from "../../../services/dashboard/notification/handleAdminNotifications";
import {executeAction} from "../../../commons/baseAction";

export const GET_NOTIFICATION_TEMPLATES_SUCCESS = "GET_NOTIFICATION_TEMPLATES_SUCCESS";
export const GET_NOTIFICATION_TEMPLATES_FAILURE = "GET_NOTIFICATION_TEMPLATES_FAILURE";

export interface NotificationTemplatesInStoreAction {
    type: typeof GET_NOTIFICATION_TEMPLATES_SUCCESS
        | typeof GET_NOTIFICATION_TEMPLATES_FAILURE,
    model: NotificationTemplateModel | null,
    searchParams: BaseRequestParams,
}



// notification templates
export const updateNotificationTemplatesInStore = (
    params: BaseRequestParams | null,
    currentParams: BaseRequestParams | null,
    templates: NotificationTemplateModel | null,
    onSuccess: Function,
    onFailure: Function
    ):
    ThunkAction<void, AppState, unknown, Action> => dispatch => {
    executeAction(
        queryRequest(() => getAdminNotificationTemplates(params?.pageNumber, params?.pageSize)),
        (res : NotificationTemplateData) => {
            dispatch({
                type: GET_NOTIFICATION_TEMPLATES_SUCCESS,
                model: res,
                searchParams: params
            } as unknown as NotificationTemplatesInStoreAction);
            onSuccess();
        },
        (err : any) => {
            dispatch({
                type: GET_NOTIFICATION_TEMPLATES_FAILURE,
                model: templates,
                searchParams: currentParams
            } as unknown as NotificationTemplatesInStoreAction);
            onFailure(err);
        }
    );
}

export const deleteNotificationTemplatesInStore = (
    id: string | null,
    onSuccess: Function,
    onFailure: Function
): void => {
    executeAction(
        queryRequest(() => deleteAdminNotificationTemplate(id)),
        (_: any) => {
            onSuccess();
        },
        (err : any) => {
            onFailure(err);
        }
    );
}

export const addNotificationTemplateDataInStore =  (
    model: NotificationTemplateData | null,
    onSuccess: Function,
    onFailure: Function
): void => {
    executeAction(
        commandRequest(() => addAdminNotificationTemplate(model)),
        (_: any) => {
            onSuccess();
        },
        (err: any) => {
            onFailure(err);
        }
    );
}

export const updateNotificationTemplateDataInStore =  (
    model: NotificationTemplateData | null,
    onSuccess: Function,
    onFailure: Function
): void =>  {
    executeAction(
        commandRequest(() => updateAdminNotificationTemplate(model)),
        (_: any) => {
            onSuccess();
        },
        (err: any) => {
            onFailure(err);
        }
    );
}

// send notifications
export const getUserSuggestionsData = (
    keyword: string | null,
    onSuccess: Function,
    onFailure: Function
): void => {
    executeAction(
        queryRequest(() => getUserSuggestions(keyword)),
        (res: Array<UserSuggestionModel>) => {
            onSuccess(res);
        },
        onFailure
    )
}

export const sendToAll = (
    model : NotificationPushModel,
    onSuccess: Function,
    onFailure: Function
) : void => {
    executeAction(
        commandRequest(() => sendNotificationToAll(model)),
        (_: any) => {onSuccess()},
        (_: any) => {onFailure()} );
}

export const sendToSpecific = (
    model : NotificationPushModel,
    onSuccess: Function,
    onFailure: Function
) : void => {
    executeAction(
        commandRequest(() => sendNotificationToSpecific(model)),
        (_: any) => {onSuccess()},
        (_: any) => {onFailure()} );
}

// jobs





