import getStatistics  from "./getStatistics";
import marketplace from "./marketplace/index";
import marketplacePurchase from "./marketplacePurchase/index";
import transactions from "./transactions/index";
import moveMoney from "./moveMoney/index";
import users from "./users/index";
import goalsServices from "./goals/index";
import subscriptions from "./subscriptions";

const dashboard = {
    getStatistics, 
    goalsServices,
    users,  
    moveMoney,
    transactions,
    marketplace,
    marketplacePurchase,
    subscriptions
};

export default dashboard;