import { GET_WITHDRAWAL_STATISTICS_ERROR, GET_WITHDRAWAL_STATISTICS_SUCCESS, WithdrawalStatisticsInStoreAction } from "../../../actions/dashboard/moveMoney/updateWithdrawalStatisticsInStore";
import { WithdrawStatisticsModel } from "../../../models/dashboard/withdraw/withdraw";

export const updateWithdrawalStatisticsInStoreReducer = (state: WithdrawStatisticsModel | null = null, 
    action: WithdrawalStatisticsInStoreAction) => {
    switch (action.type) {
        case GET_WITHDRAWAL_STATISTICS_SUCCESS:
            return {...action.withdrawalStatisticsData }
        case GET_WITHDRAWAL_STATISTICS_ERROR:
            return {...state};
        default:
            return {...state};
    }
}