import {Badge} from "antd";
import React, {useState} from "react";

export interface StatisticsItemProps {
    title: string;
    value: string;
    badgeColor: string;
    collapsible?: any;
    secondValue?: string;
}


const StatisticsItemComponent: React.FC<StatisticsItemProps> = props => {
    const [isExpanded, setExpanded] = useState(false);
    return (
        <>
            <div className="statistic-item-wrapper">
                <div className="statistics-item-badge-wrapper">
                    <Badge className="badge-item" color={props.badgeColor}/>
                </div>
                <div className="inherit">
                    <span className="stats-details">
                        <h6 className="title-item">{props.title}</h6>
                        <>
                        <span className="value-item inline-block">{props.value}
                            &nbsp;&nbsp;
                        </span>

                            {props.secondValue ?
                                <>
                                 <span
                                    className={"left-divider value-item inline-block"}> &nbsp;{props.secondValue}</span>
                                    </>: null
                            }
                            {props.collapsible ? <span className='more-details-link' onClick={() => {
                                setExpanded(prev => !prev);
                            }}>More details</span> : null}
                            </>
                        <br/>
                    </span>
                </div>
            </div>
            <div>
                {
                    isExpanded ?
                        <span>
                            <h6 className='statistics-item-expanded-title title-item'>Bank disconnected:</h6>
                            <span className='statistics-item-expanded-value value-item'>{props.collapsible}</span>
                        </span>
                        : null
                }
            </div>
        </>
    );
}
export default StatisticsItemComponent;