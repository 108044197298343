import {Empty, Table, Input, Badge, Row, Spin, message} from 'antd';
import React, { useEffect, useState } from 'react';
import { AppState } from "../../../core/commons/reduxStore";
import { connect } from "react-redux";
import '../../../styles/layout/dashboard/goals/goalsTable.less';
import { updateMoveMoneyByUserInStoreAction } from "../../../core/actions/dashboard/moveMoney/updateMoveMoneyListByUserInStore";
import { GetMoveMoney, MoveMoneyModel, MoveMoneySearch, MoveMoneyType } from '../../../core/models/dashboard/moveMoney/moveMoney';
import moment from 'moment';
import { ArrowRightOutlined, BankOutlined, SearchOutlined } from '@ant-design/icons';
import { s3FromMoveMoneyImgUrl, s3DestinationMoveMoneyImgUrl, CurrencyNumberFormat } from '../../common/utils/Utils';
import MoveMoneyTypeComponent from '../../common/components/dashboard/MoveMoneyTypeComponent';
const { Search } = Input;


export interface GoalUserComponentProps {
    userId: string,
    userMoveMoney: GetMoveMoney,
    updateMoveMoneyByUserInStoreAction: (moveMoney: GetMoveMoney | null, searchParams: MoveMoneySearch, onSuccess: Function, onFailure: Function) => void;
}

const initSearchParams: MoveMoneySearch = {
    pageNumber: 1,
    pageSize: 10,
    searchValue: "",
    orderDescending: true,
    userId: "",
    sortColumn: "createdAt",
    status: null,
    type: null,
    createdAt: {
        from: null,
        to: null
    }
};

const UserMoveMoneyTableComponent: React.FC<GoalUserComponentProps> = props => {
    const maxSearchLength = 50;
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(false);


    const onMoveMoneyLoadSuccess = () => {
        setLoading(false);
    }
    const onMoveMoneyLoadFailure = () => {
        message.error('Move money list failed to load!');
        setLoading(false);
    }

    const getMoveMoney = () => {
        initSearchParams.userId = props.userId;
        props.updateMoveMoneyByUserInStoreAction(null, initSearchParams, onMoveMoneyLoadSuccess, onMoveMoneyLoadFailure);
        setLoading(true);
    }

    if (isMounting) {
        getMoveMoney();
        setMounting(false)
    }

    const onSearchSubmit = (searchValue: string) => {
        initSearchParams.searchValue = searchValue;
        initSearchParams.pageNumber = 1;
        props.updateMoveMoneyByUserInStoreAction(null, initSearchParams,onMoveMoneyLoadSuccess, onMoveMoneyLoadFailure);
        setLoading(true);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {

        if (filters) {
            if (filters.status != undefined || filters.status != null) {
                initSearchParams.status = Number(filters.status[0]);
            } else {
                initSearchParams.status = null;
            }
            if (filters.type != undefined || filters.type != null) {
                initSearchParams.type = Number(filters.type[0]);
            } else {
                initSearchParams.type = null;
            }
        }

        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = (sorter.order == 'ascend') ? false : true;

            } else {
                initSearchParams.sortColumn = 'createdAt';
                initSearchParams.orderDescending = true;
            }
        }

        if (pagination) {
            if (props.userMoveMoney?.pageNumber != pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        };

        props.updateMoveMoneyByUserInStoreAction(null, initSearchParams, onMoveMoneyLoadSuccess, onMoveMoneyLoadFailure);
        setLoading(true);
    }

    const columns = [
        {
            title: 'Type',
            width: 170,
            dataIndex: 'type',
            key: 'type',
            fixed: true,
            sorter: true,
            filterMultiple: false,
            filters: [
                {
                    text: <> <MoveMoneyTypeComponent {...{ type: MoveMoneyType.GoalToGoal }}></MoveMoneyTypeComponent></>,
                    value: MoveMoneyType.GoalToGoal,
                },
                {
                    text: <> <MoveMoneyTypeComponent {...{ type: MoveMoneyType.Withdrawal }}></MoveMoneyTypeComponent></>,
                    value: MoveMoneyType.Withdrawal,
                }
            ],
            render: (type: number, row: MoveMoneyModel) => (
                <>
                    <MoveMoneyTypeComponent {...{ type: type }}></MoveMoneyTypeComponent>

                </>
            )
        },
        {
            title: 'Date created',
            width: 220,
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (createdAt: Date) => (
                <> { createdAt ? `${moment(new Date(createdAt)).format("MM-DD-YYYY")}` : ("")} </>
            )
        },
        {
            title: 'From',
            width: 220,
            dataIndex: 'nameOfFrom',
            key: 'nameOfFrom',
            sorter: true,
        },
        {
            title: 'To',
            width: 220,
            dataIndex: 'nameOfDestination',
            key: 'nameOfDestination',
            sorter: true
        },
        {
            title: 'Status',
            width: 220,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            filterMultiple: false,
            filters: [
                {
                    text: <><span><Badge color={"#52C41A"} text={"Settled"} /></span></>,
                    value: 0,
                },
                {
                    text: <><span><Badge color={"#BFBFBF"} text={"Pending"} /></span></>,
                    value: 1,
                },
                {
                    text: <><span><Badge color={"#F5222D"} text={"Canceled"} /></span></>,
                    value: 2,
                },
            ],
            render: (status: number, row: MoveMoneyModel) => (
                <>
                    {status == 0 &&
                        <span>   <Badge color={"#52C41A"} text={"Settled"} /></span>
                    }
                    {status == 1 &&
                        <span>   <Badge color={"#BFBFBF"} text={"Pending"} /></span>
                    }
                    {status == 2 &&
                        <span>   <Badge color={"#F5222D"} text={"Canceled"} /></span>
                    }
                </>
            )
        },
        {
            title: 'Amount',
            width: 170,
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            render: (amount: number) => (
                <> {amount ? CurrencyNumberFormat(amount) : ("")} </>
            )
        }
    ]

    return <>
        <Spin spinning={isLoading}>
            <Row className="searchbox">
                <Search size="large"
                        name="searchValue"
                        className="search-input-goals"
                        maxLength={maxSearchLength}
                        placeholder="Search something..."
                        onSearch={onSearchSubmit} />
            </Row>

            <Table
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                pagination={{
                    total: props.userMoveMoney?.totalItemCount, current: props.userMoveMoney?.pageNumber,
                    pageSize: props.userMoveMoney?.pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: 1200 }}
                onChange={(onChangeEvent)}
                columns={columns} dataSource={props.userMoveMoney?.items} />
        </Spin>
    </>
}

const mapStateToProps = ({ userMoveMoney }: AppState) =>
({
    userMoveMoney: userMoveMoney
});

export default connect(mapStateToProps, {
    updateMoveMoneyByUserInStoreAction
})(UserMoveMoneyTableComponent);