import { GET_USERS_LIST_ERROR, GET_USERS_LIST_SUCCESS, UsersListInStoreAction } from '../../../actions/dashboard/users/updateUserListInStore';
import { UserModel } from '../../../models/dashboard/users/user';


export const updateUserListInStoreReducer = (state: UserModel | null = null, action: UsersListInStoreAction) => {
    switch (action.type) {
        case GET_USERS_LIST_SUCCESS:
            return { ...state, ...action.users };
        case GET_USERS_LIST_ERROR:
            return null;
        default:
            return state;
    }
}