import { updateUserDetailsInStoreReducer } from './dashboard/users/updateUserDetailsInStoreReducer';
import { updateStatisticsInfoReducer } from './dashboard/updateStatisticsInStoreReducer';
import { updateUserInfoReducer } from "./users/updateUserInStoreReducer";
import { updateUserListInStoreReducer } from './dashboard/users/updateUserListInStoreReducer';
import { updateUserGoalsListInStoreReducer } from './dashboard/goals/updateGoalsListInStoreReducer';
import { updateMoveMoneyByUserInStoreReducer } from './dashboard/moveMoney/updateMoveMoneyByUserInStoreReducer';
import { updateTransactionsByUserInStoreReducer } from './dashboard/transactions/updateTransactionsByUserInStoreReducer';
import { updateTransactionsDepositByTransferInStoreReducer } from './dashboard/transactions/updateTransactionsDepositByTransferInStoreReducer';
import { updateTransactionWithdrawalByTransferInStoreReducer } from './dashboard/transactions/updateTransactionWithdrawalByTransferInStoreReducer';
import { updateSuggestedAffiliatesInStoreReducer } from './dashboard/marketplace/suggestedAffiliates/updateSuggestedAffiliatesInStoreReducer';
import {updateNotificationInStoreReducer} from './dashboard/notification/updateNotificationInStoreReducer';
import {updateNotificationJobsInStoreReducer} from "./dashboard/notification/updateNotificationJobsInStoreReducer";
import {updateNotificationLogsInStoreReducer} from "./dashboard/notification/updateNotificationLogsInStoreReducer";
import {updateNotificationLogsInStoreSimpleReducer} from "./dashboard/notification/updateNotificationLogsInStoreSimpleReducer";
import {updateBankAccountDetailsInStoreReducer} from "./dashboard/users/updateBankAccountDetailsInStoreReducer";
import {updatePaymentOptionDetailsInStoreReducer} from "./dashboard/users/updatePaymentOptionDetailsInStoreReducer";
import marketplaceReducer from './dashboard/marketplace/index';
import marketplacePurchaseReducer from "./dashboard/marketplacePurchase/index"
import subscriptionReducer from './dashboard/subscriptions';
import moveMoneyReducer from './dashboard/moveMoney';
import userReducer from './dashboard/users';

const mainReducerExport = {
    updateUserInfoReducer,
    updateStatisticsInfoReducer,
    updateUserGoalsListInStoreReducer,
    updateTransactionsByUserInStoreReducer,
    updateTransactionsDepositByTransferInStoreReducer,
    updateTransactionWithdrawalByTransferInStoreReducer, 
    updateSuggestedAffiliatesInStoreReducer,
    updateNotificationInStoreReducer,
    updateNotificationLogsInStoreReducer,
    updateNotificationJobsInStoreReducer,
    updateNotificationLogsInStoreSimpleReducer,
    ...marketplaceReducer,
    ...marketplacePurchaseReducer,
    ...subscriptionReducer,
    ...moveMoneyReducer,
    ...userReducer
};

export default mainReducerExport;