import { GET_SUGGESTED_ERROR, GET_SUGGESTED_SUCCESS, SuggestedAffiliatesInStoreAction } from "../../../../actions/dashboard/marketplace/suggestedAffiliates/updateSuggestedAffiliatesInStore";
import {
    SuggestedAdvertisers
} from "../../../../models/dashboard/marketplace/suggestedAdvertisers";


export const updateSuggestedAffiliatesInStoreReducer = (state: SuggestedAdvertisers | null = null, action:SuggestedAffiliatesInStoreAction) => {
    switch(action.type) {
        case GET_SUGGESTED_SUCCESS:
            return { ...state, ...action.suggestAdvertiser }
        case GET_SUGGESTED_ERROR:
            return null;
        default:
            return {...state};
    }
}