export interface DailySubscriptionStatistics {
    totalSubscriptions: number;
    totalPremium: number;
    totalTrial: number;
    totalNewUsers: number;
    totalExistingUser: number;
    canceledSubscriptions: number;
    usersWithPremiumCard: number;
}

export interface MonthlySubscriptionStatistics {
    totalSubscriptions: number;
    totalPremium: number;
    totalTrial: number;
    totalNewUsers: number;
    totalExistingUser: number;
    canceledSubscriptions: number;
    usersWithPremiumCard: number;
}

export interface TotalSubscriptionStatistics {
    totalSubscriptions: number;
    totalPremium: number;
    totalTrial: number;
    canceledSubscriptions: number;
    usersWithPremiumCard: number;
}

export interface SubscriptionStatistics {
    dailySubscriptionStatistics: DailySubscriptionStatistics;
    monthlySubscriptionStatistics: MonthlySubscriptionStatistics;
    subscriptionStatistics: TotalSubscriptionStatistics;
}


export interface UserDetailsSubscribed {
    id: string;
    fullName: string;
    email: string;
    createdAt: Date;
}

export interface UserSubscriptions {
    id: string;
    user: UserDetailsSubscribed;
    subscriptionType: UserSubscriptionType;
    startPlanDate: Date;
    endPlanDate: Date;
    isCanceled: boolean;
    hasPrimaryCardOfUse: boolean;
}

export interface PaginatedUserSubscriptions {
    items: UserSubscriptions[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}

export interface GetListOfUserSubscriptions {
    pageNumber: number;
    pageSize: number;
    searchValue: string;
    sortColumn: string;
    orderDescending: boolean;
}

export enum UserSubscriptionType
{
    Trial,
    Premium
}


export interface CSVUserSubscriptions {
    User:string;
    Email:string;
    CreatedAt:string;
    SubscriptionType:string;
    StartPlanDate: string;
    EndPlanDate: string;
    IsCanceled: string;
    HasPrimaryCardOfUse: string;
}


export interface SubscriptionPlans {
    id: string;
    title: string;
    duration: number;
    description: string;
    headTitle: string;
    headDescription: string;
    iconUrl: string;
    priceLabel: string;
    amount: number;
    order: number;
    planType: number;
    status: number;
}

export interface  UpdateSubscription
{
     id:string;
     title:string
     amount:number
}
