import { GET_USERSUBSCRIPTIONS_ERROR, GET_USERSUBSCRIPTIONS_SUCCESS, UserSubscriptionsInStoreAction } from "../../../../actions/dashboard/subscriptions/userSubscriptions/updateUserSubscriptionsInStore";
import { PaginatedUserSubscriptions } from "../../../../models/dashboard/subscriptions/subscriptions";

export const updateUserSubscriptionsInStoreReducer = (state: PaginatedUserSubscriptions | null = null, action: UserSubscriptionsInStoreAction) => {
    switch (action.type) {
        case GET_USERSUBSCRIPTIONS_SUCCESS:
            return { ...state, ...action.userSubscriptions }
        case GET_USERSUBSCRIPTIONS_ERROR:
            return {...state};
        default:
            return {...state};
    }
}