const resource = {
    generic: {
        tableEmpty: 'No results for ths search. Try again...'
    },
    routes: {
       dashboard: {
           dashboard: 'Dashboard',
           user: 'User', 
           marketplace:'Marketplace',
           details:'Details'
       } 
    },
    dashboard: {
        marketplace: {
            suggestion:{
                
            },
            platform:{

            },
            category: {

            },
            affiliate: {
               newAffiliate:{
                   title:'Add new affiliate',
                   cancelButton:'Cancel',
                   saveButton:'Save',
                   addNewButton: 'Add new',
                   name:'Affiliate name',
                   description:'Description',
                   platform:'Platform',
                   category:'Category',
                   accessUrl:'Access URL',
                   secondAccessUrl:'Second access URL (Optional)',
                   promotions: 'Promotions',
                   promoName:'Promotion name',
                   userCashBack:'User cashback percentage',
                   guacCashBack:'Guac cashback percentage',
                   upload:'Upload', 
                   optional:'Optional'
               }, 
              
            }

        }
    }
}

export default resource;