import { SubscriptionStatistics } from '../../../../models/dashboard/subscriptions/subscriptions';
import { GET_SUBSCRIPTIONS_STATISTICS_ERROR, GET_SUBSCRIPTIONS_STATISTICS_SUCCESS, SubscriptionsInStoreAction } from './../../../../actions/dashboard/subscriptions/statistics/updateSubscriptionStatisticsInStore';

export const updateSubscriptionStatisticsInStoreReducer = (state: SubscriptionStatistics | null = null, action: SubscriptionsInStoreAction) => {
    switch (action.type) {
        case GET_SUBSCRIPTIONS_STATISTICS_SUCCESS:
            return { ...state, ...action.subscriptionStatistics }
        case GET_SUBSCRIPTIONS_STATISTICS_ERROR:
            return {...state};
        default:
            return {...state};
    }
}
