const detailsResource = {
    edit:'Edit',
    delete: 'Delete',
    name:'Name',
    accessUrl:'Access URL',
    platform:'Platform',
    secondAccessUrl:'Second access URL',
    category:'Category',
    status:'Status',
    createdAt:'Created on', 
    lastModifiedAt: 'Updated on', 
    unableToDelete : 'Unable to delete',
    deleteAffiliateContentRow1: 'Deleting this store will result in removing this from the (Marketplace or Earn Cashback).',
    deleteAffiliateContentRow2: 'In the future, you can find this store in the “Deleted stores” tab.'
}
export default detailsResource;