import { Avatar, Badge, Card, Col, Divider, Row, Spin, Statistic, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout"
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../core/commons/reduxStore";
import BreadcrumbComponent, { BreadcrumbProps } from "../../common/components/dataDisplay/BreadcrumbComponent";
import SubscriptionListComponent from "./subscriptionList/SubscriptionListComponent";
import SubscriptionStatisticsComponent from "./statistics/StatisticsComponent";
import { GetListOfUserSubscriptions, PaginatedUserSubscriptions, SubscriptionStatistics, UserSubscriptions } from "../../../core/models/dashboard/subscriptions/subscriptions";
import { updateSubscriptionStatisticsInStoreAction } from "../../../core/actions/dashboard/subscriptions/statistics/updateSubscriptionStatisticsInStore";
import { updateUserSubscriptionsInStoreAction } from "../../../core/actions/dashboard/subscriptions/userSubscriptions/updateUserSubscriptionsInStore";
import { updateAllUserSubscriptionsInStoreAction } from "../../../core/actions/dashboard/subscriptions/userSubscriptions/updateAllUserSubscriptionsInStore";
import { UserOutlined } from "@ant-design/icons";
import SubscriptionPlansComponent from "./subscriptionPlans/SubscriptionPlansComponent";
const { TabPane } = Tabs;

export interface SubscriptionsComponentProps {
    updateSubscriptionStatisticsInStoreAction: () => void;
    updateAllUserSubscriptionsInStoreAction: () => void;
    updateUserSubscriptionsInStoreAction: (model: GetListOfUserSubscriptions) => void;
    subscriptionStatistics: SubscriptionStatistics;
    userSubscriptions: PaginatedUserSubscriptions;
    allUserSubscriptions: Array<UserSubscriptions>;
}

var initialValue: GetListOfUserSubscriptions = {
    orderDescending: true,
    pageNumber: 1,
    pageSize: 10,
    searchValue: '',
    sortColumn: 'CreatedAt'
}

const Subscription: React.FC<SubscriptionsComponentProps> = (props: SubscriptionsComponentProps) => {
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(true);

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Subscriptions",
                path: ""
            }
        ]
    };

    const getSubscriptionStatistics = () => {
        props.updateSubscriptionStatisticsInStoreAction();
    }

    const getUserSubscriptionsList = () => {
        props.updateUserSubscriptionsInStoreAction(initialValue);
    }

    const getAllUserSubscriptionsList = () => {
        props.updateAllUserSubscriptionsInStoreAction();
    }

    if (isMounting) {
        setLoading(false);
        setMounting(false);
        getSubscriptionStatistics();
        getUserSubscriptionsList();
        getAllUserSubscriptionsList();
    }

    return (
        <Content>
            <BreadcrumbComponent {...currentBreadCrumbs} />
            <Spin spinning={isLoading}>
                <Row className="home-content">

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[8, 8]}>
                      <SubscriptionPlansComponent></SubscriptionPlansComponent>
                    </Row>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                {props.subscriptionStatistics ? <SubscriptionStatisticsComponent {...{ statistics: props.subscriptionStatistics }} ></SubscriptionStatisticsComponent> : ""}
                            </Card>
                        </Col>
                    </Row>
                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={24}>
                            <Card className="card-item" bordered={false}>
                                <SubscriptionListComponent {...{ userSubscriptions: props.userSubscriptions, allUserSubscriptions: props.allUserSubscriptions }}></SubscriptionListComponent>
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </Spin>
        </Content>
    )
}

const mapStateToProps = ({ subscriptionStatistics, userSubscriptions, allUserSubscriptions }: AppState) =>
({
    subscriptionStatistics: subscriptionStatistics,
    userSubscriptions: userSubscriptions,
    allUserSubscriptions: allUserSubscriptions

});

export default connect(mapStateToProps, {
    updateSubscriptionStatisticsInStoreAction,
    updateUserSubscriptionsInStoreAction,
    updateAllUserSubscriptionsInStoreAction

})(Subscription);
