

import { adminHttpClient } from "../../../commons/adminHttpClinet";
import { GetListOfUserSubscriptions, PaginatedUserSubscriptions, UserSubscriptions } from "../../../models/dashboard/subscriptions/subscriptions";

const getAllUserSubscriptions = (): Promise<Array<UserSubscriptions>> => {

    return adminHttpClient.get(`/api/Admin/AdminSubscriptions/get-all-users-subscriptions`)
}

export default getAllUserSubscriptions;