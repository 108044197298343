import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../commons/reduxStore";
import { GetListOfWithdrawals, PaginatedWithdrawals } from "../../../models/dashboard/withdraw/withdraw";
import getWithdrawList from "../../../services/dashboard/moveMoney/getWithdrawList";

export const GET_WITHDRAWLIST_SUCCESS="GET_WITHDRAWLIST_SUCCESS";
export const GET_WITHDRAWLIST_ERROR="GET_WITHDRAWLIST_ERROR";

export interface WithdrawListInStoreAction {
    type: typeof GET_WITHDRAWLIST_SUCCESS | typeof GET_WITHDRAWLIST_ERROR,
    withdrawList: PaginatedWithdrawals | null
}

export const udpateWithdrawListInStoreAction = (
    searchWithdraw: GetListOfWithdrawals
)
: ThunkAction<void, AppState, unknown, Action> => dispatch =>{
    queryRequest(()=> getWithdrawList(searchWithdraw))
    .then((res:any)=>{
        dispatch({
            type:GET_WITHDRAWLIST_SUCCESS,
            withdrawList: res
        } as WithdrawListInStoreAction);
    })
    .catch((err) => {
        dispatch({
            type: GET_WITHDRAWLIST_ERROR,
            withdrawList: null
        } as WithdrawListInStoreAction);
        
    });
}