import {Alert, Button, Checkbox, Col, Input, Layout, message, Row, Skeleton} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Redirect, useHistory} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import React, {useState} from "react";
import {connect} from "react-redux";
import {Content} from "antd/lib/layout/layout";

import {commandRequest, queryRequest} from "../../common/requestUtils/requestUtils";
import store from "../../../core/commons/localstorage";

import GuacHeader from "../GuacHeader";
import GuacFooter from "../GuacFooter";

import '../../../styles/layout/index.less';
import '../../../styles/_base.less'

import getUserProfile, {User} from "../../../core/services/accounts/getUserProfile";
import services from "../../../core/services";
import {updateUserInStore} from "../../../core/actions/user/updateUserInStore";
import {RoutePaths} from "../../routes";
import {AppState} from "../../../core/commons/reduxStore";
import {addToLocalStorage, rememberMeKey} from "../../common/utils/Utils";

const {login, confirmCode, resendCode} = services;

interface LoginProps {
    updateUserInStore: (user: User | null) => void;
}

interface FormValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

interface FormCodeValues {
    token: string;
    code: string;
}

let initialValues: FormValues = {
    email: "",
    password: "",
    rememberMe: false
};

let secondInitialValues: FormCodeValues = {
    token: '',
    code: ''
};

const formValidator = () =>
    Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().required('Password is required').min(6, "Password should be 6 chars minimum")
    });

const secondFormValidator = () => Yup.object({
    code: Yup.string().required('Code is required').min(7, 'Token should be 7 chars minimum')
});

const Login: React.FC<LoginProps> = (props: any) => {

    const history = useHistory();
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmiting] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [token, setToken] = useState('');
    const [isSubmitted, setSubmitted] = useState(false);

    const [errors, setErrors] = useState<string[]>([]);

    const getProfileInfo = async () => {
        props.updateUserInStore(null, false);
        setLoading(false);
    }

    if (isMounting) {
        if (localStorage.getItem(rememberMeKey)) {
            const newLocal = localStorage.getItem(rememberMeKey);
            if (newLocal != null) {
                let values = JSON.parse(newLocal) as unknown as FormValues;

                if (values != null) {
                    initialValues.email = values?.email
                }
            }
        }
        setMounting(false);
        getProfileInfo();
    }

    const onCheckRemberMeChange = (checked: boolean) => {
        setChecked(checked);
    }

    const onFirstSubmit = async (values: FormValues) => {
        setErrors([]);
        setSubmiting(true);

        if (isChecked) {
            initialValues.email = values.email
            addToLocalStorage(rememberMeKey, JSON.stringify(initialValues));
        }
        const res = await commandRequest(() => login(values.password, values.email))
            .then((res: any) => {
                setToken(res.authToken);
                setSubmitted(true);
                setSubmiting(false);
            }, (reason: any) => {
                setSubmiting(false);
                setErrors([reason]);
            })
            .catch((error) => {
            });
    };

    const onSecondSubmit = async (values: FormCodeValues) => {
        setErrors([]);
        setSubmiting(true);

        await commandRequest(() => confirmCode(values.code, token))
            .then((res: any) => {
                store.setToken(res.authToken);
                setSubmiting(false);
                try {
                    props.updateUserInStore();
                    history.push("/");
                } catch (error) {
                }
            }, (reason: any) => {
                setSubmiting(false);
                setErrors([reason]);
            })
            .catch((error) => {
            });
    };

    const submitResetCode = () => {
        commandRequest(() => resendCode(token))
            .then((res: any) => {
                setToken(res.authToken);
                message.success('The authentication email was resend successfully');
            }, (reason: any) => {
                setErrors([reason]);
                message.error('The authentication email failed to be sent');
            })
            .catch((error) => {
                message.error('The authentication email failed to be sent');
            });
    };

    return (
        <Skeleton active loading={isLoading}>
            <Layout>
                {props.user !== null ? <Redirect to={RoutePaths.REDIRECT_PATH}/> : null}
                <Content>{
                    !isSubmitted ?
                        <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={formValidator}
                                onSubmit={onFirstSubmit}>

                                {formik => (
                                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <GuacHeader/>
                                        <Row className="login-form">
                                            <p className="header-title">Log in to your account</p>
                                            <Row>
                                                <Col xs={24}>
                                                    {errors.length > 0 && (
                                                        <Alert type="error" className="error-alert" closable
                                                               message={errors[0]} showIcon/>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="form">
                                                <Col xs={24}>
                                                    <Input size="large" id="email" name="email"
                                                           className="email-input"
                                                           placeholder="Email"
                                                           onChange={formik.handleChange}
                                                           prefix={<UserOutlined className="icon-styles"/>}/>
                                                    {formik.errors.email && formik.touched.email && (
                                                        <div
                                                            className="ant-form-item-explain ant-form-item-explain-error">
                                                            <div role="alert">{formik.errors.email}</div>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="form">
                                                <Col xs={24}>
                                                    <Input.Password size="large"
                                                                    id="password"
                                                                    name="password"
                                                                    className="password-input"
                                                                    placeholder="Password"
                                                                    onChange={formik.handleChange}
                                                                    prefix={<LockOutlined className="icon-styles"/>}
                                                    />
                                                    {formik.errors.password && formik.touched.password && (
                                                        <div
                                                            className="ant-form-item-explain ant-form-item-explain-error">
                                                            <div role="alert">{formik.errors.password}</div>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="form">
                                                <Col xs={24}>
                                                    <Checkbox onChange={e => {
                                                        onCheckRemberMeChange(e.target.checked)
                                                    }} name="rememberMe" id="rememberMe"
                                                              className="login-remember-me">Remember me</Checkbox>
                                                    <a onClick={() => {
                                                        history.push(`${RoutePaths.FORGOT_PASSWORD_PATH}`);
                                                    }}
                                                       className="login-fogot-password"
                                                       href="#">Forgot password</a>
                                                </Col>
                                            </Row>
                                            <Row className="form">
                                                <Button htmlType="submit" type="primary"
                                                        disabled={!formValidator().isValidSync(formik.values)}
                                                        className={(formValidator().isValidSync(formik.values)) ? "login-submit-button active" : "login-submit-button disabled"}
                                                        block title="Log In" shape="round"
                                                        size="large" loading={isSubmitting}>Log in</Button>
                                            </Row>
                                        </Row>
                                        <GuacFooter/>
                                    </form>
                                )}
                            </Formik>
                        </Row>
                        : <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={secondInitialValues}
                                validationSchema={secondFormValidator}
                                onSubmit={onSecondSubmit}>
                                {formik => {
                                    return (
                                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                                            <GuacHeader/>
                                            <Row className="login-form">
                                                <p className="header-title">Enter the code: </p>
                                                <Row>
                                                    <Col xs={24}>
                                                        {errors.length > 0 && (
                                                            <Alert type="error" className="error-alert" closable
                                                                   message={errors[0]} showIcon/>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="form">
                                                    <Col xs={24}>
                                                        <Input size="large" id="code" name="code"
                                                               className="email-input"
                                                               type="number"
                                                               placeholder="123456"
                                                               onChange={formik.handleChange}
                                                               prefix={<UserOutlined className="icon-styles"/>}/>
                                                        {formik.errors.code && formik.touched.code && (
                                                            <div
                                                                className="ant-form-item-explain ant-form-item-explain-error">
                                                                <div role="alert">{formik.errors.code}</div>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="form">
                                                    <a
                                                        style={
                                                            {width: '100%'}
                                                        }
                                                        className="login-fogot-password"
                                                        href="login">Not getting the code? Retry login.</a>
                                                </Row>
                                                <Row className="form">
                                                    <Button htmlType="submit" type="primary"
                                                            disabled={!secondFormValidator().isValidSync(formik.values)}
                                                            className={(secondFormValidator().isValidSync(formik.values)) ? "login-submit-button active" : "login-submit-button disabled"}
                                                            block title="Log In" shape="round"
                                                            size="large" loading={isSubmitting}>Log in</Button>
                                                </Row>
                                            </Row>
                                            <GuacFooter/>
                                        </form>
                                    )
                                }}
                                    </Formik>
                                    </Row>
                                }

                            </Content>
                        </Layout>
                    </Skeleton>
                    );
                }
const mapStateToProps = ({user}: AppState) =>
    ({
        user: user
    });

export default connect(mapStateToProps, {
    updateUserInStore
})(Login);

function setErrorMessage(reason: any) {
    throw new Error("Function not implemented.");
}
