import {MarketplacePlatformModel} from "../../../../models/dashboard/marketplace/marketplaceModels";
import {ThunkAction} from "redux-thunk";
import { Action } from 'redux';
import {AppState} from "../../../../commons/reduxStore";
import {queryRequest} from "../../../../../app/common/requestUtils/requestUtils";
import getMarketplacePlatform from "../../../../services/dashboard/marketplace/platform/getMarketplacePlatform";

export const  GET_MARKETPLACEPLATFORM_SUCCESS = "GET_MARKETPLACEPLATFORM_SUCCESS";
export const  GET_MARKETPLACEPLATFORM_ERROR = "GET_MARKETPLACEPLATFORM_ERROR";

export  interface MarketplacePlatformInStoreAction {
    type: typeof GET_MARKETPLACEPLATFORM_SUCCESS | typeof GET_MARKETPLACEPLATFORM_ERROR,
    marketplacePlatform: Array<MarketplacePlatformModel>
}

export const updateMarketplacePlatformInStoreAction = (platforms: Array<MarketplacePlatformModel> | [])
: ThunkAction<void, AppState, unknown, Action> => dispatch => {
    queryRequest(()=> getMarketplacePlatform())
        .then((res: Array<MarketplacePlatformModel>) =>{
            dispatch({
                type: GET_MARKETPLACEPLATFORM_SUCCESS,
                marketplacePlatform: res
            } as MarketplacePlatformInStoreAction)
        })
        .catch((err) =>{
            dispatch({
                type: GET_MARKETPLACEPLATFORM_ERROR,
                marketplacePlatform: platforms
            } as MarketplacePlatformInStoreAction)
        })
}