import { udpateAnnualRapidWithdrawInStoreReducer } from "./udpateAnnualRapidWithdrawInStoreReducer";
import { updateMoveMoneyByUserInStoreReducer } from "./updateMoveMoneyByUserInStoreReducer";
import { updateWithdrawalStatisticsInStoreReducer } from "./updateWithdrawalStatisticsInStoreReducer";
import { updateWithdrawListInStoreReducer } from "./updateWithdrawListInStoreReducer";

const moveMoneyReducer = {
    udpateAnnualRapidWithdrawInStoreReducer,
    updateMoveMoneyByUserInStoreReducer,
    updateWithdrawalStatisticsInStoreReducer,
    updateWithdrawListInStoreReducer
}

export default moveMoneyReducer;