import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { queryRequest } from "../../../../../app/common/requestUtils/requestUtils";
import { AppState } from "../../../../commons/reduxStore";
import { MarketplacePurchaseStatisticsModel } from "../../../../models/dashboard/marketplace/affiliateStatisticsModel";
import getMarketplaceStatistics from "../../../../services/dashboard/marketplace/affiliates/getAffiliateStatistics";


export const GET_AFFILIATE_STATISTICS_SUCCESS = "GET_AFFILIATE_STATISTICS_SUCCESS";
export const GET_AFFILIATE_STATISTICS_ERROR = "GET_AFFILIATE_STATISTICS_ERROR";

export interface AffiliatePurchaseStatisticsInStoreAction {
    type: typeof GET_AFFILIATE_STATISTICS_SUCCESS | typeof GET_AFFILIATE_STATISTICS_ERROR,
    affiliatePurchaseStatistics: MarketplacePurchaseStatisticsModel
}

export const updateAffiliatePurchaseStatisticsInStoreAction = (id: string, affiliatePurchaseStatistics: MarketplacePurchaseStatisticsModel | null)
    : ThunkAction<void, AppState, unknown, Action> => dispatch => {
        queryRequest(() => getMarketplaceStatistics(id))
            .then((res: MarketplacePurchaseStatisticsModel) => {
                dispatch({
                    type: GET_AFFILIATE_STATISTICS_SUCCESS,
                    affiliatePurchaseStatistics: res
                } as AffiliatePurchaseStatisticsInStoreAction);
            })
            .catch((err) => {
                dispatch({
                    type: GET_AFFILIATE_STATISTICS_ERROR,
                    affiliatePurchaseStatistics: affiliatePurchaseStatistics
                } as AffiliatePurchaseStatisticsInStoreAction);
            });
    }