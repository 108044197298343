import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { queryRequest } from '../../../../../app/common/requestUtils/requestUtils';
import { AppState } from '../../../../commons/reduxStore';
import { GetMarketplaceModel, MarketplaceSearch } from "../../../../models/dashboard/marketplace/affiliatesModel";
import getMarketplaces from '../../../../services/dashboard/marketplace/affiliates/getMarketplaces';

export const GET_MARKETPLACES_SUCCESS = "GET_MARKETPLACES_SUCCESS";
export const GET_MARKETPLACES_ERROR = "GET_MARKETPLACES_ERROR";

export interface MarketplacesInStoreAction {
    type: typeof GET_MARKETPLACES_SUCCESS | typeof GET_MARKETPLACES_ERROR,
    marketplaces: GetMarketplaceModel
}

export const updateMarketplacesInStoreAction =
    (marketplaces: GetMarketplaceModel | null, searchParams: MarketplaceSearch)
        : ThunkAction<void, AppState, unknown, Action> => dispatch => {
            queryRequest(() => getMarketplaces(searchParams))
                .then((res: GetMarketplaceModel) => {
                    dispatch({
                        type: GET_MARKETPLACES_SUCCESS,
                        marketplaces: res
                    } as MarketplacesInStoreAction)
                })
                .catch((err) => {
                    dispatch({
                        type: GET_MARKETPLACES_ERROR,
                        marketplaces: marketplaces
                    } as MarketplacesInStoreAction)
                });
        }