import React, { FC, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { AppState } from "../../../core/commons/reduxStore";

import { Content } from "antd/es/layout/layout";
import BreadcrumbComponent, { BreadcrumbProps } from "../../common/components/dataDisplay/BreadcrumbComponent";
import { Row, Spin, Card, Col } from "antd";
import { AffiliateModel } from "../../../core/models/dashboard/marketplace/affiliatesModel";
import { MarketplaceCategoriesModel } from "../../../core/models/dashboard/marketplace/marketplaceCategoryModel";
import { updateAffiliatesInStoreAction } from "../../../core/actions/dashboard/marketplace/affiliates/updateAffiliatesInStore";
import { updateMarketplaceCategoriesInStoreAction } from "../../../core/actions/dashboard/marketplace/categories/updateMarketplaceCategoriesInStore";
import { updateMarketplacePlatformInStoreAction } from "../../../core/actions/dashboard/marketplace/platform/updateMarketplacePlatformInStore";
import { MarketplacePlatformModel } from "../../../core/models/dashboard/marketplace/marketplaceModels";

import AffiliateHomeCategoriesComponent from './categories/AffiliateHomeCategoryComponent';
import AffiliateHomePlatformsComponent from './platform/AffiliateHomePlatformComponent';
import AffiliateListComponent from './affiliates/AffiliateListComponent';
import SuggestedAffiliatesComponent from './suggestedAffiliates/SuggestedAffiliatesComponent';
import TransactionMarketplaceComponent from './transactions/TransactionMarketplaceComponent';
import MarketplaceStatisticsWrapper from "../home/MarketplaceStatistics/MarketplaceStatisticsWrapper";

export interface MarketplaceComponentProps {
    updateAffiliatesInStoreAction: (affiliates: Array<AffiliateModel> | [], onSuccess: Function, onFailure: Function) => void;
    updateMarketplaceCategoriesInStoreAction: (affiliateCategory: Array<MarketplaceCategoriesModel> | []) => void;
    updateMarketplacePlatformInStoreAction: (marketplacePlatform: Array<MarketplacePlatformModel> | []) => void;
    marketplacePlatform: Array<MarketplacePlatformModel>;
    affiliateCategory: Array<MarketplaceCategoriesModel>
}

const MarketplaceComponent: React.FC<MarketplaceComponentProps> = (props: MarketplaceComponentProps) => {
    const [isMounting, setMounting] = useState(true);
    const [isLoading, setLoading] = useState(true);

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Marketplace",
                path: ""
            }
        ]
    };

    const getAffiliates = () => {
        props.updateAffiliatesInStoreAction([], ()=>{}, ()=>{});
    }

    const getAffiliateCategories = () => {
        props.updateMarketplaceCategoriesInStoreAction([]);
    }

    const getMarketplacePlatform = () => {
        props.updateMarketplacePlatformInStoreAction([]);
    }

    if (isMounting) {
        setLoading(false);
        setMounting(false);
        getAffiliates();
        getAffiliateCategories();
        getMarketplacePlatform();
    }

    return (
        <Content>
            <BreadcrumbComponent {...currentBreadCrumbs} />
            <Spin spinning={isLoading}>
                <Row className="home-content">
                    <MarketplaceStatisticsWrapper isHidden={true}/>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col span={24}>
                            <SuggestedAffiliatesComponent/>
                        </Col>
                    </Row>

                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col className="card-column" span={12}>
                            <AffiliateHomePlatformsComponent key="1" />
                        </Col>
                        <Col className="card-column" span={12}>
                            <AffiliateHomeCategoriesComponent key="2" />

                        </Col>
                    </Row>
                   <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col span={24}>
                            <TransactionMarketplaceComponent />
                        </Col>
                    </Row>
                    <Row className="row-card-content" justify="space-around" align="middle" gutter={[16, 24]}>
                        <Col span={24}>
                            <AffiliateListComponent {...{ affiliateCategory: props.affiliateCategory, marketplacePlatform: props.marketplacePlatform }}/>
                        </Col>
                    </Row>
 
                </Row>
            </Spin>
        </Content>
    );
}

const mapStateToProps = ({ affiliates, affiliateCategory, marketplacePlatform }: AppState) =>
({
    affiliates: affiliates,
    affiliateCategory: affiliateCategory,
    marketplacePlatform: marketplacePlatform
});


export default connect(mapStateToProps, {
    updateMarketplaceCategoriesInStoreAction,
    updateAffiliatesInStoreAction,
    updateMarketplacePlatformInStoreAction
})(MarketplaceComponent);