export class UserStatisticsChartColor {
    static NotVerified = '#4096E8';
    static OnBoarding = '#FA8C16';
    static BankConnected = '#A0D911';
    static Active = '#3AB100';
    static BankDisconnected = '#D1D1D1';
    static DeletionRequest = '#F5222D';
}

export class TransactionStatisticsChartColor{
    static Deposit = '#3AB100';
    static DirectDeposit = '#FCFFA3'
    static MarketplacePurchase = '#8034C7';
    static Referral = '#23D5FF';
    static Withdrawal = '#4096E8';
    static Cashback = '#C76DB8';
}

export class DefualtPieChartColors {
    static Primary = '#3AB100';
    static Secondary = '#D1D1D1';
}

export class SubscriptionStatisticsChartColor {
    static Trial = '#4096E8';
    static NewUsers = '#FA8C16';
    static PrimaryCard = '#A0D911';
    static Pro = '#3AB100';
    static ExistingUsers = '#D1D1D1';
    static Canceled = '#F5222D';
}

export class CashbackStatisticsChartColor {
    static Collected = '#3AB100';
    static Claimed = '#D1D1D1';
    static Canceled = '#F5222D';
}

export class WithdrawStatisticsChartColor {
    static Trial = '#4096E8';
    static NewUsers = '#FA8C16';
    static PrimaryCard = '#A0D911';
    static Pro = '#3AB100';
    static ExistingUsers = '#D1D1D1';
    static Canceled = '#F5222D';
}