import { UpdateLoginUserAction, UPDATE_LOGIN_USER } from "../../actions/user/updateUserInStore";

export const updateUserInfoReducer = (state = null, action: UpdateLoginUserAction) => {
  switch (action.type) {
    case UPDATE_LOGIN_USER:
      return action.user;
    default:
      return state;
  }
};
