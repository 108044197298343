
const SubscriptionsResource= {
   breadCrumb: {
       dashboard: 'Dashboard',
       title:'Subacriptions'
   },
   statistics : {
       totalPro:'Total pro',
       totalTrial:'Total trial',
       totalNewUsers:'Total new users',
       totalExistingUsers:'Total existing users',
       canceledSubscriptions: 'Canceled subscriptions',
       usersPrimaryCardOfUse :'Users with primary card of use',
       totalSubscriptions: 'Total subscriptions',
       allTime:'All time',
       lastMonth: 'Last month',
       lastDay: 'Last day'
   }
}

export default SubscriptionsResource;