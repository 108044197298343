import getUserProfile, { User } from "../../services/accounts/getUserProfile";
import {ThunkAction} from "redux-thunk";
import {Action} from "redux";
import {AppState} from "../../commons/reduxStore";
import { queryRequest } from "../../../app/common/requestUtils/requestUtils";

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";
export const UPDATE_LOGIN_ERROR = "UPDATE_LOGIN_USER";

export interface UpdateLoginUserAction {
  type: typeof UPDATE_LOGIN_USER | typeof UPDATE_LOGIN_ERROR;
  user: User | null;
}


export const updateUserInStore = (user : User | null, reload: boolean = true):
    ThunkAction<void, AppState, unknown, Action> => 
  dispatch => {
    queryRequest(() => getUserProfile(), reload)
    .then((user) => {
      dispatch({
        type:UPDATE_LOGIN_USER,
        user: user
      } as UpdateLoginUserAction)
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_LOGIN_ERROR,
        user: null
      } as UpdateLoginUserAction)
    });
  };