import { NotificationOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Tag } from "antd";
import moment from "moment";
import React from "react";
import { ReferralTransaction, TransferType } from "../../../../core/models/dashboard/transactions/transation";
import TransactionTypeDetailsComponent from "../../../common/components/dashboard/TransactionTypeDetailsComponent";

export interface ReferralActivityComponentProps {
    referralActivity: ReferralTransaction
}

const ReferralActivityComponent: React.FC<ReferralActivityComponentProps> = props => {
    return <>
    {props.referralActivity ?
    <>
        <TransactionTypeDetailsComponent {...{transferType:TransferType.ReferralReward}}></TransactionTypeDetailsComponent>
        <Row className="movemoneyTransations-details" gutter={[16, 16]}>
            <Col span={8} >
                <span className="item-title">Invited by</span>
                <span className="item-value">
                    {props.referralActivity.refferedBy}
                </span>
            </Col>
            <Col span={8} >
                <span className="item-title">Valid From</span>
                <span className="item-value">
                    <> {props.referralActivity.createdAt ? `${moment(new Date(props.referralActivity.createdAt)).format("MM-DD-YYYY")}` : ("")} </>
                </span>
            </Col>
            <Col span={8} >
                <span className="item-title">Expired on</span>
                <span className="item-value">
                    <> {props.referralActivity.expirationDate ? `${moment(new Date(props.referralActivity.expirationDate)).format("MM-DD-YYYY")}` : ("-")} </>
                </span>
            </Col>
        </Row>
        <Row className="movemoneyTransations-details" gutter={[16, 16]}>
            <Col span={8} >
            </Col>
            <Col span={8} >
                <span className="item-title">Collected on</span>
                <span className="item-value">
                    <> {props.referralActivity.collectedDate ? `${moment(new Date(props.referralActivity.collectedDate)).format("MM-DD-YYYY")}` : ("-")} </>
                </span>
            </Col>
            <Col span={8} >
                <span className="item-title">To</span>
                <span className="item-value">
                    {props.referralActivity.goalName}
                </span>
            </Col>
        </Row>
        </>
        : <> </>
    }
    </>
}

export default ReferralActivityComponent;