import React, {useState} from "react";
import {Button, Card, Empty, message, Row, Spin, Table} from "antd";
import {ArrowRightOutlined, DownOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import {MarketplaceSearch} from "../../../../core/models/dashboard/marketplace/affiliatesModel";
import moment from "moment";
import {AppState} from "../../../../core/commons/reduxStore";
import {connect} from "react-redux";
import {updateNotificationTemplatesInStore} from "../../../../core/actions/dashboard/notification/updateNotificationTemplatesInStore";
import {NotificationTemplateData} from "../../../../core/models/dashboard/notification/notification";
import {
    NotificationTemplateAddOrEditComponent
} from "./NotificationTemplateAddComponent";


const NotificationTemplateListComponent: React.FC<any> = props => {

    const [isMounting, setMounting] = useState(true);
    const [isAddVisible, setAddVisibility] = useState(false);
    const [isEditVisible, setEditVisibility] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState(new Array<any>());
    const [isLoading, setLoading] = useState(true);
    const [editData, setEditData] = useState(null);
    const [isNotCollapsed, setCollapsed] = useState(true);
    const initSearchParams: MarketplaceSearch = {
        pageNumber: 1,
        pageSize: 5,
        searchValue: "",
        orderDescending: false,
        sortColumn: "Name",
        isDeleted: false
    }

    const onGetTemplatesSuccess = () => {
        setLoading(false);
    }

    const collapse = () => {
        setCollapsed(!isNotCollapsed);
    }

    const onGetTemplatesFailure = (err: any) => {
        message.error(err.message)
        setLoading(false);
    }

    const loadTemplates = (pageNumber: number) => {
        props.updateNotificationTemplatesInStore({
            pageNumber: pageNumber,
            pageSize: 7
        }, null, null, onGetTemplatesSuccess, onGetTemplatesFailure);
        setSelectedRowKeys([]);
        props.setSelectedRows([]);
    }

    if (isMounting) {
        setLoading(true);
        setMounting(false);
        loadTemplates(1);

    }

    const onClickTemplate = (data: any) => {
        setEditData(data);
        setEditVisibility(true);
    }

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderDescending = sorter.order === 'ascend';

            } else {
                initSearchParams.sortColumn = 'Title';
                initSearchParams.orderDescending = false;
            }
        }

        if (pagination) {
            if (props.notificationTemplates?.pageNumber !== pagination.current) {
                initSearchParams.pageNumber = pagination.current;
            }
        }

        loadTemplates(initSearchParams.pageNumber);
        setLoading(true);
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            width: 100,
            key: 2,
            fixed: true,
            sorter: false,
            render: (title: string) => (
                <>
                    <span>{title}</span>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 290,
            key: 3,
            fixed: false,
            sorter: false,
            render: (description: string) => {
                const allowedLength = selectedRowKeys.length > 0 ? 30 : 130;
                return (
                    <>
                    <span>
                        {description?.substr(0, allowedLength)}
                        {description?.length > allowedLength ? '...' : ''}
                    </span>
                    </>
                )
            }
        },
        {
            title: 'Created on',
            dataIndex: 'createdAt',
            width: 120,
            key: 2,
            fixed: false,
            sorter: false,
            render: (createdOn: Date | null) => (
                <>
                    <span>{(moment(createdOn)).format('DD-MMM-YYYY HH:mm:ss')}</span>
                </>
            )
        },
        {
            title: 'Updated on',
            dataIndex: 'lastModifiedAt',
            width: 120,
            key: 2,
            fixed: false,
            sorter: false,
            render: (lastModifiedOn: Date | null) => (
                <>
                    <span>{(moment(lastModifiedOn)).format('DD-MMM-YYYY HH:mm:ss')}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 4,
            fixed: false,
            dataIndex: 'id',
            width: 80,
            render: (id: string | null, data: any) =>
                <>
                    <Button onClick={() => {
                        onClickTemplate(data)
                    }} type="link">
                        <ArrowRightOutlined/>
                    </Button>
                </>
        },
    ];

    const onRowSelect = (selectedRowKeys: React.Key[], selectedRows: NotificationTemplateData[]) => {
        setSelectedRowKeys(selectedRowKeys);
        props.setSelectedRows(selectedRows);
    };

    const getHeaderTitle = () => {
        return (
            <>
                {isNotCollapsed ? <><DownOutlined onClick={collapse}/> {`Notification Templates`}</> : <><RightOutlined
                    onClick={collapse}/> {`Notification Templates`}</>}
            </>
        )
    }

    return (
        <Card
            style={{
                width: props.width
            }}
            title={getHeaderTitle()}
            className={`notification-list-sender ${isNotCollapsed ? "is-flex-expanded nt-expanded" : "nt-collapsed"}`}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <div className="nt-tab-row-card-content">

                    <Row className="notification-item">
                        <Button
                            onClick={() => setAddVisibility(true)}
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined/>}>
                            New
                        </Button>
                    </Row>

                    <div className="notification-item">
                        <Table
                            rowSelection={{
                                selectedRowKeys: selectedRowKeys,
                                onChange: onRowSelect,
                            }}
                            locale={{
                                emptyText: <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No results for ths search. Try again..."/>
                            }}
                            pagination={{
                                total: props?.notificationTemplates?.totalItemCount,
                                current: props.notificationTemplates?.pageNumber,
                                pageSize: props?.notificationTemplates?.pageSize,
                                showSizeChanger: false,
                            }}
                            className=""
                            sortDirections={['ascend', 'descend', 'ascend']}
                            scroll={{x: 800, y: 600}}
                            onChange={(onChangeEvent)}
                            columns={columns}
                            dataSource={props
                                .notificationTemplates?.items?.map((e: any, key: number) => ({
                                    key: key,
                                    ...e
                                }))}/>
                    </div>
                    {isAddVisible ?
                        <NotificationTemplateAddOrEditComponent refresh={loadTemplates} setVisibility={setAddVisibility}
                                                                isVisible={isAddVisible}/> : null}
                    {isEditVisible ?
                        <NotificationTemplateAddOrEditComponent refresh={loadTemplates} isEdit={true} data={editData}
                                                                setVisibility={setEditVisibility}
                                                                isVisible={isEditVisible}/> : null}
                </div>
            </Spin>
        </Card>
    );
}

const mapStateToProps = ({notificationTemplates}: AppState) =>
    ({
        notificationTemplates: notificationTemplates
    });

export default connect(mapStateToProps, {
    updateNotificationTemplatesInStore
})(NotificationTemplateListComponent);